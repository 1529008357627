.ditor_box{padding:0;word-wrap:break-word;cursor:text;height:90%;margin:8px;font-family:sans-serif;font-size:16px;}
.ditor_box img{max-width:91%;}
.ditor_box p{margin:5px 0;}

/*登录*/
.login_box{ height: 96vh; width: 100%; overflow: hidden;}
.login_top{background-color: #000;opacity: 0.8;width: 100%;height: 85px; position: relative;}
.login_top a { width: 272px; height: 49px; display: block;  padding-top: 18px;margin-left: 18%}
.login_box1{ margin-bottom: 175px;width: 502px; height: 500px; background: url(../images/l02.png) no-repeat center; display: block;  margin-top: 50px; overflow: hidden;margin-left: 54%;}
.login{width: 323px;margin: 0 auto;margin-top: 180px;display: table;}
.login01{position: relative;float: left;background-color: #fff;width: 100%;height: 40px;border-radius: 3px;margin-bottom: 20px;border:1px solid #ababab;}
.login01A{background: url(../images/in01.png) no-repeat left center; padding-left: 22px;margin-left: 10px;}
.login01A input {border: none; width: 280px; height: 38px; outline: none;background: rgba(255,255,255,0);}
.login01B{background: url(../images/in02.png) no-repeat left center; padding-left: 22px;margin-left: 10px;}
.login01B input {border: none; width: 280px; height: 38px; outline: none;background: rgba(255,255,255,0);}
.error{border:1px solid #d92c2c !important;}
.login_background{ position: relative; z-index: -1; top: -85px; width: 100%; float: left; height: 995px;}
.login_background ul li{ width: 100%; float: left;}
.login_background ul li img{ width: 1920px; float: left; height: 995px; margin-left: calc( (100vw - 1920px)/2 );}
.login_mt_box{ width: 100%; float: left; position: relative; top: -995px; overflow: hidden;}
.h310{ height: 310px;}
.errorts{position: absolute; top: 40px;font-family: "微软雅黑"; font-size: 12px; color: #f24646;margin-top: 3px;display: table;width: 100%;float: left;}
.login02{margin-bottom: 20px;display: table;width: 100%;position: relative;}
.login01C{ background: url(../images/in03.png) no-repeat 10px center;padding-left: 32px;float: left;display: inline-block;background-color: #fff;width: 163px;height: 40px;border-radius: 3px;border:1px solid #ababab;}
.login01C input { width: 155px;border: none;height: 38px; outline: none;background: rgba(255,255,255,0); }
.yanzhengma{display: inline-block;float: right;width: 107px;height: 40px}
.login_con3{ width: 100%; float: left;    margin-bottom: 30px;}
.login_lileft{ float: left;}
.login_lileft label{ float: left; margin-right: 30px; cursor: pointer;}
.login_lileft label input{ float: left; width: 13px; height: 13px; box-sizing: border-box; margin: 0!important; margin-top: 3px!important; margin-right: 8px!important;}
.login_lileft label p{ float: left;}
.login_con3 p{ font-family: "微软雅黑"; font-size: 13px; color: #fff;}
.login_con3 a{ font-family: "微软雅黑"; font-size: 13px; color: #fff;text-decoration: underline; color: #f7a85a;}
.login_liright{ float: right;}
.login_liright a{ float: right;}
.login_liright2{ float: right; margin-left: 70px;}
.login_liright2 a{ color: #f7a85a;}
.login_a{width: 100%;float: left;margin-bottom: 10px;}
.login_a>a{background-color: #99533e;font-family: "微软雅黑"; font-size: 18px; color: #fff;display: block;width: 100%;text-align: center;height: 40px;line-height: 40px;border-radius: 3px;}
.login_zc{width: 100%;float: left;font-family: "微软雅黑"; font-size: 13px; color: #fff;text-align: center;}
.login_zc a{color: #f7a85a;text-decoration: underline;}
.login_footer{    background-color: #af967f;
    width: 100%;
    height: 143px;
    text-align: center;
    padding-top: 30px;box-sizing: border-box;}
.login_footer p{font-family: "黑体"; font-size: 15px; color: #dac9bb;margin-bottom: 10px}
.login_footer a{font-family: "黑体"; font-size: 15px; color: #dac9bb;}
.login_footer a:hover{ color: #2b87ff;}

.login_mt_box .login_footer{background-color: #af967f;width: 100%;height: 143px;text-align: center;padding-top: 30px; position: fixed; left: 0; bottom: -50px;}
.login_2>a{ float: left; width: 170px;}
.login_2 span{float: left; margin-left: 9px; line-height: 49px; margin-top: -6px; font-family: "微软雅黑"; font-size: 13px; color: #ffffff;}
.login_2 span a{ float: right; margin-left: 5px; height: 41px;}
.login_2 span:before{ content: ""; margin-right: 10px; float: left; width: 1px; height: 41px; margin-top: 5px; background: url(../images/login_zheg.png) no-repeat center;}
.login_3{ margin-bottom: 0;}
.login_3>a{ float: left; width: 170px;}
.login_3 span{float: left; margin-left: 9px; line-height: 49px; margin-top: -6px; font-family: "微软雅黑"; font-size: 13px; color: #2a1708;}
.login_3 span a{ float: right; margin-left: 5px; height: 41px;}
.login_3 span:before{ content: ""; margin-right: 10px; float: left; width: 1px; height: 41px; margin-top: 5px; background: url(../images/login_zheg2.png) no-repeat center;}
@media ( max-width:1440px ) {
	.login_2 >a{width: 168px;}
}
/*注册*/
.login_box2{ margin-bottom: 90px;width: 502px; height: 579px; background: url(../images/l03.png) no-repeat center; background-size:100% 100%; display: block;  margin-top: 50px; overflow: hidden;margin-left: 54%;}
.loginzc{width: 323px;margin: 0 auto;margin-top: 152px;display: table;}
.login01D{background: url(../images/in04.png) no-repeat left center;padding-left: 22px;margin-left: 10px;}
.login01D >div{ width: 100% !important;}
.login01D *{ border: none !important;}
.login01D select{border: none; width: 280px; height: 40px; outline: none;background: rgba(255,255,255,0);color: #757575}
.yanzhengmazc{display: inline-block;float: right;width: 80px;height: 40px;line-height: 40px;text-align: right;}
.yanzhengmazc a{font-family: "微软雅黑"; font-size: 15px; color: #fff;text-decoration: underline;}
.login01E{background: url(../images/in05.png) no-repeat left center;padding-left: 22px;margin-left: 10px;}
.login01E input{border: none; width: 280px; height: 38px; outline: none;background: rgba(255,255,255,0);}
.login01Czc{width: 195px}
.login01Czc input{width: 180px}
.login_azc{margin-bottom: 15px}
.login_dzc{margin-bottom: 10px}
.login01D .layui-select-title input{ border: none; padding-left: 0;}
.login01D .layui-input-block{ margin-left: 0;}
.login01D .layui-form-select dl dd.layui-this{ background-color: #af967f!important;}
.login01D .layui-anim{ margin-left: -32px; width: 323px;}
/*注册协议*/
.e0cdbf span{ background: #e0cdbf!important; cursor: no-drop;}
.zcxy_box{width: 100%;float: left;border-radius: 5px;background: #fff; overflow: hidden;}
.Pop_btn{position: absolute;top: 3px;right: 18px;padding: 0;border: none;outline: 0;background: 0 0;font-size: 16px;cursor: pointer;color: #303133;}
.zcxy_box_c{width: 1130px;display: block;margin: 0 auto;margin-top: 30px;margin-bottom: 30px;}
.zcxy_detail{width: 100%;float: left;height: 480px;overflow: auto;border: 1px solid #e3e3e3;margin-top: 25px;padding: 20px;box-sizing: border-box;margin-bottom: 27px;}
.zcxy_detail p{font-size: 14px;font-family: "微软雅黑";color: #4f4126;line-height: 29px;text-indent: 2em;}
.conbtn_box {width: 100%;float: left;margin-top: 30px;margin-bottom: 25px;}
.conbtn_boxc {display: table;margin: 0 auto;}
.conbtn_box a span {width: 200px;height: 35px;background: #af967f;display: block;border-radius: 13px;float: left;margin-right: 40px;text-align: center;line-height: 35px;font-size: 16px;font-family: "微软雅黑";color: #ffffff;transition: 0.4s;}
/*个人中心-消息管理*/
.cont_right_box{ width: 930px; float: right; border-radius: 4px; overflow: hidden; border: 2px solid #ebe1d9; box-sizing: border-box;}
.xxgl_box{ width: 100%; float: left; min-height: 200px; background: #fffbf7;}
.xxgl_box_c{ width: 896px; margin: 0 auto; margin-top:18px;}
.xxgl_box_ce{ width: 896px; margin: 0 auto;}
.xxgl_box_c ul li{ width: 100%; float: left; border-bottom: 1px solid #e9e0d9; padding-bottom: 15px; margin-bottom: 15px;}
.xxgl_box_c ul li h2 a:nth-child(3){ float: right;}
.xxgl_box_c ul li:last-child{ margin-bottom: 0;}
.xxgl_box_c ul li h2{ width: 100%; float: left; font-size: 15px; font-family: "微软雅黑"; color: #353535; font-weight: 100; margin-bottom: 5px;}
.xxgl_box_c ul li h2 span{ margin-right: 5px; width: 33px; height: 16px; text-align: center; line-height: 16px; background: #93b39b; display: inline-block; font-size: 12px; font-family: "微软雅黑"; color: #fff; border-radius: 2px; position: relative; left: 0; top: -1px;}
.xxgl_box_c ul li i{ font-size: 13px; font-family: "微软雅黑"; color: #aeaeae; float: left; margin-top: 5px;}
.xxgl_box_c ul li h2 img{ float:left; margin-top: 5px; margin-right:2px;}
.xxgl_box_c ul li h2 a p{ float:left;font-size: 12px; font-family: "微软雅黑"; color: #aaa9a9;}
.xxgl_box_c ul li h2 font{ float: left;}
.xxgl_box_c ul li h2 a{ float: right;}
.xxgl_box_c ul li p{ float: left; font-size: 13px; line-height: 23px; font-family: "微软雅黑"; color: #585858;}
.white_s{ white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%;}
.yd_class{ background: #aeaeae!important;}
.xxgl_box_c ul li h2 a font:hover{color: #99533e !important;}
/*个人中心-我的已购*/
.mypurchase_top{ width: 100%; float: left; height: 45px;}
.mypurchase_top li{ width: 91px; height: 45px; float: left; padding-left: 18px; box-sizing: border-box;}
.mypurchase_top li a{ font-size: 15px; font-family: "微软雅黑"; color: #aa9d91; line-height: 45px;}
.mypurchase_top li a:hover{color: #504027!important;}
.mypurchase_topcur a{ color: #504027!important;}
.mypurchase_con{ width: 100%; float: left; background: #fffbf7; padding-top: 6px;}
.mypurchase_con table{ width: 100%; float: left;}
.mypurchase_con table th{ background: #c19486; height: 29px; text-align: left; padding-left: 17px; color: #ffffff; font-size: 14px; font-family: "微软雅黑";}
.mypurchase_con table tr th:last-child{ text-align: center; padding-left: 0;}
.mypurchase_con table td{ border-bottom: 1px solid #e9e0d9; font-size: 13px; font-family: "微软雅黑"; color: #353535; height: 50px; padding-left: 17px;}
.mypurchase_con table td a:hover{color: #99533e !important;}
.mypurchase_con table td:last-child a{ width: 71px; height: 22px; float: left; background: #af967f; font-family: "微软雅黑"; color: #ffffff; font-size: 12px; text-align: center; line-height: 22px; border-radius: 2px;} 
.mypurchase_con table td:last-child a:hover{background: #99533e !important;color: #ffffff !important;}
/*个人中心-修改密码*/
.changepassword_box{ width: 100%; float: left; background: #fffbf7;}
.change_c_box{ width: 460px; display: table; margin: 0 auto; margin-top: 71px; margin-bottom: 90px;}
.change_c_box ul li{ width: 100%; float: left; margin-bottom: 28px; position: relative;}
.change_c_box ul li:last-child{ margin-bottom: 0;}
.change_c_box ul li >p{ float: left; width: 116px; height: 35px; line-height: 35px; font-size: 14px; font-family: "微软雅黑"; color: #736357;}
.change_c_box ul li>input{ width: calc( 100% - 116px ); height: 35px; line-height: 35px; background: #fbfbfb; color: #454545; border-radius: 4px; border: 1px solid #e2e2e2; box-sizing: border-box; outline: none; padding-left: 8px; font-size: 13px;}
.change_c_box ul li>textarea{ width: calc( 100% - 116px ); height: 135px; resize: none; line-height: 23px; background: #fbfbfb; padding-top: 5px; color: #454545; border-radius: 4px; border: 1px solid #e2e2e2; box-sizing: border-box; outline: none; padding-left: 8px; font-size: 13px;}
.user_birth{ float: left; width: calc( 100% - 116px ); position: relative;}
.user_birth::after{ position: absolute; content: ''; top: 9px; right: 9px; width: 14px; height: 16px; background: url(../images/csrq_ico.png) no-repeat;}
.user_birth .el-date-editor.el-input, .el-date-editor.el-input__inner{ width: 100% !important;}
.user_birth input{ height: 35px; line-height: 35px; padding-left: 8px !important; background-color: #fbfbfb;}
.user_birth .el-input.is-active .el-input__inner, .el-input__inner:focus{border:1px solid #e2e2e2 !important;}
.user_birth .el-input__prefix{ display: none;}
.el-date-table td.current:not(.disabled) span{ background-color:#af967f !important;}
.el-date-table td.today span{ color: #af967f !important;}
.user_introduce{ width: calc( 100% - 116px ); height: 135px; overflow-y: auto; line-height: 23px; background: #e9e9e9; padding-top: 5px; border-radius: 4px; border: 1px solid #e2e2e2; box-sizing: border-box; padding-left: 8px;}
input[disabled]{ border: 1px solid #e2e2e2!important; background: #e9e9e9!important; color: #a3a3a3!important;}
textarea[disabled]{ border: 1px solid #e2e2e2!important; background: #e9e9e9!important; color: #a3a3a3!important;}
.btn_box{ width: 100%; float: left; text-align: center; margin-bottom: 30px;}
.btn_box a{ margin-left: 15px; margin-right: 15px; width: 109px; height: 30px; background: #af967f; text-align: center; line-height: 30px; display: inline-block; border-radius: 4px; font-size: 15px; font-family: "微软雅黑"; color: #ffffff; }
.edit_box{ float: right; margin-right: 20px; margin-top: 1px;}
.edit_box img{ width: 16px!important; height: 16px!important; float: left!important;}
.personaldata_box{ width: 100%; height: 163px; float: left; background: url(../images/myback.png) no-repeat center;}
.personaldata_boxc{ display: table; margin: 0 auto; margin-top: 26px;}
.person_left{ float: left; width: 102px; height: 102px; margin-right: 12px; position: relative;}
.person_left>img{ width: 100%; border-radius: 50%;}
.edit_header{ position: absolute; left: 14px; top: 42px; display: inline-block;}
.edit_header span{ float: left;  width: 71px; height: 22px; background: #af967f; cursor: pointer; border-radius: 3px; text-align: center; line-height: 22px; font-size: 12px; font-family: "微软雅黑"; color: #ffffff; }
.edit_header input{ position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0;}
.vip_box{ width: 24px; height: 21px; position: absolute; right: 17px; bottom: 0px;}
.person_right{ float: right; margin-top: 26px;}
.person_right i{ width: 100%; float: left; margin-bottom: 10px;}
.person_right i>a{ width: 61px; height: 17px; background: #af977f; color: #fff; font-size: 12px; font-family: "微软雅黑"; line-height: 17px; text-align: center; border-radius: 2px; float: left; margin-left: 5px;}
.person_right i span{ display: inline-block; margin-top: 2px; float: left; margin-right: 10px; width: 60px; height: 20px; line-height: 20px; text-align: center; font-size: 12px; font-family: "微软雅黑"; color: #ffffff; background: #eb9153; border-radius: 2px;}
.person_right i h2{ font-size: 18px; font-family: "微软雅黑"; color: #7e604b; font-weight: 100; float: left;}
.person_right i p{ font-size: 13px; font-family: "微软雅黑"; color: #818080; float: left;}
.personaldata_bottom{ width: 100%; float: left; background: #fffbf7;}
.li_btn_box{ width: 342px; height: 35px; float: left;}
.li_btn_box input{ width: 244px; height: 35px; line-height: 35px; background: #fbfbfb; color: #454545; border-radius: 4px; border: 1px solid #e2e2e2; box-sizing: border-box; outline: none; padding-left: 8px; font-size: 13px;}
.li_btn_box a{ width: 91px; height: 33px; background: #af967f; border-radius: 4px; float: right; font-size: 15px; font-family: "微软雅黑"; color: #fff; text-align: center; line-height: 33px;}
.code_again{color: #9b9797 !important;background: #e6e6e6 !important;}
/*个人中心-我的投稿*/
.my_contribution{ width: 100%; float: left; min-height: 200px; background: #fffbf7;}
.my_con_top{ float: right;}
.my_con_top select{ float: left; width: 99px; padding-left: 4px; height: 31px; line-height: 31px; background: #fbfbfb; border: 1px solid #e2e2e2; border-radius: 4px; outline: none; margin-right: 15px; font-size: 14px; font-family: "黑体"; color: #a3a3a3;}
.my_con_top >input{ float: left; width: 226px; height: 31px; line-height: 31px; font-size: 14px; font-family: "黑体"; box-sizing: border-box; border-radius: 4px; background: #fbfbfb; border: 1px solid #e2e2e2; outline: none; padding-left: 8px; color: #a3a3a3;}
.my_con_top input::placeholder{ color: #d5d5d5;}
.my_con_top a{ float: left; width: 58px; height: 31px; font-family: "黑体"; width: 58px; height: 31px; border-radius: 4px; background: #af967f; margin-left: 14px; font-size: 15px; font-family: "黑体"; color: #fff; text-align: center; line-height: 31px;}
.my_con_top .el-select input{ line-height: 31px !important; height: 31px !important; padding-left: 10px !important; background: #fbfbfb;}
.my_con_top .el-input__icon{ line-height: 31px !important;}
.el-select-dropdown__item.selected{ color: #fff !important; background: #af967f !important; font-weight: 400 !important;}
.my_con_topm{ width: 896px; margin: 0 auto; margin-top: 10px; padding-bottom: 10px; margin-bottom: 10px; border-bottom: 1px solid #e9e0d9; height: 32px;}
.my_contribu_con{ width: 896px; margin: 0 auto;}
.my_contribu_con ul li{ width: 100%; float: left; border-bottom: 1px solid #e9e0d9; margin-bottom: 15px;}
.my_contribu_con ul li:last-child{ margin-bottom: 0;}
.my_con_litop{ width: 100%; float: left; height: 29px; background: #f7eae2; padding: 6px 5px; box-sizing: border-box;}
.my_con_litop font{ float: left; width: 49px; height: 17px; font-size: 12px; font-family: "微软雅黑"; color: #ffffff; border-radius: 2px; text-align: center; line-height: 17px; margin-right: 6px;}
.my_con_litop i{ float: left; font-style: normal; margin-right: 21px; font-size: 13px; font-family: "微软雅黑"; color: #585858;}
.my_con_litop>a{ float: right;}
.my_con_litop>a img{ float: left; margin-right: 2px; margin-top: 2px;}
.my_con_litop>a p{ float: left; font-size: 12px; font-family: "微软雅黑"; color: #aaa9a9;}
.my_con_libottom{ width: 100%; float: left;}
.my_con_libottom p{ width: 85%; float: left; font-size: 15px; font-family: "微软雅黑"; color: #353535; line-height: 25px; height: 43px; line-height: 43px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.my_con_libottom p:hover{color: #99533e !important;}
.my_con_libottom span{ width: 100%; float: left; font-size: 13px; font-family: "微软雅黑"; color: #696969; margin-bottom: 15px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.my_con_libottom_a{ float: right; margin-right: 5px;}
.my_con_libottom_a img{ float: left; margin-top: 15px; margin-right: 2px;}
.my_con_libottom_a font{ float: left; color: #aaa9a9; font-size: 12px; font-family: "微软雅黑"; line-height: 43px;}
.ytg{ background: #93b39b;}
.dsh{ background: #e1bc63;}
.ybh{ background: #f37864;}
.dws{ background: #e2e2e2;}
.my_con_top .layui-form{ width: 99px; height: 31px; float: left; margin-right: 15px;}
.my_con_top .layui-input-block{ margin-left: 0;}
.my_con_top .layui-select-title input{ width: 99px; height: 31px;}
.my_con_top .layui-form-select .layui-edge{ margin-top: 12px;}
.my_con_top .layui-form-selected .layui-edge{ margin-top: 7px!important;}
.my_con_top .layui-form-select dl dd.layui-this{ background-color:#af967f ;}
.zxxq_box_tbody{ width: 100%; float: left; height: 72px; line-height: 72px; border-top: 1px dashed #eaddd4;}
.zxxq_box_tbody>img{ float: left; margin-top: 18px; margin-right: 17px;}
.zxxq_box_tbody span{ font-size: 13px; font-family: "微软雅黑"; color: #504027;}
.zxxq_box_tbody a img{ float: right; margin-top: 19px;}
/*新闻资讯*/
.header_box{width: 1200px;margin: 0 auto;height: 128px;}
.main_box{width: 100%;background-color: #f2ede9;float: left;min-height: 475px; position: relative;}
.main_box1{width: 1200px;margin: 0 auto;}
.title_box{width: 100%;float: left;}
.title_box p{float: left;margin-right: 6px;color: #747474;font-family: "微软雅黑";font-size: 13px;line-height: 58px;}
.title_box span{float: left; color: #99533e;font-family: "微软雅黑";font-size: 13px;line-height: 58px;}
.newslist_box{float: left;width: 793px;background-color: #fffbf7;border: 2px solid #ebe1d9;margin-bottom: 25px;border-radius: 4px;box-sizing: border-box;}
.listtitle_box{float: left;width: 100%;height: 43px; background-color: #eaddd4; margin-top: 0!important;}
.listtitle_box i{float: left;width: 23px;height: 23px;background: url(../images/title_ico.png) no-repeat;margin-left: 18px;margin-top: 10px;margin-right: 8px;}
.related_title i{ background: url(../images/related_ico.png) no-repeat;}
.listtitle_box img{float: left;margin-top: 13px;}
.newslist_box1{float: left;width: 751px;padding:0px 19px;}
.newslist_box1>ul{float: left;}
.newslist_box1 >ul >li{float: left;height: 142px;border-bottom: 1px solid #e9e0d9;display: inline-block;margin-top: 25px;}
.newslist_box1 >ul >li >a{float: left;width: 187px;height: 116px;margin-right: 12px; overflow: hidden;}
.newslist_box1 >ul >li >a >img{float: left;width: 187px;height: 116px; transition: 0.3s;}
.newslist_box1 >ul >li >a:hover >img{ transform: scale(1.1);}
.newslist_box11{float: left;width: 551px;height: 116px;}
.newslist_box11 a{float: left; width: 100%;}
.newslist_box11 h4{font-weight: 100;float: left; width: 100%;color: #4f4126;font-size: 15px;font-family: "微软雅黑";height: 20px;line-height: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-bottom: 12px;}
.newslist_box11 h4:hover{color: #99533e !important;}
.newsDetail_box{width: 100%;height: 23px;background-color: #fdf3ea;float: left;display: inline-block;margin-bottom: 12px;}
.newsDetail_box ul{display: block;float: left;margin-left: 7px;}
.newsDetail_box ul li{float: left;display: inline-block;margin-right: 30px;margin-top: 6px;height: 12px;}
.newsDetail_box ul li:last-child{margin-right: 0px;}
.newsDetail_box ul li em{float:left;width: 13px;height: 12px;margin-right: 5px;}
.news_date{background: url(../images/new_ico1.png) no-repeat;}
.news_ly{background: url(../images/new_ico2.png) no-repeat;}
.news_bj{background: url(../images/new_ico3.png) no-repeat;}
.newsDetail_box ul li span{float: left;height: 13px;line-height: 12px;font-family: "微软雅黑";font-size: 13px;color: #787878;max-width: 140px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.newsDetail_box ul li:first-child span{max-width: 150px;}
.newslist_box11 p{float: left;width: 100%;height: 57px;line-height: 19px;font-family: "微软雅黑";font-size: 13px;color: #b19982;text-overflow: ellipsis;overflow: hidden;word-break: break-word;}
.news_page{float: left;width: 100%; height: 28px;margin: 0 auto;margin-top: 20px; margin-bottom: 20px;padding: 0px 17px; box-sizing: border-box;}
.news_boxr{float: right;width: 378px;margin-bottom: 25px;}
.news_boxr1{float:left;width: 378px;border: 2px solid #ebe1d9;border-radius: 4px;box-sizing: border-box;background-color: #fffbf7;}
.news_boxr1list{float: left;width: 346px;padding: 0px 16px;}
.news_boxr1list ul li{float: left;height: 54px;border-bottom: 1px solid #ebe1d9;width: 342px;}
.news_boxr1list ul li a{float: left;}
.news_boxr1list ul li h3{float: left;font-weight: 100;width: 342px;height: 54px;line-height: 54px;font-family: "微软雅黑";font-size: 14px;color: #4f4126;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.news_boxr1list ul li h3:hover{color: #99533e !important;}
.news_boxr1list ul li:last-child{border: none;}
.news_about{ width: 100%; float: left; padding: 15px; box-sizing: border-box;}
.news_about ul li{ width: 100%; float: left; border-bottom: 1px solid #ebe1d9; padding-bottom: 15px; margin-bottom: 15px;}
.news_about ul li:last-child{ border-bottom: 0; margin-bottom: 0; padding-bottom: 0;}
.news_aboutl{ float: left; width: 147px; height: 91px; overflow: hidden;}
.news_aboutl img{ float: left; width: 147px; height: 91px; transition: 0.3s;}
.news_aboutl:hover img{ transform: scale(1.1);}
.news_aboutr{ width: 185px; float: right;}
.news_aboutr h2{ font-size: 15px; cursor: pointer; font-family: "微软雅黑"; color: #4f4126; width: 100%; float: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; line-height: 15px;}
.news_aboutr h2:hover{color: #99533e!important;}
.news_aboutr font{ font-family: "微软雅黑"; color: #787878; font-size: 14px; line-height: 40px;}
.news_aboutr p{ font-size: 13px; font-family:"微软雅黑"; color: #b19982; float: left; width: 100%; line-height: 23px; margin-top: -5px;word-break: break-word;}
/*学界动态*/
.xjdtlist_box1{float: left;padding: 0px 15px 0px 18px;width: 756px;}
.xjdtlist_box1 ul{float: left;display: inline-block;}
.xjdtlist_box1 ul li{display: inline-block;float: left;width: 756px;height: 86px;border-bottom: 1px solid #e9e0d9;}
.xjdtli_l{float: left;width: 74px;margin-right: 21px;margin-top: 10px;}
.xjdtli_l div{float: left;width: 55px;}
.xjdtli_l span{float: left;width: 51px;margin-left: 1px;font-family: "微软雅黑";font-size: 43px;color: #c6c3c4;height: 46px;}
.xjdtli_l font{float: left;font-family: "微软雅黑";font-size: 13px;
color: #c6c3c4;}
.xjdtli_l i{float: right;height: 35px;margin-top: 19px;display: block;width: 11px;background: url(../images/xx.png) no-repeat;}
.xjdtli_r{float: left;width: calc( 100% - 95px );margin-top: 21px;}
.xjdtli_r a{float: left;width: 100%;}
.xjdtli_r h3{float: left;height: 20px;line-height: 18px;width: 100%;font-weight: 100;font-family: "微软雅黑";font-size: 15px;color: #4f4126;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-bottom: 12px;}
.xjdtli_r h3:hover{color: #99533e !important;}
.xjdtli_r p{float: left;width: 100%;height: 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-family: "微软雅黑";font-size: 13px;color: #777475;}
/*资讯详情*/
.zxxq_box{float:left;border-radius: 2px;width: 750px;background-color: #fffbf7;padding: 0px 21px;margin-bottom: 29px; min-height: 663px;}
.zxxq_header{float: left;width: 100%;margin-top: 14px;}
.zxxq_header h3{float: left;width: 90%;text-align: center;padding-left: 5%;padding-right: 5%;padding-bottom: 15px;line-height: 28px;font-weight: 100;font-family: "微软雅黑";font-size: 18px;color: #504027;border-bottom: 1px solid #eaddd4;}
.zxxq_introduce{float: left;width: 100%;margin-top: 10px;height: 24px;background-color: #fef4ec;margin-bottom: 18px;}
.zxxq_introduce ul{margin: 0 auto;height: 100%;display: table;}
.zxxq_introduce ul li{float: left;margin-top: 6px;margin-right: 15px;margin-left: 15px;}
.zxxq_introduce ul li em{float:left;width: 13px;height: 12px;margin-right: 5px;}
.zxxq_introduce ul li span{float: left;height: 12px;line-height: 12px;font-family: "微软雅黑";font-size: 13px;color: #787878;}
.zxxq_detail{float: left;width: 100%;margin-bottom: 50px;/*height: 600px;*/word-break: break-word;}
.zxxq_detail img{max-width: 100%;}
/*在线投稿*/
.zxtg_box{float:left;width: 792px;background-color: #fffbf7;border: 2px solid #ebe1d9;border-radius: 4px;box-sizing: border-box;margin-bottom: 30px;}
.zpxx_box{float: left;width: 100%;}
.zpxxTitle_box{float: left;width: 100%;margin-left: 50px;margin-top: 30px;margin-bottom: 28px;}
.zpxxTitle_box i{float: left;width: 25px;height: 22px;background: url(../images/zpxx_ico.png) no-repeat;margin-right: 7px;}
.zpxxTitle_box span{float: left;height: 22px;line-height: 22px;font-family: "微软雅黑";font-size: 17px;color: #736357;font-weight: 600;}
.zpxxDetail_box{float:left;width: 658px;margin-left: 50px;}
.zpxxDetail_box >li{float: left;width: 100%;margin-bottom: 28px;position: relative;}
.zpxxDetail_box >li .user_birth{ width: calc( 100% - 127px ) !important;}
.zpxxDetail_box >li:last-child{margin-bottom: 30px;}
.zpxxDetail_box >li:last-child p{height: 31px;line-height: 31px;}
.zpxxDetail_boxmc{float: left;width: 90px;margin-right: 37px;}
.zpxxDetail_boxmc p{float: right;height: 35px;line-height: 35px;font-family: "微软雅黑";font-size: 14px;color: #736357;}
.zpxxDetail_boxmc p em{ font-family: "微软雅黑"; float: left; margin-left: -10px; color: #ff5757;}
.zpxx_mc p{height: 30px !important;line-height: 30px !important;}
.zpxxDetail_box >li >input[type="text"]{float: left;width:  calc( 100% - 127px );height: 35px;background-color: #fbfbfb;border: 1px solid #e2e2e2;border-radius: 3px;box-sizing: border-box;outline: none;padding-left: 8px;font-family: "微软雅黑";font-size: 13px;color: #454545;}
.zpxxDetail_box >li >input::placeholder{ color: #d5d5d5;}
.zpxxDetail_box >li textarea{resize: none;float: left;width:  calc( 100% - 127px );height: 200px;background-color: #fbfbfb;border: 1px solid #e2e2e2;border-radius: 3px;box-sizing: border-box;outline: none;padding: 8px;font-family: "微软雅黑";font-size: 13px;color: #454545;}
.zpxxDetail_box li textarea::placeholder{ color: #d5d5d5;}
.upload_filebtn{ overflow: hidden; float: left;width: 82px;height: 31px;line-height: 31px;color: #ffffff;cursor: pointer;border-radius: 3px;background: url(../images/sc_ico.png) no-repeat center;background-color: #af967f;}
.upload_filebtn input{opacity: 0;float: left;width: 300px; height:31px; margin-left: -150px; cursor: pointer;}
.sctg_ts{float: left; margin-left: 15px;height: 31px;line-height: 31px;font-family: "微软雅黑";font-size: 13px;color: #736357;}
.zxtg_xl{float: left;width: 114px;height: 35px;background-color: #fbfbfb;box-sizing: border-box;font-family: "微软雅黑";font-size: 13px;color: #454545;display: block;}
.zxtg_xl .el-input__inner{ padding-left: 8px !important;}
.zxtg_xl .el-input__inner{ height: 35px !important; line-height: 35px !important; background: #fbfbfb !important;}
.zxtg_xl .el-input__icon{ line-height: 35px;}
.zxtg_xl .layui-select-title input{ border: none; padding-left: 0;height: 32px;background-color: #fbfbfb;}
.zxtg_xl .layui-select-title input::placeholder{ color: #d5d5d5;}
.zxtg_xl .layui-input-block{ margin-left: 0;}
.zxtg_xl .layui-form-select dl dd.layui-this{ background-color: #af967f!important;color: #ffffff;}
.zxtg_xl .layui-anim{ margin-left: -8px; width: 114px;}
/*.zpxxDetail_box li select{float: left;width: 114px;height: 35px;background-color: #fbfbfb;border: 1px solid #e2e2e2;border-radius: 3px;box-sizing: border-box;font-family: "微软雅黑";font-size: 13px;color: #454545;display: block;padding-left: 8px;outline: none;}*/
.zpxx_xb{float: left;width: calc( 100% - 127px );}
.radio-inline{margin-right: 20px; display: table; float: left;}
.radio-inline:last-child{ margin-right: 0;}
.radio-inline input{float: left;margin-right: 10px;margin-top: 9px;}
.radio-inline span{float: left;width: 69px;font-size: 14px;font-family: "微软雅黑";color: #736357;line-height: 30px;}  
.zxtg_btn{float: left;width: 100%;}
.zxtg_btn span{margin: 0 auto;width: 109px;height: 30px;line-height: 30px;font-family: "微软雅黑";position: relative;font-size: 14px;color: #ffffff;background-color: #af967f;border-radius: 3px;display: block;text-align: center;margin-bottom: 28px;cursor: pointer;}
.zpxx_csrq{position: absolute;top: 9px;right: 9px;}
.zpxx_scyg{float: left;margin-top: 4px;height: 22px;max-width: 164px;border: 1px solid #f1dfcf;border-radius: 2px;background: -webkit-linear-gradient(top,#f8eee5,#f6e5d6);}
.zpxx_scyg span{float: left;font-family: "微软雅黑";font-size: 12px;color: #504027;line-height: 22px;height: 22px;margin-right: 5px;max-width: 121px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.zpxx_scyg em{float: left;width: 11px;height: 22px;line-height: 22px;background: url(../images/scyg_ico1.png) no-repeat center;margin-left: 6px;margin-right: 5px;}
.zpxx_scyg i{float: right;width: 11px;height: 22px;line-height: 22px;background: url(../images/scyg_ico2.png) no-repeat center;margin-right: 4px;cursor: pointer;}
.yhzysx_box{height: auto;max-height: none;}
.zysx_box{float: left;margin-left: 23px;width: 332px;margin-top: 18px;margin-bottom: 18px;}
.zysx_box li{float: left;width: 100%;}
.zysx_box li span{float: left;width: 24px;height: 30px;line-height: 30px;font-family: "微软雅黑";font-size: 14px;color: #4f4126;margin-right: 5px;}
.tgxz_detail{float: left;width: calc( 100% - 29px );}
.zysx_box p{float: left;width: 100%;font-family: "微软雅黑";font-size: 14px;color: #4f4126;line-height: 30px;}
.zysx_boxli{float: left;width: 100%;margin-bottom: 14px;}
.zysx_boxli li{float: left;width: 100%;font-family: "微软雅黑";font-size: 14px;color: #4f4126;line-height: 28px;}
.zxtgerror{position: absolute;top: 40px;font-family: "微软雅黑";font-size: 12px;color: #f24646;margin-top: 0px;display: table;width:  calc( 100% - 127px );float: left;margin-left: 127px;}
.textareaerror{top: 200px !important;}
.tgxz_detailli{float: left;width: 100%;}
.tgxz_detailli ul{float: left;width: 100%;}
.tgxz_detailli ul li{float: left;width: 100%;}
/*底部*/
.bottom_box{width: 100%;float: left;height: 333px;background: url(../images/bottom_bg.jpg) no-repeat center;}
.bottom_box1{width: 1200px;margin: 0 auto;}
.bottom_box1t{float: left;width: 100%;height: 249px;border-bottom: 1px solid #d7c6b7;}
.bottom_box1l{float: left;margin-top: 31px;}
.bottom_box1l >li{float: left;width: 84px;margin-right: 185px;}   
.bottom_box1l >li h3{float: left;font-weight: 600;color: #ffffff;font-family: "黑体";font-size: 20px;margin-bottom: 24px;}
.bottom_box1lli{float: left;width: 100%;}
.bottom_box1lli li{float: left;width: 100%;margin-bottom: 19px;}
.bottom_box1lli a{float: left;width: 100%;text-align: left;color: #ecdfd4;font-family: "黑体";font-size: 17px;}
.bottom_box1r{float: right;margin-top: 31px;}
.bottom_box1r li{float: left;width: 114px;}
.bottom_box1r li img{float: left;width: 114px;height: 114px;margin-bottom: 15px;border-radius: 3px;}
.bottom_box1r li p{float: left;width: 100%;height: 38px;line-height: 19px;font-family: "黑体";font-size: 16px;color: #ecdfd4;text-align: center;}
.bottom_box1b{float: left;width: 100%;margin-top: 22px;}
.bottom_box1b p{float: left;width: 100%;text-align: center;font-family: "黑体";font-size: 14px;color: #ecdfd4;}
.bottom_box1b a{font-family: "黑体";font-size: 14px;color: #ecdfd4;}
.bottom_box1b a:hover{ color: #2b87ff;}

/*修改手机*/
.change_phone{ width: 100%; float: left; margin-bottom: 50px;}
.change_phone img{ float: left;}
.change_phone p{ float: left; font-size: 18px; font-family: "微软雅黑"; color: #af967f; line-height: 19px; margin-left: 8px;}
.xg_error{margin-left: 116px;}
/*设置新密码*/
.login_box1x{ margin-bottom: 175px; width: 507px; height: 500px; background: url(../images/l04.png) no-repeat center; display: block; margin-top: 50px; overflow: hidden; margin-left: 54%;}
.login_img{ width: 100%; float: left; margin-bottom: 28px;}
.logincx .login01{ margin-bottom: 30px;}
.logincx .login02{ margin-bottom: 30px;}
/*稿件详情*/
.my_contribution_b{ width: 100%; float: left; height: 37px; background: #f4eae2; padding: 0 18px; box-sizing: border-box; margin-top: 10px;}
.my_contribution_b img{ float: left; margin-top: 11px; margin-left: 10px;}
.my_contribution_b font{ font-size: 17px; font-family: "微软雅黑"; color: #54473e; color: #54473e; line-height: 37px; float: left;}
.my_contribution_b span{ float: right; width: 49px; height: 17px; font-size: 13px; font-family: "微软雅黑"; color: #fff; border-radius: 3px; text-align: center; line-height: 17px; margin-top: 10px;}
.my_contribution_b2{ width: 100%; float: left; padding:10px 18px; box-sizing: border-box;}
.my_contribution_b2 li{ width: 50%; float: left;}
.my_contribution_b2 li p{ float: left; font-size: 14px; font-family: "微软雅黑"; color: #736357; line-height: 35px; width: 100px;}
.my_contribution_b2 li span{ float: left; font-size: 13px; font-family: "微软雅黑"; color: #736357; line-height: 35px; width: calc( 100% - 100px );}
.second_title_box{ width: 100%; float: left; height: 28px; line-height: 28px; background: #f4eae2; padding: 0 18px;}
.second_title_box img{ float: left; width: 20px; height: 17px; margin-top: 6px; margin-right: 6px;}
.second_title_box span{ font-size: 14px; font-family: "微软雅黑"; color: #7a6a5e; line-height: 28px; float: left;}
.cont_tg_box{ width: 100%; float: left; min-height: 85px; padding: 10px 18px; box-sizing: border-box;}
.cont_tg_box p{ font-family: "微软雅黑"; color: #9e8e82; font-size: 13px; line-height: 22px;}
.my_contribution_b2 li span a{ float: left; margin-right: 18px; font-size: 13px; font-family: "微软雅黑";}
.keyword{ color: #99533e;}
/*投稿成功*/
.main_boxccx{ width: 100%; float: left; height: 100px; border-radius: 5px; background: #fffbf7; margin-bottom: 40px;}
/*登录弹窗*/
.dltc_box{position: fixed; left: 0; top: 0; width: 100%; height: 100%; opacity: .5; background: #000; z-index: 1000;}
.dltc_box1{position: fixed;left: 50%;margin-left: -198px;top: 50%;margin-top: -180px;z-index: 1001;}
.dltc_box1_main{float: left;width: 396px;background-color: #ffffff;border-radius: 4px;border: 1px solid #736357;box-sizing: border-box;}
.dltc_box1_maint{position: relative;height: 30px;background-color: #736357;}
.dltc_box1_maint h4{font-weight: 100;padding: 6px 30px 9px 15px;font-family: "微软雅黑";font-size: 13px;color: #ffffff;}
.dltc_box1_mainm{width: 324px;padding: 34px 34px 25px 34px;float: left;}
.tclogin_con3{margin-bottom: 20px;}
.tcdl_a{margin-bottom: 0px;}
.tcdl_a a{height: 30px;line-height: 30px;background-color: #736357;font-size: 14px;}
/*投稿成功投稿推荐*/
.tgtj_sucess{float: left;width: 100%;height: 200px;line-height: 200px;text-align: center;margin-bottom: 45px;}
.tgtj_sucess img{width: 411px;height: 86px;}
.tgtj_tj{float: left;width: 100%}
.tgtj_tj p{float: left;font-family: "微软雅黑";font-size: 15px;color: #393939;margin-bottom: 16px}
.tgtj_tjlist{float: left;width: 100%;height: 42px;border-radius: 4px;background-color: #fffbf7;margin-bottom: 15px;padding: 0px 38px;box-sizing: border-box;}
.tgtj_tjlist li{float: left;margin-left: 36px;margin-right: 36px;height: 42px;line-height: 42px;}
.tgtj_tjlist li:last-child{margin-right: 0px;}
.tgtj_tjlist li a{float: left;height: 42px;line-height: 42px;font-family: "微软雅黑";font-size: 15px;color: #736357;}
.tgtj_selected{color: #99533e !important;}
.tgtj_sk{float: left;width: 100%;border-radius: 4px;background-color: #fffbf7;margin-bottom: 31px;}
.tgtj_sk >ul{float: left;width: 100%;padding: 10.5px 21px;box-sizing: border-box;}
.tgtj_sk >ul >li{float: left;width: 371px;height: 194px;background-color: #eaddd4;border-radius: 4px;margin-top: 7.5px;margin-bottom: 7.5px;margin-right: 22px;padding: 9px 10px;box-sizing: border-box;}
.tgtj_sk >ul >li:nth-child(3n){margin-right: 0px;}
.tgtj_sk_img{float: left;width: 176px;height: 176px;margin-right: 10px; overflow: hidden;}
.tgtj_sk_img:hover img{ transform: scale(1.1);}
.tgtj_sk_img img{float: left;width: 176px;height: 176px; transition: 0.3s;}
.tgtj_sk_content{float: left;width: 164px;height: 176px;background-color: #ffffff;padding: 16px 12px 10px 11px;box-sizing: border-box;}
.tgtj_sk_content h3{float: left;width: 100%;font-family: "微软雅黑";font-size: 17px;color: #4f4126;height: 18px;line-height: 18px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-bottom: 16px;}
.tgtj_sk_content h3:hover{color: #99533e !important;}
.tgtj_sk_content span{float: left;width: 100%;font-family: "微软雅黑";font-size: 14px;color: #787878;height: 16px;line-height: 16px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-bottom: 16px;}
.tgtj_sk_content p{float: left;width: 100%;font-family: "微软雅黑";font-size: 14px;color: #b19982;height: 88px;line-height: 22px;overflow: hidden;text-overflow: ellipsis;}
.tgtj_ts{float: left;width: 100%;border-radius: 4px;background-color: #fffbf7;margin-bottom: 31px;}
.tgtj_ts >ul{float: left;width: 100%;padding: 10.5px 21px;box-sizing: border-box;}
.tgtj_ts >ul >li{float: left;width: 177px;height: 227px;margin-right: 19px;padding: 6px;background-color: #eaddd4;border-radius: 4px;margin-top: 7.5px;margin-bottom: 7.5px;box-sizing: border-box;}
.tgtj_ts >ul >li:nth-child(6n){margin-right: 0px;}
.tgtj_ts_img{float: left;width: 162px;height: 162px;background-color: #f4f4f4;text-align: center;line-height: 162px; overflow: hidden;}
.tgtj_ts_img img{max-width: 162px;max-height: 162px; transition: 2s;}
.tgtj_ts_img:hover img{ transform: scale(1.1);}
.tgtj_ts_content{float: left;width: 100%;}
.tgtj_ts_content h3{float: left;width: 100%;font-family: "微软雅黑";font-size: 14px;color: #4f4126;height: 24px;line-height: 24px;text-align: center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-bottom: 6px;}
.tgtj_ts_content h3:hover{color: #99533e !important;}
.tgtj_ts_content font{float: left;width: 100%;text-align: center;font-family: "微软雅黑";font-size: 13px;color: #777777;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.tgtj_ts_price{margin: 0 auto;display: table;}
.tgtj_ts_price span{float: left;font-family: "微软雅黑";color: #cc162c;font-size: 16px;margin-right: 6px;height: 22px;line-height: 22px;}
.tgtj_ts_price span:first-child{color: #777777;font-size: 14px;text-decoration: line-through;}
.tgtj_ts_price span:last-child{margin-right: 0px;}
.tgtj_lw >ul{padding: 0px 21px;}
.tgtj_lw >ul >li{width: 100%;background-color: #fffbf7;height: 158px;border-bottom: 1px solid #e9e0d9;border-radius: 0px;margin: 0px;padding: 0px;}
.tgtj_lw >ul >li:last-child{border-bottom: none;}
.tgtj_lw ul li h4{float: left;width: 100%;margin-top: 18px;margin-bottom: 13px;height: 20px;line-height: 16px;font-family: "微软雅黑";font-size: 17px;color: #4f4126;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.tgtj_lw ul li h4:hover{color: #99533e !important;}
.tgtj_lw_box{background-color: #fffbf7;height: 20px;margin-bottom: 8px;float: left;width: 100%;display: inline-block;}
.tgtj_lw_box ul{display: block;float: left;}
.tgtj_lw_box ul li {float: left;display: inline-block;margin-right: 80px;margin-top: 6px;height: 12px;}
.tgtj_lw_box ul li em {float: left;width: 13px;height: 12px;margin-right: 5px;margin-top: 3px;}
.tgtj_lw_box ul li span { max-width: 150px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; float: left;/*height: 13px;line-height: 12px;*/font-family: 微软雅黑;font-size: 13px;color: rgb(120, 120, 120);}
.tgtj_lw_gjc{float: left;width: 100%;margin-bottom: 10px;overflow: hidden;}
.tgtj_lw_gjc i{float: left;width: 13px;height: 17px;display: block;background: url(../images/gjc_icn.png) no-repeat 0px 2px;margin-right: 4px;}
.tgtj_lw_gjc font{float: left;font-family: "微软雅黑";font-size: 13px;color: #787878;margin-right: 10px;}
.tgtj_lw_gjcli{float: left;display: inline-block;width: calc( 100% - 72px );height: 17px;}
.tgtj_lw_gjcli ul{float: left;width: 100%;}
.tgtj_lw_gjcli ul li{float: left;margin-right: 25px;font-family: "微软雅黑";font-size: 13px;color: #99533e;}
.tgtj_lw_gjcli ul li:last-child{margin-right: 0px;}
.tgtj_lw_gjcli ul li a{float: left;font-family: "微软雅黑";font-size: 13px;color: #99533e;height: 17px;line-height: 17px;}
.tgtj_lw ul li p{float: left;width: 100%;height: 40px;line-height: 20px;font-family: "微软雅黑";font-size: 13px;color: #b19982;text-overflow: ellipsis;overflow: hidden;}
.cont_tg_boxbox{float: left;width: 100%; background: #fffbf7; border-radius: 5px; min-height: 100px; margin-bottom: 31px; padding-bottom: 18px;}
.cont_tg_boxbox ul li{ width: 178px; height: 197px; border-radius: 5px; background: #eaddd4; padding: 8px; box-sizing: border-box; float: left; margin-left: 19px; margin-top: 18px; overflow: hidden;}
.cont_tg_boxbox ul li img{ width: 162px; height: 162px;}
.cont_tg_boxbox ul li p{ width: 150px; height: 28px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 14px; font-family: "微软雅黑"; color: #4f4126; line-height: 28px; text-align: center; margin: 0 auto;}
.cont_tg_boxbox ul li p:hover{color: #99533e !important;}
.cont_tg_boxbox2{ box-sizing: border-box; float: left;width: 100%; background: #fffbf7; border-radius: 5px; min-height: 100px; margin-bottom: 31px; padding: 18px 21px;}
.cont_tg_boxbox2 ul li{ width: 100%; float: left; height: 117px; border-bottom: 1px solid #e9e0d9; padding-top: 18px;}
.cont_tg_boxbox2 ul li:first-child{ padding-top: 0;}
.tg2li_left{ float: left; width: 98px; height: 98px;}
.tg2li_left img{ float: left; width: 98px; height: 98px;}
.tg2li_right{ float: right; width: 1050px;}
.tg2li_right h2{ font-size: 15px; font-family: "微软雅黑"; color: #4f4126; line-height: 30px; font-weight: 100; margin-bottom: 12px;}
.tg2li_right p{ font-size: 13px; font-family: "微软雅黑"; color: #b19982; line-height: 19px;}
.search_box{ float: left; width: 100%; margin-bottom: 10px;}
.search_boxx{ float: right; width: 146px; height: 25px; border-bottom: 1px solid #cecece;}
.search_boxx input{ width: 123px; height: 25px; float: left; box-sizing: border-box; border: none; outline: none; font-size: 13px; font-family: "微软雅黑"; background: #fefdfc; }
.search_boxx img{ float: right;}
.cont_wx_boxbox{float: left; width: 100%; background: #fffbf7; border-radius: 5px; min-height: 100px; margin-bottom: 31px; padding-bottom: 18px;}
.cont_wx_boxbox table{ width: 1160px; margin: 0 auto; margin-top: 17px;}
.cont_wx_boxbox table th{ text-align: left; font-size: 14px; font-family: "微软雅黑"; color: #ffffff; padding-left: 19px; height: 29px; line-height: 29px; background: #c19486; font-weight: 100;}
.cont_wx_boxbox table td{ padding-left: 19px; height: 45px; border-bottom: 1px solid #e9e0d9; font-size: 13px; font-family: "微软雅黑"; color: #736357;}
.cont_wx_boxbox table td a{color: #736357;}
.cont_wx_boxbox table td a:hover{color: #99533e !important;}
/*在线投稿的插件样式*/
.yggjz_wrap {float: left;	width: calc( 100% - 127px);	}
.label-selected {width: 100%;min-height: 35px;border: 1px solid #e2e2e2;background-color: #fbfbfb;border-radius: 3px;position: relative;}
.cell {	display: block;	width: 90px;height: 28px;line-height: 28px;border: 3px;	background: #009688;color: #fff;text-align: center;	}
.label-selected li {display: inline-block;	height: 27px;line-height: 27px;	font-size: .8rem;padding: 0 10px;background: #bf725c;border: 1px solid #a7604d;	border-radius: 2px;	cursor: pointer;margin: 4px 2px;color: #ffffff;	}
#labelItem {margin-bottom: 10px;display: none;}
.label-item {margin-top: -1px;	border: 1px solid #e6e6e6;padding: 10px 10px 30px 10px;	border-radius: 4px;	position: relative;	overflow: hidden;background: #fffefe;}
.label-item li {display: inline-block;	height: 27px;line-height: 27px;	font-size: .8rem;padding: 0 1rem;border: 1px solid #e5e5e5;	border-radius: 2px;cursor: pointer;margin-bottom: 5px;	margin-left: 2px;background: #ededed; font-size: 13px; font-family: "微软雅黑"; color: #2a1708;	}
.label-item .selected {	color: #fff;background: #bf725c;border-color: #a7604d;}
.tcc-nr-tp { margin-left: 20px; float: left; width: 57.3333%;height: 292px; border-width: 1px;border-style: solid; border-color: initial; border-image: initial; padding: 0px;}
.tcc-cj { display:none;}
.img-container, .img-preview{ width:477px;}
.tcc-nr-bp { display:none;}
#layui-laydate1 .layui-laydate-header { background-color:#af977e!important;}
#layui-laydate1 .layui-this { background-color:#af977e!important;}
.J_codeimg1 { display: inline-block; text-align: center; background: #fff; width: 100%; height: 42px; line-height: 42px; box-sizing: border-box; border-radius: 2px; cursor: pointer; font-size: 30px; background: url(../images/login_code_back.png) no-repeat center; background-size: 100% 100%;}
.layui-layer-loading{ display: none;}
/*首页*/
.bannermain_box{width: 1200px;margin: 0 auto;position: relative;}
.banner_contentlist{position: absolute;right: 23px;width: 365px;background: rgba(233,220,209,0.8);z-index: 10;top: 66px;border-radius: 4px;}
.banner_contentlist1{float: left;width: 100%;height: 53px; box-sizing: border-box; padding: 0 32px;}
.banner_contentlist1 h6{position: relative;width: 100%;line-height: 50px;font-family: "黑体";font-size: 20px;color: #504027; border-bottom: 3px solid #ebe4de; padding-left: 30px; box-sizing: border-box;}
.banner_contentlist1 h6::before{ position: absolute; left: 0; top: 14px; content: ''; background: url(../images/index_titleico1.png); width: 23px; height: 22px;}
.banner_contentlist2{float: left;width: 100%;padding: 10px 32px;box-sizing: border-box;}
.banner_contentlist2 ul li{float: left;width: 100%;}
.banner_contentlist2 ul li i{float: left;width: 6px;height: 6px;margin-top: 19px;margin-right: 10px;background: #7a6654;border-radius: 3px;}
.banner_contentlist2 ul li h3{float: left;height: 44px;line-height: 44px;font-family: "微软雅黑";font-size: 15px;color: #504027;width: calc( 100% - 16px );font-weight: 400;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.banner_contentlist2 ul li h3:hover{color: #99533e !important;}
.banner{ width: 100%; float: left; margin-top: 130px; height: 617px; padding: 19px 0; box-sizing: border-box; background: url(../images/banner_bg.png) no-repeat top center;}
.banner_img{ width: 100%; float: left; height: 874px; position: relative;}
.banner_img ul li{ float: left; height: 579px; width: 100%; overflow:hidden;}
.dots {position: absolute;left: 0;right: 0;bottom: 30px; text-align: center; width: 100vw;}
.dots li { width: 78px; height: 3px; opacity: 0.6; background: #fff; display: inline-block; margin-left: 4px; margin-right: 4px; overflow: hidden; cursor: pointer;}
.dots li.active {background: #fff;opacity: 1;}
.banner_img ul li img{  float: left; height: 579px; margin-left: calc(50vw - 960px); width: 1920px;}
.banner_img{width: 100% !important;}
/*首页*/
.syml_main{float: left;width: 100%;background: #f2ede9;height: 397px;    background: url(../images/main_bg4.png) no-repeat top #f2ede9;}
.syml_mainbox{width: 1200px;margin: 0 auto;}
.syml_mainbox_title{float:left;width: 100%;margin-top: 30px;}
.syml_title{width: 280px;height: 58px;margin: 0 auto;background: url(../images/symu_bg.png) no-repeat;line-height: 58px;text-align: center;}
.syml_title img{ vertical-align: middle;}
.content_title{float: left;margin-bottom: 20px;height: 30px;line-height: 30px;margin-top: 25px; width: 100%;}
.content_title i{float: left;width: 30px;height: 30px;display: block;background: url(../images/maintitle_ico.png) no-repeat;margin-right: 9px}
.content_title img{ float: left; margin-top: 2px;}
.content_title a{ float: right; font-size: 15px; color: #504027; font-family: "黑体"; margin-top: 4px;}
.content_title a:hover{ color:#99533e;}
.entry_box .content_title i{width: 42px; height: 29px; background: url(../images/title_bg.png);}
.entry_box .content_title a{position: relative; margin-left: 70px; float: none; display: inline-block; vertical-align: bottom; color: #7A6655;}
.entry_box .content_title a:hover{ color:#99533e;}
.entry_box .content_title a::before{content: ""; display: block; width: 50px; height: 1px; background-color: #7A6655; position: absolute; left: -64px; top: 13px;}
.syml_list{float: left;width: 100%;}
.syml_list ul{float: left;display: inline-block;}
.syml_list ul li{float: left;width: 207px;height: 207px;margin-right: 41px;border-radius: 4px;box-sizing: border-box;overflow: hidden;}
.syml_list ul li:last-child{margin-right: 0px;}
.syml_list ul li img{float: left;width: 100%;height: 100%; transition: 2s;}
.syml_list ul li:hover img{ transform: scale(1.1);}
.syml_list ul li h3{float: left;height: 28px;width: 100%;line-height: 28px;margin-top: -28px;text-align: center;color: #f2ede9;background: #252321;opacity: 0.8;font-family: "黑体";font-size: 15px;font-weight: 100;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}

.wxww_main{float: left;width: 100%;;height: 410px;background: #dacec5;}
.main_symlbox .wxww_main{ background: inherit;}
.wxww_boxf{float: left;width: 386px;margin-right: 21px;}
.wxww_boxf_list{float: left;width: 100%;}
.wxww_boxf_list ul{float: left;width: 100%;}
.wxww_boxf_list ul li{float: left;width: 100%;margin-bottom: 42px;height: 14px;line-height: 14px;}
.wxww_boxf_list ul li:last-child{margin-bottom: 0px;}
.wxww_boxf_list ul li:hover font{ color: #99533e;}
.wxww_boxf_list ul li:hover h3{ color: #99533e;}
.wxww_boxf_list ul li em{float: left;width: 5px;height: 5px;border-radius: 2px;display: block;background: #504027;margin-right: 11px;margin-top: 4.5px}
.wxww_boxf_list ul li font{float: left;margin-right: 14px;font-family: "微软雅黑";font-size: 15px;color: #504027;width: 70px;height: 17px;line-height: 17px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.wxww_boxf_list ul li h3{float: left;font-weight: 100;font-family: "微软雅黑";font-size: 15px;color: #504027; width:250px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;height: 17px;line-height: 17px;}
.wxww_boxr{float: right;width: 815px;}
.wxww_boxr_list ul li{margin-right: 19px;margin-bottom: 20px;}
.wxww_boxr_list ul li:nth-child(4n){margin-right: 0px;}
.ww_detail_right{ width: 100%; height: 299px; float: left; background: #f2ede9; border-radius: 4px; overflow: hidden;}
.ww_detail_rightt{ width: 100%; float: left; height: 184px; overflow-y: auto; padding-left: 20px; padding-right: 20px; box-sizing: border-box;}
.ww_detail_rightt h2{ font-size: 18px; cursor: pointer; font-family: "黑体"; color: #504027; line-height: 48px;}
.ww_detail_rightt p{ font-size: 15px; font-family: "黑体"; color: #7a6654; line-height: 32px; margin-top: -4px;word-break: break-word;}
.ww_detail_rightt p img{ width: 15px; height: 15px;}
.ww_detail_rightb{ width: 100%; float: left; background: #ebe1d9; height: 115px; padding: 15px 27px; box-sizing: border-box;}
.ww_detail_rightb a{ transition: 0.2s; width: 158px; height: 35px; float: left; border: 1px solid #8b7867; background: #f8eee5; box-sizing: border-box; border-radius: 4px; text-align: center; line-height: 33px; margin-right: 14px; margin-bottom: 12px; font-size: 15px; font-family: "黑体"; color: #504027; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.ww_detail_rightb a:hover{background: #99533e; color: #fff; border-color:#99533e;}
.ww_detail_rightb a:nth-child(2n){ margin-right: 0;}
.ww_detail_rightred{ width: 100%; height: 299px; float: left; background: #f8eee5; border-radius: 4px; overflow: hidden;}
.ww_detail_rightred ul li{ width: 325px; float: left; margin-left: 30px; height: 96px; border-bottom: 1px solid #af967f;}
.ww_detail_rightred ul li h3{ width: 100%; float: left; margin-top: 23px; margin-bottom: 19px; font-size: 18px; line-height: 18px; font-family: "黑体"; color: #504027; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.ww_detail_rightred ul li p{ width: 100%; float: left; font-size: 15px; line-height: 15px; font-family: "黑体"; color: #7a6654; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.ww_detail_rightred ul li:last-child{ border-bottom: 0;}
.ww_detail_rightred ul li:first-child{ margin-top: 5px;}











.img-inline{ width: 15px; height: 15px;}
.smyzrw_main{float: left;width: 100%;height: 526px;background: url(../images/main_bg2.jpg) no-repeat center;}
.sm_mainbox{float: left;width: 386px;margin-right: 21px;}
.sm_contentbox{float: left;width: 100%;height: 425px;border-radius: 3px;background: #f8eee5;overflow: hidden;padding:30px 30px 17px 30px;box-sizing: border-box;}
.sm_contentbox>ul{float: left;width: 100%;}
.sm_contentbox>ul>li{float: left;width: 100%;height: 86px;border-bottom: 1px solid #af967f;margin-bottom: 10px;}
.sm_contentbox>ul>li h3{float: left;width: 100%;font-family: "黑体";font-size: 18px;color: #504027;height: 39px;line-height: 39px;font-weight: 100;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-bottom: 10px}
.sm_contentbox>ul>li h3:hover{color: #99533e !important;}
.sm_contentbox>ul>li p{float: left;width: 100%;font-family: "黑体";font-size: 15px;color: #7a6654;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.sm_contentbox>ul>li:last-child{border: none;margin-bottom: 0px;}
.yz_contentbox134{ overflow-y: auto; overflow-x: hidden; padding: 15px 20px; width: 100%; float: left; height: 165px; background: #fff5ec; margin-bottom: 15px; border: 1px solid #887564; box-sizing: border-box; border-radius: 4px;}
.yz_contentbox134 h2{ font-size: 18px; cursor: pointer; font-family: "黑体"; color: #504027; line-height: 18px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin-bottom: 10px;}
.yz_contentbox134 span{ font-size: 15px; font-family: "黑体"; color: #7a6654; line-height: 26px;word-break: break-word;}
.yz_contentbox134 span img{ width: 15px; height: 15px;}
.rw_contentbox213 span img{ width: 15px; height: 15px; margin-left: -1px; margin-top: -1px;}
.yz_contentbox1{float: left;width: 100%;margin-bottom: 24px;}
.yz_contentbox1 li{float: left;width: 157px;height: 157px;border-radius: 2px;box-sizing: border-box;overflow: hidden;margin-right: 12px}
.yz_contentbox1 li:last-child{margin-right: 0px;}
.yz_contentbox1 li img{float: left;width: 100%;height: 100%;}
.yz_contentbox1 li h3{float: left;height: 28px;width: 100%;line-height: 28px;margin-top: -28px;text-align: center;color: #f2ede9;background: #252321;opacity: 0.8;font-family: "黑体";font-size: 15px;font-weight: 100;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.yz_contentbox2{float: left;width: 100%;}
.yz_contentbox2 li{ transition: 0.2s; float: left;width: 157px;margin-right: 12px;margin-bottom: 15px;height: 35px;border: 1px solid #8b7867;border-radius: 3px;box-sizing: border-box;overflow: hidden;}
.yz_contentbox2 li:nth-child(2n){margin-right: 0px;}
.yz_contentbox2 li span{ transition: 0.2s; float: left;width: 100%;height: 35px;line-height: 35px;color: #504027;font-family: "黑体";font-size: 15px;text-align: center;}
.yz_contentbox2 li:hover{ background: #99533e; color: #fff; border-color: #99533e;}
.yz_contentbox2 li:hover span{ color: #fff;}

.rw_mainbox{margin-right: 0px;}
.rw_contentbox2{float: left;width: 100%;margin-bottom: 16px;}
.rw_contentbox213{ width: 326px; height: 102px; border-radius: 4px; float: left; border: 1px solid #8a7766; box-sizing: border-box; background: #fff5ec; margin-bottom: 9px; padding: 15px 20px;}
.rw_contentbox213 h2{ font-size: 18px; cursor: pointer; font-family: "黑体"; color: #504027;height: 21px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.rw_contentbox213 span{ color: #7a6654; font-size: 15px; font-family: "黑体"; line-height: 28px;}
.rw_contentbox2 li{float: left;width: 100%;height: 103px;margin-bottom: 9px;padding: 11px 35px 11px 18px;overflow: hidden;box-sizing: border-box;border: 1px solid #8c7968;border-radius: 3px;}
.rw_contentbox2 li:last-child{margin-bottom: 0px;}
.rw_contentbox2 li img{float: left;width: 78px;height: 78px;border-radius: 50%;border: 3px solid #93b39b;box-sizing: border-box;margin-right: 13px;}
.rw_contentbox2r{float: left;width: 180px;}
.rw_contentbox2r h3{float: left;height: 27px;width: 100%;line-height: 27px;font-family: "黑体";font-size: 18px;font-weight: 100;color: #504027;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.rw_contentbox2r p{float: left;width: 100%;font-family: "黑体";font-size: 15px;color: #7a6654;line-height: 27px;overflow: hidden;text-overflow: ellipsis;}

.yjcgts_main{width: 100%;float: left;background: url(../images/main_bg3.png) no-repeat center #f9f6f4; height: 445px;}
.yjcgts_title{background: url(../images/yj_bg.png) no-repeat;width: 357px;}
.yjcgts_contentbox{float: left;width: 100%;}
.yjtscg_ts{margin-bottom: 0px;background:none;}
.yjtscg_ts ul{padding: 0px;}
.yjtscg_ts ul li{margin-right: 27px;margin-top: 0px;margin-bottom: 0px;}

.lw_mainbox{width: 100%;float: left;height: 746px;padding-bottom: 20px;}
.lw_contentbox{float: left;width: 100%;height: 296px;background-color: #af967f;border-top: 5px solid #99533e;border-radius: 3px;box-sizing: border-box;margin-bottom: 30px;}
.lw_boxf_list ul{padding: 36px 41px 8px 42px;box-sizing: border-box;}
.lw_boxf_list ul li{width: 305px;margin-bottom: 26px;margin-right: 100px;}
.lw_boxf_list ul li:nth-child(3n){margin-right: 0px;}
.lw_boxf_list ul li em{background: #fff6ec;}
.lw_boxf_list ul li font{color: #fff5ec;}
.lw_boxf_list ul li h3{color: #fff5ec;max-width: 205px;}

.qk_contentbox{float: left;width: 100%;height: 345px;background-color: #473d39;border-radius: 3px;padding: 0px 42px;box-sizing: border-box;}
.qk_contentbox1{float: left;margin-right: 34px;width: 671px;}
.qk_contentbox1_list{float: left;width: 100%;}
.qk_contentbox1_list li{float: left;width: 209px;height: 252px;margin-right: 22px;}
.qk_contentbox1_list li:last-child{margin-right: 0px;}
.qk_image{float: left;width: 209px;height: 209px;padding: 10px;box-sizing: border-box;background-color: #eaddd4;}
.qk_imagebg{float: left;width: 189px;height: 189px;background-color: #f4f4f4;line-height: 199px;text-align: center; overflow: hidden;}
.qk_imagebg img{max-width: 189px;max-height: 189px; transition: 0.3s;}
.qk_imagebg:hover img{ transform: scale(1.1);}
.qk_contentbox1_list li h3{float: left;width: 100%;height: 42px;line-height: 42px;text-align: center;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;font-family: "微软雅黑";font-size: 15px;color: #fff5ec;font-weight: 100;}
.qk_contentbox2{float: right;width: 411px;}
.qt_contentbox_list{float: left;width: 100%;}
.qt_contentbox_list li{float: left;width: 100%;height: 73px;margin-bottom: 20px;border-bottom: 1px solid #fff5ec;box-sizing: border-box; position: relative;}
.qt_contentbox_list li:last-child{margin-bottom: 0px;border-bottom: none;}
.qt_contentbox_list li h3{float:left;width: 100%;height: 20px;line-height: 20px;font-family: "微软雅黑";font-size: 18px;font-weight: 100;color: #ffffff;margin-bottom: 16px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.qt_contentbox_list li p{float: left;width: 100%;height: 15px;line-height: 15px;font-family: "微软雅黑";font-size: 14px;color: #fff5ec;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.qt_contentbox_list li:before{ content: ""; position: absolute; width: 1px; height: 1px; left: 0; bottom: -1px; background: #99533e; transition: 0.4s;}
.qt_contentbox_list li:hover::before{ width: 100%;}


.gwxsjk_mainbox{float: left;width: 100%; min-height: 703px; padding-bottom:30px; background: url(../images/main_bg5.png) no-repeat center;background-color: #f2ede9;}
.gwxsjk_contentbox{float: left;width: 100%;}
.gwxsjk_boxr_list{margin-top: 48px; height: 495px;}
.gwxsjk_boxr_list ul li{ width: 207px; height: 227px; float: left; margin-right: 41px; background: #eaddd4; margin-bottom: 41px; border-radius: 5px; padding: 9px; box-sizing: border-box;}
.gwxsjk_boxr_list ul li:nth-child(5n){margin-right: 0px;}
.gwxsjk_boxr_list ul li a{ width: 188px; height: 188px; border-radius: 5px; background: #f4f4f4; float: left; line-height: 188px; text-align: center;}
.gwxsjk_boxr_list ul li a img{ max-width: 100%; max-height: 100%;}
.gwxsjk_boxr_list ul li h3{ width: 100%; float: left; cursor: pointer; line-height: 30px; font-size: 14px; font-family: "微软雅黑"; color: #4f4126; text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.yzsjk_mainbox_title{margin-top: 19px;}
.yzsjk_mainbox1{float: left;width: 100%;height: 108px;background-color: #fff;}
.yzsjk_mainbox2{float: left;width: 100%;height: 47px;border-top: 1px solid #c3b7ab;border-bottom: 1px solid #c3b7ab;box-sizing: border-box;background-color: #d7cdc3;}
.yzsjk_titlebox{float: left;width: 100%; position: relative;}
.yzsjk_titlebox2{ position: absolute; left: 0; top: 4px;}
.yzsjk_titlebox li{float: left;margin-right: 5px;height: 47px; width: 114px; position: relative;}
.yzsjk_titlebox li span{ width: 100%; text-align: center; float: left;min-width: 36px;padding: 0px 14px;box-sizing: border-box;text-align: center;height: 47px;line-height: 47px;font-family: "微软雅黑";font-size: 17px;color: #fff;}
.yzsjk_titlebox_selected span{color: #fff !important;}
.yzsjk_titlebox li:hover span{ color: #fff;;}
.yzsjk_mainbox3{float: left;width: 100%; min-height: 599px; background-color: #ebe5e1; padding-bottom: 30px;}
.yzsjk_boxr_list{margin-top: 40px; height: 495px;}
.yzsjk_boxr_list ul li{margin-right: 41px;margin-bottom: 40px;}
.yzsjk_boxr_list ul li:nth-child(5n){margin-right: 0px;}

.zy_mainbox{float: left;width: 100%;height: 710px;background: url(../images/main_bg6.png) no-repeat center;}
.zy_contentbox{padding: 0px;background-color: #fffbf7;border: 1px solid #ddd3cb;}
.zy_contentbox_top{float: left;width: 100%;height: 240px;margin-bottom: 10px; overflow: hidden;}
.zy_contentbox_top img{float: left;width: 100%;height: 100%; transition: 2s;}
.zy_contentbox_top img:hover{ transform: scale(1.1);}
.zy_contentbox_top h3{float: left;width: 100%;height: 49px;margin-top: -49px;line-height: 49px;opacity: 0.8;background-color: #252321;color: #ffffff;text-align: center;font-family: "微软雅黑";font-size: 20px;font-weight: 100;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.zy_contentbox_bottom{float: left;width: 100%;padding: 0px 42px;box-sizing: border-box;}
.zy_contentbox_bottom li{float: left;height: 38px;width: 100%;}
.zy_contentbox_bottom li i{float: left;width: 7px;height: 7px;background-color: #ae9179;border-radius: 50%;margin-top: 15px;margin-right: 13px;}
.zy_contentbox_bottom li h3{float: left;width: calc( 100% - 20px );height: 38px;line-height: 38px;font-family: "微软雅黑";font-size: 15px;color: #504027;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.zy_contentbox_bottom li h3:hover{ color: #99533e;}
.mzys_contentbox{background-color: #fffbf7;border: 1px solid #ddd3cb;padding: 0px;}
.mzys_contentbox_top{padding: 21px 36px 33px 28px;box-sizing: border-box;background-color: #ebe1d9;}
.mzys_contentbox_top h3{background-color: #ebe1d9;margin-top: 0px;color: #504027; text-align: left;}
.mzys_contentbox_top h3:hover{ color: #99533e;}
.zy_contentbox_top1{float: left;width: 100%;}
.zy_contentbox_top1l{float: left;width: 143px;height: 143px;line-height: 143px;text-align: center;background-color: #f4f4f4;border-radius: 3px;margin-right: 14px; overflow: hidden;}
.zy_contentbox_top1l img{max-width: 143px;max-height: 143px;width: auto;height: auto;float: none;}
.zy_contentbox_top1r{float: right;width: 163px;margin-top: 26px;}
.zy_contentbox_top1r span{float: left;width: 100%;font-family: "微软雅黑";font-size: 14px;color: #504027;margin-bottom: 9px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.mzys_contentbox_bottom{padding: 0px 40px;}
.mzys_contentbox_bottom p{float: left;width: 100%;font-family: "微软雅黑";font-size: 15px;color: #504027;line-height: 37px;word-break: break-word;}
.dyqk_mainbox{margin-right: 0px;}
.zjxzk_contentbox2{padding: 16px 29px;box-sizing: border-box;height: 121px;}
.mzys_contentbox_top1{float: left;width: 100%;margin-bottom: 8px;}
.mzys_contentbox_top1l{float: left;width: 90px;height: 90px;margin-right: 24px;}
.mzys_contentbox_top1l img{float: left;width: 90px;height: 90px;border-radius: 50%;border: 3px solid #93b39b;box-sizing: border-box; overflow: hidden;}
.mzys_contentbox_top1 font{float: left;font-family: "黑体";font-size: 20px;color: #504027;line-height: 90px;height: 90px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: calc( 100% - 114px );}
.mzys_contentbox_top2{float: left;width: 100%;}
.mzys_contentbox_top2 p{float: left;width: 100%;line-height: 28px;font-family: "黑体";font-size: 13px;color: #504027;word-break: break-word;}
.kyfzpt_contentbox{background-color: #fffbf7;border: 1px solid #ddd3cb;padding: 34px 38px 0px;}
.zxtg_btn{float: left;width: 100%;height: 101px;margin-bottom: 26px;}
.zxtg_btn img{float: left;width: 100%;height: 101px;}
.kyfzpt_contentboxli{float: left;width: 100%;}
.kyfzpt_contentboxli li{float: left;width: 152px;height: 124px;margin-right: 4px;}
.kyfzpt_contentboxli li:nth-child(2n){margin-right: 0px;}
.kyfzpt_contentboxli li img{float: left;width: 100%;height: 89px;border-radius: 2px;box-sizing: border-box;}
.kyfzpt_contentboxli li span{float: left;width: 100%;height: 35px;line-height: 35px;text-align: center;font-family: "微软雅黑";font-size: 15px;color: #504027;}
.yqlj_mainbox{float: left;width: 100%;margin-top: 45px;}
.yqlj_mainbox h4{float: left;width: 100%;font-family: "微软雅黑";font-size: 20px;color: #ffffff;font-weight: 400;margin-bottom: 25px;}
.yqlj_mainboxli{float: left;width: 100%;}
.yqlj_mainboxli ul{float: left;width: 100%;}
.yqlj_mainboxli ul li{float: left;width: 200px;height: 34px;border: 1px solid #fff;border-radius: 4px;margin-bottom: 18px;margin-right: 47px;}
.yqlj_mainboxli ul li span{width: 100%;height: 34px;line-height: 34px;text-align: center;float: left;font-family: "微软雅黑";font-size: 15px;color: #ffffff;}
.yqlj_mainboxli ul li:nth-child(5n){margin-right: 0px;}
.yqlj_mainboxli ul li:hover{background: rgba(255,255,255,0.3);}
.button_box{ width: 100%; float: left;}
.button_box a{ font-size: 15px; font-family: "微软雅黑"; color: #bd997d; width: 157px; height: 35px; border-radius: 4px; background: #fffbf7; display: table; margin: 0 auto; text-align: center; line-height: 35px;}
.button_box a span{ width: 16px; height: 16px; border-radius: 50%; background: #bd997d; color: #fff; display: inline-block; text-align: center; line-height: 16px;}
.button_box a img{ margin-top: -4px; margin-left: 4px;}
.button_box a:hover{ color: #99533e;}























/*个人中心机构用户*/
.jgyh{ background: #28b9b4!important;}
.w692px{ width: 692px!important;}
.edit_subaccount{ width: 840px; margin: 0 auto; overflow: hidden; margin-top: 20px; margin-bottom: 20px;}
.edit_subaccount ul li{ width: 100%; float: left; margin-bottom: 25px; position: relative;}
.secondary_title{ font-family: "微软雅黑"; color: #736357; font-size: 15px; line-height: 15px; height: 15px; margin-bottom: 15px; width: 100%; float: left; font-weight: 600;}
.edit_subaccount ul li i{ width: 50%; float: left; height: 35px;}
.edit_subaccount ul li i p{ font-family: "微软雅黑"; color: #736357; font-size: 14px; float: left; line-height: 35px; width: 45px;}
.edit_subaccount ul li i input{ width: 353px; height: 35px; line-height: 35px; background: #fbfbfb; color: #454545; border-radius: 4px;border: 1px solid #e2e2e2; box-sizing: border-box; outline: none; padding-left: 8px; font-size: 13px;}
.zherror{top: 68px;width: calc( 50% - 65px );margin-left: 46px;}
/*授权ip管理*/
.cont_left_li:nth-child(6).cur:before { content: ''; width：0: ; height: 0; border-top: 5.5px solid transparent; border-bottom: 5.5px solid transparent; border-right: 8px solid #736357; position: absolute; left: 203px; top: 19px;}
.change_c_box_bottom{ width: 100%; float: left; height: 33px; margin-top: 30px; text-align: center;}
.change_c_box_bottom a{ width: 115px; height: 33px; border: 1px dashed #b2aaa4; display: inline-block; box-sizing: border-box; border-radius: 2px;}
.change_c_box_bottom a font{ font-family: "微软雅黑"; color: #b3aba5; font-size: 13px; line-height: 32px; float: left;}
.change_c_box_bottom a img{ float: left; margin-left: 16px; margin-top: 9px; margin-right: 5px;}
.dete_liico{ position: absolute; right: -32px; top: 0px; display: none; width: 35px; height: 42px;}
.dete_liico a{ float: right; margin-top: 9px;}
.change_c_box ul li:hover .dete_liico{ display: block;}
.dete_liico2{ position: absolute; right: 5px; top: 0; display: none; width: 35px; height: 42px;}
.edit_subaccount ul li:hover .dete_liico2{ display: block;}
/*会员缴费弹窗*/
.dltc_box2 { position: fixed; left: 50%; margin-left: -198px; top: 50%; margin-top: -180px; z-index: 1001;}
.dltc_box2_main { float: left; width: 466px; background-color: #ffffff; border-radius: 4px; border: 1px solid #736357; box-sizing: border-box;}
.dltc_box2_mainm { width: 397px; padding: 34px 34px 25px 34px; float: left;}
.tc_abox { width: 100%; float: left;}
.tc_abox a{ font-family: "微软雅黑"; color: #fff; display: block; width: 245px; text-align: center; height: 40px; line-height: 40px; border-radius: 3px; height: 30px; line-height: 30px; background-color: #736357; font-size: 14px; margin: 0 auto;}
.hyjftc_box{float: left;width: 396px;padding: 0px 35px;}
.hyjftc_boxmain1{float: left;width: 100%;padding: 17px 0px;height: 104px;border-bottom: 1px solid #f4ebe4;box-sizing: border-box;margin-bottom: 16px;}
.hyjftc_boxmain1l{float: left;width:75px;height: 68px;position: relative;margin-right: 3px;}
.hyjftc_boxmain1l >img{border-radius: 50%;width: 68px;height: 68px;}
.hyjfvip_box{width: 24px;height: 21px;position: absolute;bottom: 0px;right: 0px;}
.hyjftc_boxmain1r{float: left;height: 68px;width: 316px;}
.hyjftc_boxmain1r_1{float: left;height: 68px;width: 160px;}
.hyjftc_boxmain1r_1 h3{float: left;font-family: "微软雅黑";font-size: 15px;color: #2a1708;font-weight: 100;margin-top: 14px;margin-bottom: 10px;width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.hyjftc_boxmain1r_1 p{float: left;font-size: 13px;color: #818080;font-family: "微软雅黑";}
.hyjftc_boxmain2{float: left;width: 100%;}
.hyjftc_boxmain2 h4{float: left;width: 100%;font-weight: 100;font-family: "微软雅黑";font-size: 13px;color: #2a1708;margin-bottom: 10px;}
.hyjftc_boxmain2l{float: left;width: 100%;}
.hyjftc_boxmain2l li{float: left;position: relative;width: 85px;height: 85px;border-radius: 4px;border: 1px solid #e2dedb;box-sizing: border-box;margin-right: 18px;background-color: #ffffff;margin-bottom: 18px;}
.hyjftc_boxmain2l li:last-child{margin-right: 0px;}
.hyjftc_boxmain2l li span{float: left;width: 69px;height: 55px;line-height: 55px;text-align: center;border-bottom: 1px dashed #e2dedb;font-family: "微软雅黑";font-size:15px;color: #787574;margin-left: 8px;box-sizing: border-box;}
.hyjftc_boxmain2l li font{float: left;font-family: "微软雅黑";font-size: 13px;color: #787574;width: 100%;text-align: center;height: 27px;line-height: 27px;}
.tc_selected{border-color: #b08875 !important;background-color: #fffbf7 !important;background: url(../images/duihao_ico.png) no-repeat right -2px;}
.tc_selected span{color: #b08875 !important;border-color: #f6e5d8;}
.tc_selected font{color: #818080 !important;}
.hyjftc_boxmainzf{float: left;width: 100%;}
.hyjftc_boxmainzf1{float: left;width: 50%;text-align: center;height: 67px;line-height: 67px;}
.hyjftc_boxmainzf1 label{cursor: pointer;}

/*会员缴费弹窗2*/
.hyjf_tc1{margin-top: -237px;}
.hyjf_tc2{margin-top: -288px;}
.hyjftc_boxmain1r_2{float: right;width: 150px;}
.hyjftc_boxmain1r_2 span{float: left;width:100%;font-size: 13px;font-family: "微软雅黑";color: #818080;height: 24px;line-height: 24px;}
.xztc_box{float: left;width: 100%;margin-bottom: 16px;}
.xztc_box li{float: left;width: 100%;margin-bottom: 12px;height: 31px;}
.xztc_box li label{cursor: pointer;}
.xztc_box li:last-child{margin-bottom: 0px;}
.xztc_box li input{float: left;height: 31px;line-height: 31px;margin-right: 5px;}
.xztc_box li font{float: left;height: 31px;line-height: 31px;margin-right: 15px;width: 52px;font-family: "微软雅黑";font-size: 13px;color: #818080;}
.xztc_boxchoose{float: left;width: 108px;height: 31px;background-color: #fbfbfb;box-sizing: border-box;font-family: 微软雅黑;font-size: 13px;color: #454545;display: block;padding-left: 8px;border-width: 1px;border-style: solid;border-color: rgb(226, 226, 226);border-image: initial;border-radius: 3px;}
.xztc_boxchoose>p{float: left;width: 100%;height: 31px;line-height: 31px;font-family: 微软雅黑;font-size: 13px;color: #000000}
.xztc_boxchoose .layui-select-title input{ border: none; padding-left: 0;height: 29px;background-color: #fbfbfb;}
.xztc_boxchoose .layui-select-title input::placeholder{ color: #d5d5d5;}
.xztc_boxchoose .layui-input-block{ margin-left: 0;}
.xztc_boxchoose .layui-form-select dl dd.layui-this{ background-color: #af967f!important;color: #ffffff;}
.xztc_boxchoose .layui-anim{ margin-left: -8px; width: 108px;}
.xztc_boxchoose .layui-form-selected .layui-edge{margin-top: 4px;}
.xztc_boxchoose .layui-form-select .layui-edge{margin-top: 11px;}
.xztc_boxchoose .layui-form-select dl{top:35px;}
.xzsc_box{float: left;width: 100%;}
.xzsc_box li{float: left;width:96px;height: 13px;margin-bottom: 15px;}
.xzsc_box li label{cursor: pointer;}
.xzsc_box li input{float: left;height: 13px;margin-right: 6px;}
.xzsc_box li span{float: left;height: 13px;line-height: 13px;font-family: "微软雅黑";font-size: 13px;color: #818080;}
.jf_main2{height: 106px;border-bottom: 1px solid #f4ebe4;margin-bottom: 28px;}
.jf_total{display:table;margin: 0 auto;height: 24px;}
.jf_total span{float: left;margin-top: 11px;font-family: "微软雅黑";font-size: 13px;color: #2a1708;margin-right: 12px;}
.jf_total p{float: left;font-family: "微软雅黑";font-size: 31px;color: #f82727;height: 24px;line-height: 24px;}
.label-selected p {padding-left: 8px;font-family: "微软雅黑";font-size: 13px;color: #d5d5d5;line-height:34px;left:0;top:0;position:absolute;}
/*研究成果分类特色数据库-论文详情*/
.left_box{ width: 792px; float: left;}
.right_box{ width: 379px; float: right; min-height: 400px; margin-bottom: 20px;}
.yjcg_box1{ width: 100%; float: left; background: #eaddd4; border-radius: 4px; padding: 20px; box-sizing: border-box; margin-bottom: 20px;}
.title_box_t2{ width: 100%; float: left;}
.title_box_t2 img{ float: left;}
.title_box_t2 p{ font-size: 18px; font-family: "微软雅黑"; color: #726256; line-height: 22px; float: left; margin-left: 5px;}
.sc_box{ margin-top: 1px; float: right; width: 71px; height: 27px; border-radius: 2px; border: 1px solid #a39990; text-align: center; line-height: 27px; padding-left: 14px; box-sizing: border-box; background: url(../images/star_ico.png) no-repeat 10px 6px #fffbf7;}
.yjcg_box1_t{ font-size: 14px; font-family: "微软雅黑"; color: #3f3126; line-height: 24px; padding: 10px 15px; box-sizing: border-box; width: 752px; float: left; border-radius: 5px; background: #fffbf7; margin-top: 15px;word-break: break-word;}
.yjcg_box1_b{ width: 100%; float: left; margin-top: 10px;}
.yjcg_box1_b1{ width: 640px; float: left;}
.yjcg_box1_b2{ width: 111px; float: right;}
.yjcg_box1_b1 ul li{ width: 100%; float: left;}
.yjcg_box1_b1 ul li p{ float: left; font-size: 13px; font-family: "微软雅黑"; color: #4f4126; height: 30px; line-height: 30px; width: 65px;}
.yjcg_box1_b1 ul li font{ float: left; width: calc( 100% - 65px ); font-size: 13px; font-family: "微软雅黑"; color: #676767; line-height: 30px;}
.yjcg_box1_b1 ul li font a{ color: #99533e; float: left; margin-right: 20px;}
.yjcg_box1_b2t{ width: 100%; float: right; margin-top: 15px;}
.yjcg_box1_b2t font{ font-size: 15px; font-family: "微软雅黑"; color: #c5c5c5; text-decoration: line-through; width: 100%; text-align: right; float: left;}
.yjcg_box1_b2t span{ font-size: 15px; font-family: "微软雅黑"; color: #cc1616; width: 100%; text-align: right; float: left;}
.yjcg_box1_b2b{ width: 100%; float: left; margin-top: 21px;}
.yjcg_box1_b2b a{ width: 100%; float: left; height: 27px; background: #af967f; margin-top: 15px; border-radius: 2px; text-align: center; line-height: 27px; color: #fff; font-size: 13px; font-family: "微软雅黑";}
.yjcg_box1_b2b a:hover{ opacity: 0.9;}
.xgsk_box{ width: 100%; float: left; background: #fffbf7; padding: 18px; box-sizing: border-box; border-radius: 0 0 4px 4px; border: 2px solid #ebe1d9; border-top: 0; margin-bottom: 20px;}
.xgsk_box ul li{ width: 360px; float: left; height: 144px; margin-right: 32px; margin-bottom: 20px;}
.xgsk_box ul li:nth-child(2n){ margin-right: 0;}
.xgsk_box1{ width: 144px; height: 144px; float: left; overflow: hidden;position: relative;}
.sk_zd{background: url(../images/zd_ico.png) no-repeat;position: absolute;width: 48px;height: 52px;z-index: 50;}
.xgsk_box1 img{ width: 144px; height: 144px; float: left; transition: 0.3s;}
.xgsk_box1:hover img{ transform: scale(1.1);}
.xgsk_box2{ float: left; width: 216px; height: 144px; padding-left: 10px; box-sizing: border-box; background: #f6eee8;}
.xgsk_box2 h2{ font-size: 17px; font-family: "微软雅黑"; color: #4f4126; font-weight: 100; margin-bottom: 3px; line-height: 50px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;height: 50px;}
.xgsk_box2 h2:hover{color: #99533e !important;}
.xgsk_box2 h3{ white-space: nowrap; overflow: hidden; text-overflow: ellipsis; font-size: 14px; font-family: "微软雅黑"; color: #787878; font-weight: 100; height: 14px; line-height: 14px; margin-bottom: 16px;}
.xgsk_box2 >font{ font-size: 14px; font-family: "微软雅黑"; color: #b19982; font-weight: 100; line-height: 25px; width: 95%; float: left;}
.left_box .listtitle_box{ border-radius: 4px 4px 0 0;}
.xgts_box{ width: 100%; float: left; background: #fffbf7; padding: 18px; box-sizing: border-box; border-radius: 0 0 4px 4px; border: 2px solid #ebe1d9; border-top: 0; margin-bottom: 20px;}
.xgts_box ul li{ width: 128px; cursor: pointer; float: left; margin-right: 28px; border: 2px solid #eaddd4; border-radius: 2px; box-sizing: border-box; position: relative; overflow: hidden;}
.xgts_box ul li:last-child{ margin-right: 0;}
.xgts_boxt{ width: 124px; height: 124px; float: left; background: #f4f4f4; text-align: center; line-height: 119px;}
.xgts_boxt img{ max-height: 124px; max-width: 124px; transition: 0.3s;}
.xgts_boxt:hover img{ transform: scale(1.1);}
.xgts_boxb{ width: 124px; float: left; background: #eaddd4;}
.xgts_box ul li:hover .xgts_boxb3{ top: 0;}
.xgts_boxb3{ width: 124px; float: left; background: url(../images/beijing.png); position: absolute; height: 124px; left: 0; top:124px; transition: 0.3s;}
.xgts_boxb3 h3{ font-size: 13px; font-family: "微软雅黑"; color: #ffffff; font-weight: 100; width: 100%; float: left; text-align: center; margin-top: 34px;}
.xgts_boxb3 span{ font-size: 12px; font-family: "微软雅黑"; color: #ffffff; width: 100%; float: left; text-align: center; margin-top: 5px;}
.xgts_boxb3 i{ font-size: 13px; font-family: "微软雅黑"; color: #ffffff; width: 100%; float: left; text-align: center; margin-top: 5px;}
.xgts_boxb h3{ font-size: 13px; font-family: "微软雅黑"; color: #726256; line-height: 30px; text-align: center; width: 100%; float: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.xgts_boxb h3:hover{color: #99533e !important;}
.xgts_boxb span{ font-size: 12px; font-family: "微软雅黑"; color: #777777; width: 100%; float: left; text-align: center; margin-bottom: 4px;}
.xgts_boxb i{ font-size: 13px; font-family: "微软雅黑"; text-align: center; width: 100%; float: left; margin-bottom: 3px;}
.xgts_boxb i b{ font-weight: 100; display: inline; color: #777777; text-decoration: line-through;}
.xgts_boxb i p{display: inline; color: #cc1616;}
.xgyp_box{ width: 100%; float: left; background: #fffbf7; padding: 18px; box-sizing: border-box; border-radius: 0 0 4px 4px; border: 2px solid #ebe1d9; border-top: 0; margin-bottom: 20px!important; padding-bottom: 0;}
.xgyp_box li{ width: 178px; float: left; margin-right: 13px; margin-bottom: 13px; position: relative; overflow: hidden;}
.xgyp_box li a:first-child{ width: 178px; height: 102px; float: left; overflow: hidden; overflow: hidden;}
.xgyp_box li:hover img{ transform: scale(1.1);}
.xgyp_box li a img{ width: 178px; height: 102px; border-radius: 2px; transition: 0.3s;}
.xgyp_box li h2{ font-size: 13px; font-weight: 100; font-family: "微软雅黑"; color: #726256; font-weight: 400; font-size: 13px; width: 100%; line-height: 30px; float: left; text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.xgyp_box li h2:hover{color: #99533e !important;}
.xgyp_box li:nth-child(4n){ margin-right: 0;}
.jb_r{ position: absolute; right: -1px; top: -1px;}
.zstp_box{ width: 375px; height: 365px; float: left; background: #f7f7f7;}
/*期刊详情*/
.qk_leftbox{ width: 323px; height: 323px; float: left; box-sizing: border-box; background: #fffbf7; border-radius: 4px;}
.qk_leftboxc{ width: 309px; height: 309px; line-height: 309px; text-align: center; margin: 7px; background: #f4f4f4; float: left; position: relative;}
.qk_leftboxc img{ max-width: 309px; max-height: 309px;}
.qk_rightbox{ float: right; width: 418px; height: 322px; background: #fffbf7; border-radius: 4px; padding: 15px; box-sizing: border-box; position: relative;}
.xgts_box_as{ width: 100%; float: left; font-size: 14px; font-family: "微软雅黑"; color: #4f4126; line-height: 24px;word-break: break-word;}
.xgts_box_as img{ max-width: 100%;}
.xgqk_list{ float: left; width: 346px; padding: 0px 16px;}
.xgqk_list ul li{ width: 100%; float: left; border-bottom: 1px solid #ebe1d9; margin-top: 18px; padding-bottom: 18px;}
.xgqk_list_left{ float: left; width: 101px; height: 138px; overflow: hidden;}
.xgqk_list_left img{ float: left; width: 101px; height: 138px; transition: 0.3s;}
.xgqk_list_left:hover img{ transform: scale(1.1);}
.xgqk_list_right h2{ width: 100%; float: left; font-size: 17px; font-family: "微软雅黑"; color: #4f4126; margin-bottom: 10px;}
.xgqk_list_right h2:hover{ color: #99533e;}
.xgqk_list_right{ float: right; width: 230px;}
.xgqk_list_right p{ width: 100%; float: left; margin-bottom: 5px; font-size: 13px; font-family: "微软雅黑"; color: #787878;}
.xgqk_list_right span{ float: right; width: calc( 100% - 45px ); color: #959595;}
.xgqk_list ul li:last-child{ border-bottom: 0;}
.qk_rightbox h2{ font-size: 16px; font-weight: 900; font-family: "微软雅黑"; color: #4f4126; line-height: 16px; margin-top: 3px; margin-bottom: 14px; width: 100%; float: left;}
.lin_height20p_t9{ line-height: 20px!important; padding-top: 9px;}
.qk_rightbox_b i{ width: 50%; float: left;}
.qk_rightbox_b i >p{ width: 58px; float: left; font-size: 13px; font-family: "微软雅黑"; color: #4f4126; line-height: 33px;}
.qk_rightbox_b i span{ float: left; width: calc( 100% - 58px ); font-size: 13px; font-family: "微软雅黑"; color: #676767; line-height: 33px;}
.qk_rightbox_b3 i >p{ line-height: 46px}
.qk_rightbox_b3 i span{ line-height: 46px}
.w_60{ width: 60%!important;}
.w_40{ width: 40%!important;}
.w_50{ width: 50%!important;}
.qk_rightbox_b img{ max-width: 100%;}
.qk_rightbox_b i span a{ color: #99533e; margin-right: 15px;}
.lin_height20p_t12{ line-height: 20px!important; padding-top: 12px;}
.lin_height20p_t14{ line-height: 20px!important; padding-top: 14px;}
.zk{ width: calc( 100% - 100px )!important; height: 42px; overflow: hidden;}
.zk_btn{ width: 12px; height: 12px; float: right; background: url(../images/zhankai_ico.png) no-repeat center; margin-top: 14px; cursor: pointer; display: none;}
.zd_btn{ background: url(../images/zhedie_ico.png) no-repeat center!important;}
.qk_leftboxc .sc_box{ position: absolute; right: 10px; bottom: 10px;}
.zhoukan{ width: 69px; height: 28px; background: url(../images/jiaobiao_right.png) no-repeat center; position: absolute; right: -7px; top: 18; font-size: 13px; font-family: "微软雅黑"; color: #fff; line-height: 22px; text-align: center;}
.qik_detailright_a{ position: absolute; right: 15px; bottom: 10px; width: 75px; height: 27px; border-radius: 3px; background: #af967f; float: right; text-align: center; line-height: 27px; color: #ffffff; margin-top: 15px;}
/*其他详情*/
.qtxq_box1{ width: 100%; float: left; background: #fffbf7; border-radius: 4px; padding: 20px; box-sizing: border-box; margin-bottom: 20px;}
.qtxq_box1t h2{ font-size: 18px; font-family: "微软雅黑"; color: #504027; width: 680px; float: left; margin-bottom: 15px; margin-top: -2px;}
.qtxq_box1t{ width: 100%; float: left; border-bottom: 1px solid #eaddd4;}
.qtxq_box1c{ width: 100%; float: left; height: 24px; background: #fef4ec; margin-top: 10px;}
.qtxq_box1c i{ float: left; font-size: 13px; font-family: "微软雅黑"; color: #787878; line-height: 22px;}
.qtxq_box1c i p{ float: left;}
.qtxq_box1c i span{ float: left;}
.qtxq_box1c i span a{ color: #99533e;}
.qtxq_box1c i img{ float: left; margin-left: 6px; margin-top: 5px; margin-right: 4px;}
.new_content_box{ width: 100%; float: left; margin-top: 15px; line-height: 23px; font-size: 13px; font-family: "微软雅黑"; color: #676767;word-break: break-word;}
.new_content_box img{ max-width: 100%; margin-top: 15px; margin-bottom: 15px;}
.qtxq_box1b{ width: 100%; float: left; height: 24px; background: #fef4ec; margin-top: 10px;}
.qtxq_box1b i{ float: left; font-size: 13px; font-family: "微软雅黑"; color: #787878; line-height: 22px;}
.qtxq_box1b i p{ float: left;}
.qtxq_box1b i span{ float: left;}
.qtxq_box1b i img{ float: left; margin-left: 6px; margin-top: 5px; margin-right: 4px;}
.qtxq_box1b i span a{ color: #99533e; margin-right: 15px;}
.xglw_box{ width: 100%; float: left; background: #fffbf7; padding: 18px; box-sizing: border-box; border-radius: 0 0 4px 4px; border: 2px solid #ebe1d9; border-top: 0; margin-bottom: 20px;}
.xglw_box ul li{ width: 49%; float: left; margin-right: 2%; list-style: inside; margin-bottom: 15px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.xglw_box ul li:nth-child(2n){ margin-right: 0;}
.xglw_box ul li a{ font-family: "微软雅黑"; color: #726256; font-size: 15px; line-height: 20px; position: relative; left: 0; top: 2px;}
.xglw_box ul li a:hover{color: #99533e !important;}
/*图书详情*/
.bottom_con a { width: 111px; margin-left: 8px; float: right; height: 27px; background: #af967f; border-radius: 2px; text-align: center; line-height: 27px; color: #fff; font-size: 13px; font-family: "微软雅黑";}
.bottom_con span{ float: left; font-size: 15px; font-family: "微软雅黑"; color: #cc1616; line-height: 27px; margin-left: 10px;}
.bottom_con font{ float: left; font-size: 15px; font-family: "微软雅黑"; color: #c5c5c5; line-height: 27px;text-decoration: line-through;}
.bottom_con{ width: 100%; float: left; margin-top: 25px;}
.btn_2{ border: 1px solid #af967f; box-sizing: border-box; background: #fffbf7!important; color: #af967f!important; line-height: 25px!important;}
.bottom_con a:hover{ opacity: 0.9;}
.qk_rightbox_b3 h5{ font-size: 14px; font-family: "微软雅黑"; color: #99533e; width: 100%; float: left; margin-top: -15px;}
.xgts_list{float: left; border-bottom: 1px solid #ebe1d9;}
.xgts_list { float: left; width: 346px; padding: 0px 16px;}
.xgts_list ul li{ width: 100%; float: left; border-bottom: 1px solid #ebe1d9; margin-top: 18px; padding-bottom: 18px;}
.xgts_list ul li:last-child { border-bottom: 0;}
.xgts_list_l { float: left; width: 101px; height: 101px; text-align: center; line-height: 96px; background: #f8ece3; margin-right: 7px; overflow: hidden;}
.xgts_list_l img{ max-height: 101px; max-width: 101px; transition: 0.3s;}
.xgts_list_l:hover img{ transform: scale(1.1);}
.xgts_list_r{ width: 233px; float: right;}
.xgts_list_r h2 { width: 100%; float: left; font-size: 17px; font-family: "微软雅黑"; color: #4f4126; margin-bottom: 10px; margin-top: 3px;}
.xgts_list_r h2:hover { color: #99533e;}
.xgts_list_r span{ width: 100%; float: left; font-size: 14px; font-family: "微软雅黑"; color: #787878; margin-bottom: 15px; margin-top: 5px;}
.xgts_list_r i{ width: 100%; float: left; font-size: 13px; font-family: "微软雅黑"; color: #777777;}
.xgts_list_r i p{ float: left; margin-right: 10px;text-decoration: line-through;}
.xgts_list_r i font{ float: left; color: #cc1616;}
.alreadycollection{background: url(../images/star_ico2.png) no-repeat 6px 6px #fffbf7!important; color: #99533e;}
/*石窟详情*/
.yjcg_box13 {
    width: 100%;
    float: left;
    background: #eaddd4;
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.yjcg_box13l{ width: 391px; height: 391px; float: left; position: relative;}
/*.yjcg_box13l img{ max-width: 391px; max-height: 391px;}*/
.yjcg_box13l img{ width: 391px; height: 391px; border-radius: 4px;}
.yjcg_box13r{ float: right; width: 350px; height: 391px; border-radius: 4px; background: #fffbf7; padding:0 20px; box-sizing: border-box;}
.yjcg_box13r ul li{ width: 100%; float: left; height: 55px; border-bottom: 1px solid #eaddd4; font-size: 16px; font-family: "微软雅黑"; color: #4f4126; line-height: 55px;}
.yjcg_box13r ul li p{ float: left; width: 75px;}
.yjcg_box13r ul li span{ color: #676767;}
.yjcg_box13r ul li span a{ color: #99533e;}
.yjcg_box13l .sc_box{ position: absolute; right: 10px; bottom: 10px;}
.yjcg_box13cb{ width: 100%; float: left;}
.yjcg_box13cbb{ width: 100%; padding: 0 26px 0 34px; box-sizing: border-box; float: left; font-size: 14px; font-family: "微软雅黑"; color: #3a2b1f; line-height: 24px;word-break: break-word;}
.xg_more{float: right;margin:15px 24px 15px 0px;}
.xg_more span{float: left;height: 13px;line-height: 13px;font-family: "微软雅黑";font-size: 13px;color: #8a776a;margin-right: 5px;}
.xg_more em{float: left;width: 12px;height: 12px;line-height: 12px;background-color: #8e7b6f;color: #eaddd4;font-family: "微软雅黑";font-size: 13px;border-radius: 6px;text-align: center;font-style: normal;}
.yjcg_box13cbbq{ width: 100%; float: left; font-size: 16px; font-family: "微软雅黑"; color: #99533e;}
.yjcg_box13cbbq a{ color: #99533e;}
/*购买电子书-弹窗*/
.pro_box{ width: 396px; height: 312px; margin: 0 auto; margin-top: 16px;}
.pro_box_t{ width: 100%; float: left; border-bottom: 1px solid #f4ebe4; padding-bottom: 13px;}
.pro_box_tl{ width: 91px; height: 91px; float: left;line-height: 91px;text-align: center;background-color: #f4f4f4;}
.pro_box_tl img{max-width: 91px; max-height: 91px;}
.pro_box_tltp img{float: left;width: 91px; height: 91px;}
.pro_box_tr{ float: right; width: 300px;}
.pro_box_tr h2{ font-weight: 400; margin-bottom: 8px; font-size: 14px; font-family: "微软雅黑"; color: #2a1708; width: 100%; float: left; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.pro_box_tr h3{float: left;width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; font-size: 14px; font-weight: 400; font-family: "微软雅黑"; color: #7e7e7e; margin-bottom: 5px;}
.pro_box_tr span{ font-size: 14px; font-family: "微软雅黑"; color: #4f4126; line-height: 22px;}
.pro_box_b h3{ font-size: 13px; font-family: "微软雅黑"; color: #2a1708; float: left; width: 100%; line-height: 13px; margin-top: 12px; margin-bottom: 4px;}
.pro_box_ul ul li{ width: 50%; float: left;}
.pro_box_ul ul li label{ cursor: pointer;}
.pro_box_ul ul li input{ float: left; margin-top: 29px;}
.pro_box_ul ul li img{ float: left;}
.pro_box_ul{ width: 100%; float: left; border-bottom: 1px solid #f4ebe4; padding-bottom: 7px;}
.pro_box_bot{  display: table; margin: 0 auto; margin-top: 15px; margin-bottom: 11px;}
.pro_box_bot span{ font-size: 20px; font-family: "微软雅黑"; color: #c5c5c5; font-weight: 100; text-decoration: line-through; margin-right: 7px;}
.pro_box_bot font{ font-size: 30px; font-family: "微软雅黑"; color: #f82727;}
.btn_box2 a{ width: 245px; height: 27px; display: inline-block; border-radius: 3px; background: #736357; font-size: 14px; font-family: "微软雅黑"; color: #fff; line-height: 27px;}
.btn_box2{ width: 100%; float: left; text-align: center; margin-bottom: 20px;}
/*研究成果分类特色数据库*/
.banner2{ width: 100%; float: left; position: relative; top: 130px; left: 0;  height: 617px; padding: 19px 0; box-sizing: border-box; background: url(../images/banner_bg.png) no-repeat top center;}
.banner2>img{ width: 100%; float: left; height: 579px;}
.banner2_c{ width: 775px; height: 86px; margin: 0 auto; position: relative; top: 247px; z-index: 80;}
.banner2_c1{ position: absolute; left: 0; top: 0; height: 53px; width: 100%;}
.banner2_c1>a{ width: 108px; height: 53px; border: 1px solid #b9a28d; background: #f8eee5; float: right; font-family:"黑体"; color: #9f7855; font-weight: 400; line-height: 51px; text-align: center; font-size: 17px; box-sizing: border-box;}
.banner2_cl{ width: 647px; height: 53px; float: left;}
.banner2_cl select{ float: left;}
.layui-form-select dl{ z-index: 1000000!important;}
.banner2_cl .layui-input-block{ margin-left: 0!important; width: 109px; height: 53px; float: left;}
.banner2_cl .layui-form{ width: 109px; height: 53px; float: left;}
.banner2_cl .el-select{ width: 109px !important; height: 53px !important; float: left;}
.banner2_cl .el-input__inner{height: 53px !important; line-height: 53px !important; border: none !important; border-radius: 4px 0 0 4px !important;}
.banner2_cl>input{ width: 449px; height: 53px; outline: none; box-sizing: border-box; border: none; padding-left: 8px; border-left: 2px solid #d3d3d3;}
.banner2_cl .layui-form-select .layui-input{ height: 53px!important; font-size: 17px; color: #af967f; border: 0;border-radius: 4px 0 0 4px;}
.banner2_cl .layui-form-select dl{ top: 52px!important;}
.banner2_cl .layui-form-select .layui-edge{border-top-color: #af967f!important;}
.banner2_cl>a{ float: right; width: 89px; height: 53px; background: #af967f; border-radius: 0 4px 4px 0; font-size: 17px; font-family: "黑体"; color: #ffffff; text-align: center; line-height: 53px;}
.banner2_c2{ position: absolute; left: 0; top: 68px;}
.banner2_c2 i{ font-size: 17px; font-family: "微软雅黑"; color: #ffffff; float: left;}
.banner2_c2 a{ font-size: 17px; font-family: "微软雅黑"; color: #ffffff; float: left; margin-left: 15px;}
.yjcgsy_box{ width: 100%; float: left; margin-top: 130px;}
.yjcgsy_box1{ width: 100%; float: left; min-height: 450px; background: url(../images/index_back.png) no-repeat center #f2ede9;}
.yjcgsy_box1c{ width: 1200px; margin: 0 auto;}
.yjcgsy_box1cbox{ width: 100%; float: left;}
.yjcgsy_box1cbox ul li{ width: 50%; float: left; height: 200px; margin-bottom: 30px;}
.yjcgsy_left{ width: 200px; height: 200px; float: left; background: #f4f4f4; line-height: 195px; text-align: center; margin-right: 12px; overflow: hidden;}
.yjcgsy_left img{ max-width: 100%; max-height: 100%; transition: 0.3s;}
.yjcgsy_left:hover img{ transform: scale(1.1);}
.yjcgsy_right{ width: calc( 100% - 212px ); float: left; height: 200px;}
.yjcgsy_right h2{ font-size: 19px; font-family: "微软雅黑"; color: #4f4126; line-height: 43px; margin-bottom: 10px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.yjcgsy_right h2:hover{color: #99533e !important;}
.yjcgsy_right h3{ font-size: 16px; font-family: "微软雅黑"; color: #777777; line-height: 16px; margin-bottom: 19px; font-weight: 400;}
.yjcgsy_right h4{ width: 100%; float: left; font-weight: 100; margin-bottom: 20px;}
.yjcgsy_right h4 span{ font-family: "微软雅黑"; color: #777777; font-size: 16px; line-height: 16px; text-decoration: line-through;}
.yjcgsy_right h4 font{ font-family: "微软雅黑"; color: #cc1616; font-size: 16px; line-height: 16px; margin-left: 4px;}
.yjcgsy_right p{ font-size: 14px; font-family: "微软雅黑"; color: #7a6654; line-height: 27px;}
.yjcgsy_box1cboxqk ul li{width: 33.33%;height: 147px;}
.yjcgsy_box1cboxqk .yjcgsy_left{width: 147px;height: 147px;line-height: 147px;}
.yjcgsy_box1cboxqk .yjcgsy_right{width: calc( 100% - 160px );height: 147px;}
.yjcgsy_box1cboxqk .yjcgsy_right h2{line-height: 25px;margin-bottom: 21px;margin-top: -4px}
.yjcgsy_box1cboxqk .yjcgsy_right p{line-height: 20px;}
.yjcgsy_box1cboxqk .yjcgsy_right h3{margin-bottom: 18px;}
.yjcgsy_box2{ width: 100%; float: left; background: #dbcfc6;min-height: 310px;}
.yjcgsy_box2_c{ width: 1200px; margin: 0 auto; overflow: hidden;}
.yjcgsy_box2 .yjcgsy_left{ background: #e8ddd5;}
.yjcgsy_right h3 i{ width: calc( 100% - 50px ); float: right;}
.yjcgsy_box3{ width: 100%; float: left; min-height: 450px; background: url(../images/index_back.png) no-repeat center #fff;}
.yjcgsy_box3_c{ width: 1200px; margin: 0 auto;}
.yjcgsy_box3_cl{ width: 680px; float: left;}
.yjcgsy_box3_cr{ width: 485px; float: right;}
.lw_boxlist{ overflow: hidden; box-sizing: border-box; width: 100%; height: 396px; float: left; background: #f8eee5; border-top: 5px solid #af967f; border-radius: 4px; overflow: hidden; margin-bottom: 30px; padding: 5px 15px 10px 15px;}
.lw_boxlist ul li{ width: 49%; margin-right: 2%; float: left; list-style: inside; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; line-height: 47px; }
.lw_boxlist ul li:nth-child(2n){ margin-right: 0;}
.lw_boxlist ul li a{font-family: "微软雅黑"; color: #726256; font-size: 15px; line-height: 17px; position: relative; left: 0; top: 2px;}  
.lw_boxlist ul li a:hover{color: #99533e !important;}
.qt_boxlist{ width: 100%; height: 396px; float: left; background: #f8eee5; border-radius: 3px; padding: 25px; box-sizing: border-box;margin-bottom: 30px;}
.qt_boxlist ul li{ width: 100%; float: left; height: 70px; border-bottom: 1px solid #af967f; margin-bottom: 20px;}
.qt_boxlist ul li h2{ font-size: 18px; font-family: "微软雅黑"; color: #504027; line-height: 19px; width: 100%; float: left; margin-bottom: 19px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.qt_boxlist ul li h2:hover{color: #99533e !important;}
.qt_boxlist ul li p{ font-size: 15px; font-family: "微软雅黑"; color: #7a6654; line-height: 15px; width: 100%; float: left; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.qt_boxlist ul li:last-child{ margin-bottom: 0;}
.banner2_cl .layui-form-select dl dd.layui-this{ background-color: #af967f;}
.b1_qk{ height: 530px; background: url(../images/main_bg3.png) no-repeat bottom #f9f6f4;}
/*遗址详情*/
.yzxq_box1{ width: 100%; float: left; background: #fffbf7; border-radius: 2px; margin-bottom: 20px;}
.yzxq_box1 .new_content_box{ border-bottom:1px solid #cfcac6;}
.related_sites{ width: 100%; float: left;}
.related_sites li{ width: 282px; height: 101px; float: left; margin-right: 12px;}
.related_sites li:last-child{ margin-right: 0;}
.related_sitesl{ width: 101px; height: 101px; float: left; line-height: 90px;}
.related_sitesl img{ max-width: 100%; max-height: 100%;}
/*文献详情*/
.document_details{ width: 515px; margin: 0 auto; height: 390px;}
.document_detailsc{ background: url(../images/wenxian_back.png) no-repeat center 100px #eaddd4;}
.document_detailsl{ width: 390px; height: 390px; float: left; background: #fffbf7; border-radius: 5px; padding: 7px; box-sizing: border-box; box-shadow: 0px 0px 10px #e5d1c7;}
.document_detailslc{ width: 375px; height: 375px; margin: 0 auto; background: #ffffff; text-align: center; line-height: 370px; position: relative;}
.document_detailslc img{ max-width: 100%; max-height: 100%;}
.document_detailslc span{ width: 100%; height: 28px; background: #bcafa3; position: absolute; bottom: 0; left: 0; font-size: 14px; font-family: "微软雅黑"; color: #ffffff; line-height: 28px;}
.document_detailsr{ width: 113px; float: right; height: 390px; background: url(../images/wenxian_back2.png); border-radius: 4px; overflow: hidden;}
.ddetailsr_t{ width: 100%; float: left; height: 23px; background: #c1a68b; cursor: pointer;}
.ddetailsr_t img{ display: table; margin: 0 auto; margin-top: 6px;}
.ddetailsr_b{ width: 100%; float: left; height: 23px; background: #c1a68b; cursor: pointer;}
.ddetailsr_b img{ display: table; margin: 0 auto; margin-top: 6px;}
.ddetailsr_c{ width: 100%; float: left; height: 344px; padding-top: 17px; box-sizing: border-box; overflow: hidden;}
.ddetailsr_c ul li{ width: 100%; height: 109px; float: left;}
.ddetailsr_ct{ width: 84px; height: 84px; margin: 0 auto; background: #fff; border: 1px solid #f7f3ef; line-height: 79px; text-align: center; box-sizing: border-box; cursor: pointer;}
.ddetailsr_ct img{ max-width: 100%; max-height: 100%;}
.ddetailsr_cb{ width: 100%; float: left; line-height: 25px; text-align: center; font-size: 12px; font-family: "微软雅黑"; color: #977452;}
.ddetailsr_cur .ddetailsr_ct{ border: 2px solid #99533e;}
.document_detailslc .sc_box{ position: absolute; bottom: 40px; right: 12px;}
.shikprop_center ul li{ width: 63px; height: 29px; background: #f6f6f6; border: 1px solid #b08875; font-size: 14px; font-family: "微软雅黑"; color: #b08875; border-radius: 2px; line-height: 27px; cursor: pointer; text-align: center; float: left; box-sizing: border-box; margin-right: 20px;}
.shikprop_center ul li:last-child{ margin-right: 0;}
.shikprop_center{ width: 100%; float: left; padding-bottom: 30px; border-bottom: 1px solid #f4ebe4; margin-top: 13px;}
.shikprop_center2{ font-size: 13px; font-family: "微软雅黑"; color: #2a1708; line-height: 23px; width: 100%; float: left; height: 180px; overflow: auto; margin-top: 15px; margin-bottom: 25px;}
.shikprop_center2::-webkit-scrollbar {/*滚动条整体样式*/
        width: 10px;     /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    }
.shikprop_center2::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
        border-radius: 10px;
         -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: #535353;
    }
.shikprop_center2::-webkit-scrollbar-track {/*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 10px;
        background: #EDEDED;
    }

.shikprop_center li.cur{ background: #b08875; color: #fff;}
.shikprop_center li:hover{ background: #b08875; color: #fff;}
.document_detailsr2{ width: 100%; height: 50px; border-top: 1px solid #e3cdbe; border-bottom: 1px solid #e3cdbe; float: left; margin-top: 20px;}
.document_detailsr2 i{ float: left; font-size: 16px; font-family: "微软雅黑"; color: #4f4126; margin-top: 13px; height: 25px; line-height: 25px; width: 50%; padding-left: 13px; box-sizing: border-box; border-left: 1px solid #e3cdbe;}
.document_detailsr2 i:first-child{ border-left: 0;}
.document_detailsr2 i span{ color: #676767;}
.document_detailsr2 i span a{ color: #99533e;}
.document_detailsr3{ width: 100%; float: left;}
.document_detailsr3 .yjcg_box13cb{ border-bottom: 1px solid #cfcac6; padding-bottom: 10px;}
.character_list_box ul li{ width: 100%; float: left; height: 85px; border-bottom: 1px solid #e9e0d9;}
.character_list_box{ width: 760px; margin: 0 auto;}
.character_list_box ul li h2{ font-size: 15px; font-family: "微软雅黑"; color: #4f4126; line-height: 45px; margin-top: 5px;}
.character_list_box ul li h2:hover{color: #99533e !important;}
.character_list_box ul li p{ font-size: 13px; font-family: "微软雅黑"; color: #777475; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.rwleft_list{ float: left; width: 100%; padding: 0px 16px; box-sizing: border-box;}
.rwleft_list ul li{ width: 100%; float: left; height: 100px; border-bottom: 1px solid #ebe1d9; box-sizing: border-box;}
.rwleft_list ul li h2{ font-size: 15px; font-family: "微软雅黑"; color: #4f4126; line-height: 35px; margin-top: 10px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.rwleft_list ul li h2:hover{color: #99533e !important;}
.rwleft_list ul li p{ font-size: 13px; font-family: "微软雅黑"; color: #b19982; line-height: 23px;word-break: break-word;}
.rwleft_list ul li:last-child{ border-bottom: 0;}
/*人物详情*/
.rwxq_box1{ width: 100%; float: left; background: #fffbf7; border-radius: 4px; padding: 20px; box-sizing: border-box; margin-bottom: 20px;}
.rwxq_box11{ width: 100%; float: left; border-bottom: 1px solid #eaddd4; padding-bottom: 17px;}
.rwxq_box11 h2{ font-size: 18px; font-family: "微软雅黑"; color: #504027; float: left;}
.rwxq_box11 i{ float: left; font-size: 13px; font-family: "微软雅黑"; color: #676767; margin-left: 8px; margin-top: 7px;}
.rwxq_box12{ width: 751px; float: left; margin-top: 10px; background: #fdf4ec; padding-top: 10px; padding-bottom: 10px; padding-left: 16px; padding-right: 16px; box-sizing: border-box;}
.rwxq_box12 i{ width: 100%; float: left; font-size: 13px; font-family: "微软雅黑"; color: #4f4126; line-height: 30px;}
.rwxq_box12 i>span{ width: 60px; float: left;}
.rwxq_box12 i font{ width: calc( 100% - 60px ); float: left; color: #676767;word-break: break-word;}
.rwxq_box12 i font a{ color: #99533e;}
.rwxq_box122 i>span{width: 90px;}
.rwxq_box122 i font{ width: calc( 100% - 90px );}
.rwxq_box13{ font-size: 13px; font-family: "微软雅黑"; color: #676767; line-height: 23px; width: 100%; float: left; margin-top: 10px;word-break: break-word;}
/*.rwxq_box13 img{ width: 16px; height: 16px;}*/
.color_fff{ color: #fff!important;}
.color_fff:hover{ color: #99533e!important;}
/*高级搜索*/
.consousuo{ width: 100%; float: left; border-radius: 4px; overflow: hidden; border: 2px solid #ebe1d9; margin-bottom: 30px;}
.consousuo2{ width: 100%; float: left; height: 450px; background: #fffbf7;}
.consousuo2c{ width: 970px; margin: 0 auto; height: 300px; margin-top: 30px;}
.consousuo2c ul li{ width: 100%; float: left; margin-bottom: 15px; }
.consousuo2c ul li p{ float: left; font-size: 13px; font-family: "微软雅黑"; color: #af967f; width: 50px;}
.consousuo2c ul li label{ float: left; font-size: 13px; font-family: "微软雅黑"; color: #7f7058; cursor: pointer; margin-right: 30px; margin-bottom: 10px;}
.consousuo2c ul li label input{ float: left; margin: 3px;}
.consousuo2cr{ width: 920px; float: right;}
.consousuo2cr2{ width: 920px; float: right;}
.search_type>i{ width: 100%; float: left;}
.sel1{ width: 70px; float: left; margin-right: 15px;}
.sel1 .layui-input-block{ margin-left: 0; min-height: auto; height: 31px; margin-top: -5px;}
.sel1 .layui-input{ height: 31px; border: 1px solid #e2e2e2; background: #fbfbfb;}
.sel1>a{ float: right;}
.sel1>a:first-child{ float: left;}
.sel2{ width: 77px; float: left;}
.sel2 .layui-input-block{ margin-left: 0; min-height: auto; height: 31px; margin-top: -5px;}
.sel2 .layui-input{ height: 31px; border: 1px solid #e2e2e2; background: #fbfbfb;}
.search_type>i>input{ width: 659px; outline: none; height: 31px; float: left; border: 1px solid #e2e2e2; background: #fbfbfb; margin-top: -5px; margin-left: 10px; border-radius: 3px; padding-left: 8px; box-sizing: border-box;}
.sel3{ width: 77px; height: 31px; float: right;}
.sel3 .layui-input-block{ margin-left: 0; min-height: auto; height: 31px; margin-top: -5px;}
.sel3 .layui-input{ height: 31px; border: 1px solid #e2e2e2; background: #fbfbfb;}
.consousuo3cr{ width: 920px; float: right; margin-top: -5px;}
.consousuo3cr .conso_il{ float: left; position: relative;}
.consousuo3cr .conso_il input{ float: left; width: 157px; height: 31px; background: #fbfbfb; border: 1px solid #e2e2e2; border-radius: 3px; padding-left: 8px; box-sizing: border-box;}
.consousuo3cr .conso_il img{ position: absolute; right: 10px; top: 7px;}
.consousuo3cr span{ float: left; width: 28px; height: 31px; line-height: 31px; text-align: center; font-size: 13px; font-family: "微软雅黑"; color: #af967f;}
.consousuo3{ width: 100%; float: left;}
.consousuo3 a{ width: 137px; height: 31px; background: #af967f; text-align: center; line-height: 31px; display: table; margin: 0 auto; border-radius: 3px; font-size: 15px; font-family: "微软雅黑"; color: #fff;}
.layui-form-select dl dd.layui-this{ background: #af967f!important;}

.rmsp_list .rmtj_listimg{ width: 179px;}
.rmsp_list .rmtj_listimg img{ width: 179px; float: left; max-width: 179px;}
.rmsp_list .rmtj_listcontent{ width: 155px!important;}
.search_type{ float: left; width: calc( 100% - 85px );}
.consousuo2cr2 .sel1{ height: 31px;}
.consousuo2cr2 .sel2,.sel3{ margin-top: -5px ;}
.consousuo2cr2 .el-input__inner{ height: 31px !important; line-height: 31px !important; outline: none !important; padding: 0 10px !important;}
.search_type>i{ margin-bottom: 15px;}
.search_type>i:last-child{ margin-bottom: 0;}
.consousuo2cr2 .el-input__icon{ line-height: 31px !important;}
.consousuo3cr_c{ float: left; width: 330px;}
.consousuo3cr_c .el-input__inner{ height: 31px !important; line-height: 31px !important;}
.consousuo3cr_c .el-date-editor .el-range__icon{ line-height: 25px !important;}
.consousuo3cr_c .el-date-editor .el-range-separator{ line-height: 25px !important;}
.cell{ background: #fff !important;}
.el-month-table td.end-date .cell, .el-month-table td.start-date .cell{ background-color: #af967f !important;}
.el-month-table td.today .cell{ color: #af967f !important;}
.el-month-table td .cell:hover{ color: #af967f !important;}














/*研究成果分类特色数据库-图书列表*/
.bookList_rmtj{float: left;width: 378px;margin-bottom: 25px;}
.rmtj_list{float: left;width: 100%;padding: 0px 16px;box-sizing: border-box;}
.rmtj_list ul{float: left;width: 100%;}
.rmtj_list ul li{float: left;width: 100%;height: 138px;border-bottom: 1px solid #ebe1d9;box-sizing: border-box;padding: 18px 0px;}
.rmtj_list ul li:last-child{border-bottom: none;}
.rmtj_listimg{float: left;width: 101px;height: 101px;line-height: 101px;margin-right: 7px;background-color: #f8ece3;text-align: center; overflow: hidden;}
.rmtj_listimg:hover img{ transform: scale(1.1);}
.rmtj_listimg img{max-width: 101px;max-height: 101px; transition: 0.3s;}
.rmtj_listcontent{float: left;width: 234px;}
.rmtj_listcontent h3{float: left;width: 100%;font-weight: 100;font-family: "微软雅黑";font-size: 17px;color: #4f4126;height: 35px;line-height: 35px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.rmtj_listcontent h3:hover{color: #99533e !important;}
.rmtj_listcontent span{float: left;width: 100%;line-height: 30px;height: 30px;font-family: "微软雅黑";font-size: 14px;color: #787878;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.rmtj_listcontent>p{float: left;width: 100%;line-height: 20px;font-family: "微软雅黑";font-size: 14px;color: #b19982;}
.rmtj_listcontent_price{float: left;width: 100%;margin-bottom: 10px;}
.rmtj_listcontent_price font{float: left;height: 28px;line-height: 28px;font-family: "微软雅黑";font-size: 13px;color: #cc1616;}
.old_price{text-decoration: line-through;margin-right: 6px;color: #777777 !important;}
.booklist_box{float: right;width: 793px;background-color: #fffbf7;border: 2px solid #ebe1d9;margin-bottom: 25px;border-radius: 4px;box-sizing: border-box;}
.booklist_box1{float: left;width: 100%;box-sizing: border-box;padding: 5px 16px 0px;}
.booklist_box1 >ul{float: left;width: 100%;}
.booklist_box1 >ul >li{width: 100%;height: 176px;border-bottom: 1px solid #e9e0d9;padding: 14px 0px;box-sizing: border-box;}
.booklist_img{float: left;width: 147px;height: 147px;line-height: 147px;border-radius: 4px;box-sizing: border-box;background-color: #f4f4f4;margin-right: 10px;text-align: center; overflow: hidden;}
.booklist_img img{max-width: 147px;max-height: 147px; transition: 0.3s;}
.booklist_img:hover img{ transform: scale(1.1);}
.booklist_content{float: left;width: 600px;}
.booklist_content h3{float: left;width: 100%;height: 28px;line-height: 28px;font-family: "微软雅黑";font-size: 17px;color: #4f4126;font-weight: 100;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin-bottom: 12px;}
.booklist_content h3:hover{color: #99533e !important;}
.booklist_content_box1{float: left;width: 100%;}
.booklist_content_box11{float: left;margin-right: 35px;height: 28px;}
.booklist_content_box11 em{float: left;width: 13px;height: 12px;margin-right: 5px;margin-top: 8px;}
.booklist_content_box11 span{float: left;font-family: "微软雅黑";font-size: 13px;color: #787878;height: 28px;line-height: 28px;}
.booklist_gjc{margin-bottom: 15px !important;}
.booklist_content p {float: left;width: 100%;height: 40px;line-height: 20px;font-family: "微软雅黑";font-size: 13px;color: #b19982;text-overflow: ellipsis;overflow: hidden;}

/*研究成果分类特色数据库-图书列表*/
.lw_list{margin-bottom: 0px;}
.lw_list >ul>li h4{line-height: 16px !important;}
.lw_zd{background: url(../images/lwd_ico.png) no-repeat;width: 18px;height: 18px;float: left;margin-right: 6px;}
.lw_list >ul >li:last-child{border-bottom: 1px solid #e9e0d9;}

/*研究成果分类特色数据库-期刊列表*/
.rmtj_listcontent_box{float: left;width: 100%;}
.rmtj_listcontent_box span{float: left;font-family: "微软雅黑";font-size: 12px !important;width: 46px;height: 18px;line-height: 18px;color: #787878;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.rmtj_listcontent_box p{float: left;height: 18px;line-height: 18px;font-family: "微软雅黑";font-size: 12px;color: #959595;max-width: calc( 100% - 46px );overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.qklist_box1{float: left;width: 100%;padding: 0px 16px;box-sizing: border-box;}
.qklist_box1 ul{float: left;width: 100%;}
.qklist_box1 ul li{float: left;width: 178px;height: 262px;margin-right: 15px;background-color: #eaddd4;border-radius: 4px;box-sizing: border-box;margin-top: 14px;padding: 8px;}
.qklist_box1 ul li:nth-child(4n){margin-right: 0px;}
.qk_listimg{float: left;width: 162px;height: 162px;line-height: 162px;text-align: center;background-color: #f4f4f4; overflow: hidden;}
.qk_listimg img{max-width: 162px;max-height: 162px; transition: 0.3s;}
.qk_listimg:hover img{ transform: scale(1.1);}
.qk_listcontent{float: left;width: 100%;}
.qk_listcontent h3{float: left;width: 100%;height: 32px;line-height: 32px;font-family: "微软雅黑";font-size: 15px;color: #4f4126;font-weight: 100;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.qk_listcontent h3:hover{color: #99533e !important;}

/*研究成果分类特色数据库-期刊列表2*/
.qik_list_left{ float: left; margin-left: 17px; width: 196px; height: 196px; line-height: 186px; text-align: center; padding: 5px; box-sizing: border-box; border-radius: 4px; background: #ffffff; margin-top: -46px;}
.qik_list_left img{ max-width: 186px; max-height: 186px;}
.h71{ height: 71px!important;}
.qik_list_b{ width: 100%; float: left; background: #fffbf7; min-height: 100px; padding-bottom: 20px; border-radius:0 0 6px 6px;}
.qik_list_br{ width: 960px; float: right;}
.qik_list_br ul li{ width: 50%; float: left; margin-top: 17px; font-size: 13px; font-family: "微软雅黑"; line-height: 13px;}
.qik_list_br ul li i{ float: left; color: #4f4126;width: 60px;}
.qik_list_br ul li p{ color: #676767; float: left;width: clac( 100% - 60px );}
.qik_list_br ul li p a{ color: #99533e; margin-right: 12px; float: left;}
/* .qik_list_br ul li:nth-child(2n-1) i{ width: 47px;}
.qik_list_br ul li:nth-child(2n-1) p{ width: clac( 100% - 47px );}
.qik_list_br ul li:nth-child(2n) i{ width: 60px;}
.qik_list_br ul li:nth-child(2n) p{ width: clac( 100% - 60px );} */
.pul_listsearch{ width: 240px; height: 30px; float: right; background: #fcfaf8; border-radius: 4px; margin-top: 7px; margin-right: 19px; overflow: hidden;}
.pul_listsearch input{ float: left; width: 205px; height:30px; box-sizing: border-box; padding-left: 12px; border: 0; background: none;}
.pul_listsearch a{ float: right; width: 34px; height: 30px; background: #b8a18d; text-align: center; line-height: 27px;}
.pul_listsearch a img{ float: none; margin-top: 0;}
.qik2_list{ width: 100%; float: left; background: #fffbf7; box-sizing: border-box; border-radius: 0 0 6px 6px; margin-bottom: 30px; padding: 20px; overflow: hidden;}
.qik2_list li{ width: 164px; height: 246px; float: left; background: #eaddd4; border-radius: 3px; overflow: hidden; margin-right: 35px; margin-bottom: 25px;}
.qik2_list li img{ width: 155px; height: 212px; float: left; margin-left: 4px; margin-top: 4px;}
.qik2_list li p{ font-size: 13px; font-family: "微软雅黑"; width: 100%; text-align: center; line-height: 28px; color: #726256;}
.qik2_list li:nth-child(6n){ margin-right: 0;}










/*研究成果分类特色数据库-其他列表*/
.qtlist_content1{float: left;width: 100%;height: 20px;margin-bottom: 12px;}
.qtlist_gjc{width: auto;float: none;margin-top: 5px;}
.other_list{margin-bottom: 0px;}
.other_list >ul>li:last-child{border-bottom: 1px solid #e9e0d9;}

/*索引目录数据库-文献列表*/
.rmtj_listcontent >font{float: left;width: 100%;height: 54px;line-height: 18px;font-family: "微软雅黑";font-size: 13px;color: #b19982;overflow: hidden;text-overflow: ellipsis;margin-top: 15px;}
.wx_listimg img{float: left;width: 101px;height: 101px;}
.wx_listcontent{margin-bottom: 0px;}
.wx_listcontent ul li{margin-top: 14px; margin-left: 15px;}

/*索引目录数据库-石窟列表*/
.xgwx_listcontent font{height: 32px;margin-top: 0px;line-height: 16px;}
.sk_list_box >ul{padding: 0px;}
.sk_list_box{margin-bottom: 0px;padding: 0px;border: none;}
.sk_list_box >ul >li{margin: 14px 15px 15px;border-radius: 2px;overflow: hidden;}
.sk_list_box >ul >li:nth-child(2n){margin-right: 0px;}


/*索引目录数据库-文献列表*/
.wx_listbox{margin-bottom: 0px;padding: 0px 16px;box-sizing: border-box;}
.wx_listbox table{width: 100%;margin-top: 12px;}
.wx_boxr1list span{float: left;width: 62px;height: 54px;line-height: 54px;font-family: "微软雅黑";font-size: 14px;color: #4f4126;margin-right: 8px;}
.wx_boxr1list h3{width: 272px !important;}

/*索引目录数据库-书目列表*/
.sm_searchbox{float: left;width: 100%;margin-top: 12px;padding: 0px 16px;box-sizing: border-box;}
.sm_searchboxsm{float: left;margin-right: 15px;}
.sm_searchboxsm span{float: left;max-width:45px;height: 31px;line-height: 31px;font-family: "微软雅黑";font-size: 15px;color: #af967f;margin-right: 8px}
.sm_searchboxsm input{float: left;width: 195px;height: 31px;line-height: 31px;border: 1px solid #e2e2e2;box-sizing: border-box;background-color: #fbfbfb;border-radius: 4px;outline: none;padding-left: 8px;font-family: "微软雅黑";font-size: 13px;color: #454545;}
.sm_searchboxzz input{width: 117px;}
.sm_searchboxcbs input{width: 226px;}
.sm_searchbox a{float: right;width: 45px;height: 31px;line-height: 31px;background-color: #af967f;border-radius: 3px;text-align: center;font-family: "微软雅黑";font-size: 14px;color: #fff;box-sizing: border-box;}

/*弹窗-购买论文*/
.pro_box_trlw{float: left;width: 100%;}
.pro_box_trlw h2{margin-bottom: 14px;}
.pro_box_trlw h3{margin-bottom: 9px;}
.pro_box_trlwdet{width: 100%;padding: 10px;background-color: #f4f4f4;box-sizing: border-box;float: left;}
.pro_box_trlwdet span{max-height: 271px;}


/*弹窗-购买音视频*/
.pro_box_tlysp{width: 138px;height: 79px;}
.pro_box_tlysp img{width: 138px;height: 79px;}
.pro_box_trysp{width: 250px;}
.pro_box_trysp h2{margin-bottom: 20px;}
/*建设中*/
.building{ width: 100%; float: left;}
.building img{ width: 100%; float: left;}

/*索引目录数据库*/
.symlsjk_sklist{float: left;width: 100%;margin-bottom: 20px}
.symlsjk_sklist ul{float: left;width: 100%;}
.symlsjk_sklist ul li{float: left;width: 564px;height: 207px;margin-right: 64px;margin-bottom: 30px;}
.symlsjk_sklist ul li:nth-child(2n){margin-right: 0px;}
.symlsjk_sklistf{float: left;width: 207px;height: 207px;margin-right: 26px;overflow: hidden;}
.symlsjk_sklistf img{float: left;width: 100%;height: 100%;border-radius: 3px;box-sizing: border-box; transition: 0.3s;}
.symlsjk_sklistf:hover img{ transform: scale(1.1);}
.symlsjk_sklistr{float: left;width: 327px;height: 100%;}
.symlsjk_sklistr h2{float: left;width: 100%;height: 50px;line-height: 50px;font-family: "微软雅黑";font-size: 19px;color: #504027;    overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.symlsjk_sklistr h2:hover{color: #99533e !important;}
.symlsjk_sklistr span{float: left;width: 100%;height: 30px;line-height: 30px;font-family: "微软雅黑";font-size: 16px;color: #777777;margin-bottom: 20px;    overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.symlsjk_sklistr p{float: left;width: 100%;line-height: 27px;color: #7a6654;font-family: "微软雅黑";font-size: 14px;overflow: hidden;text-overflow: ellipsis;max-height: 81px;}
.sywx_boxf{float: left;width: 347px;margin-right: 40px;}
.sywx_boxf_list{float: left;width: 100%;}
.sywx_boxf_list ul {float: left;width: 100%;}
.sywx_boxf_list ul li {float: left;width: 100%;margin-bottom: 45px;height: 14px;line-height: 14px;}
.sywx_boxf_list ul li em {float: left;width: 5px;height: 5px;border-radius: 2px;display: block;background: #504027;margin-right: 11px;margin-top: 4.5px;}
.sywx_boxf_list ul li font {float: left;margin-right: 14px;font-family: "微软雅黑";font-size: 15px;color: #504027;max-width: 57px; height: 14px;line-height: 14px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.sywx_boxf_list ul li h3 {float: left;font-weight: 100;font-family: "微软雅黑";font-size: 15px;color: #504027;max-width: calc( 100% - 100px );overflow: hidden;text-overflow: ellipsis;white-space: nowrap;height: 14px;line-height: 14px;}
.sywx_boxr{float: right;width: 813px;}
.sywx_boxr_list{float: left; width: 100%;}
.sywx_boxr_list ul {float: left;display: inline-block;}
.sywx_boxr_list ul li{ float: left;width: 189px;height: 189px;margin-right: 11px;border-radius: 4px;box-sizing: border-box;overflow: hidden;margin-bottom: 10px;}
.sywx_boxr_list ul li:nth-child(4n){margin-right: 0px;}
.sywx_boxr_list ul li img {float: left;width: 100%;height: 100%;}
.sywx_boxr_list ul li h3 {float: left;height: 28px;width: 100%;line-height: 28px;margin-top: -28px;text-align: center;color: #f2ede9;background: #252321;opacity: 0.8;font-family: "黑体";font-size: 15px;font-weight: 100;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}



/*遗珍数据库-首页*/
.yzsjkcs_list{float: left;width: 100%;}
.yzsjkcs_list ul{float: left;width: 100%;}
.yzsjkcs_list ul li{float: left;width: 207px;height: 207px;border-radius: 3px;box-sizing: border-box;overflow: hidden;margin-right: 41px;margin-bottom: 40px;}
.yzsjkcs_list ul li:nth-child(5n){margin-right: 0px;}
.yzsjkcs_list ul li img{float: left;width: 100%;height: 100%;}
.yzsjkcs_list ul li h3{float: left;width: 100%;height: 28px;background-color: #2a2e26;opacity: 0.8;margin-top: -28px;color: #f2ede9;text-align: center;font-family: "黑体";font-size: 15px; font-weight: 100; line-height: 28px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.yzsjk_box1{float: left;width: 100%;background: url(../images/yzsjk_bg.png) no-repeat center #d7cbc2;min-height: 569px;}
.yzsjk_box1_c{width: 1200px;margin: 0 auto;}
.yzsjk_ctww_box{float: left;width: 795px;}
.yzsjk_ctww_list{float: left;width: 100%;}
.yzsjk_ctww_list ul{float: left;width: 100%;}
.yzsjk_ctww_list ul li{float: left;width: 235px;height: 235px;margin-right: 44px;margin-bottom: 50px;border-radius: 4px;overflow: hidden;box-sizing: border-box;}
.yzsjk_ctww_list ul li:nth-child(3n){margin-right: 0px;}
.yzsjk_ctww_list ul li img{float: left;width: 100%;height: 100%;}
.yzsjk_ctww_list ul li h3{float: left;height: 28px;width: 100%;line-height: 28px;margin-top: -28px;text-align: center;color: #f2ede9;background: #252321;opacity: 0.8;font-family: "黑体";font-size: 15px;font-weight: 100;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.yzsjk_ctww_boxr{float: right;width: 358px;}
.yzsjk_ctww_boxr ul{float: left;width: 100%;}
.yzsjk_ctww_boxr ul li{float: left;width: 156px;height: 156px;margin-right: 44px;margin-bottom: 24px;border-radius: 4px;overflow: hidden;box-sizing: border-box;}
.yzsjk_ctww_boxr ul li:nth-child(2n){margin-right: 0px;}
.yzsjk_ctww_boxr ul li img{float: left;width: 100%;height: 100%;}
.yzsjk_ctww_boxr ul li h3{float: left;height: 28px;width: 100%;line-height: 28px;margin-top: -28px;text-align: center;color: #f2ede9;background: #252321;opacity: 0.8;font-family: "黑体";font-size: 15px;font-weight: 100;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.yzsjk_rw_boxr{float: left;}
.yzsjk_rw_box{float: right;}


/*EPUB阅读*/
.read_box{float: left;background: #f2ede9;width: 100%;}
.read_box1{width: 1200px;margin: 0 auto;}
.read_mainbox{float: left;width: 100%;margin-top: 20px;margin-bottom: 80px;}
.epubread_left{float: left;width: 347px;height: 1021px;border: 1px solid #ebe1d9;box-sizing: border-box;border-radius: 2px;background: #fffbf7;margin-right: 27px;padding: 19px 12px 19px 17px; overflow: hidden; overflow-y: auto;}
.read_exitbtn{float: left;width: 100%;}
.read_exitbtn a{float: right;width: 76px;height: 16px;line-height: 16px;display: block;background: url(../images/read_exit.png) no-repeat right;font-family: "微软雅黑";font-size: 13px;color: #8a776a;}
.epubread_intro{float: left;width: 100%;}
.epubread_intro h2{float: left;width: 100%;font-family: "微软雅黑";font-size: 18px;color: #4f4126;line-height: 36px;margin: 8px 0px 15px;}
.epubread_introzy{float: left;width: 100%;margin-bottom: 12px;}
.epubread_introzy img{ max-width: 100%;}
.epubread_introzy p{float: left;width: 100%;line-height: 22px;margin-top: 12px;font-family: "微软雅黑";font-size: 13px;color: #717171;}
.epubread_introgjc{float: left;width: 100%;}
.epubread_introgjc font{width: 100%;float: left;font-size: 13px;font-family: "微软雅黑";color: #676767;line-height: 20px;padding-top: 12px;}
.epubread_introgjc font a{color: #99533e;margin-right: 15px;}
.epubread_readbox{float: left;width: 780px;height: 1021px;box-shadow: 1px 1px 5px 5px #e7e2de;background: #f8f8f8;position: relative;}
.epubread_readbox_top{float: left;margin-left: 39px;margin-top: 16px;margin-bottom: 24px;}
.epubread_readbox_top a{float: left;width: 74px;height: 21px;line-height: 21px;border: 1px solid #8a776a;box-sizing: border-box;font-family: "微软雅黑";font-size: 12px;color: #717171;text-align: center;}
.epubread_readbox_top a:first-child{border-bottom-left-radius: 2px;border-top-left-radius: 2px;}
.epubread_readbox_top a:last-child{border-top-right-radius: 2px;border-bottom-right-radius: 2px;}
.readtype_selected{background: #938276;color: #ffffff !important;}
.epubread_readbox_content{float: left;width: 100%;}
.epubread_readbox_content1{width: 714px;height: 883px;margin: 0 auto;overflow-y: auto;}
.read_menubtn{float: right;max-height: 1021px;width: 39px;}
.read_changetheme{float: left;width: 39px;margin-top:20px;}
.read_changetheme i{width: 39px;height: 39px;border: 1px solid #ebe1d9;box-sizing: border-box;background: url(../images/change_theme.png) no-repeat center #fffbf7;display: block;}
.read_changetheme span{width: 39px;height: 22px;line-height: 22px;font-family: "微软雅黑";font-size: 12px;color: #8a776a;text-align: center;display: block;}
.read_catalog{float: left;width: 39px;}
.read_catalog i{width: 39px;height: 39px;border: 1px solid #ebe1d9;box-sizing: border-box;background: url(../images/read_catalog.png) no-repeat center #fffbf7;display: block;}
.read_catalog span{width: 39px;height: 22px;line-height: 22px;font-family: "微软雅黑";font-size: 12px;color: #8a776a;text-align: center;display: block;}
.read_pagechange{float: left;width: 100%;margin-top: 330px;height: 82px;}
.read_pre{width: 39px;height: 39px;margin-bottom: 4px;background: url(../images/read_pre.png) no-repeat center #e2e1e0;display: block;border-radius: 2px;}
.read_next{width: 39px;height: 39px;background: url(../images/read_next.png) no-repeat center #e2e1e0;display: block;border-radius: 2px;}
.read_pre:hover{background: url(../images/read_presel.png) no-repeat center #a19186;}
.read_next:hover{background: url(../images/read_nextsel.png) no-repeat center #a19186;} 
.theme_box{position: absolute;width: 347px;height: 78px;background: url(../images/theme_bg.png) no-repeat;top: 20px;right: -9px;display: none;}
.theme_box1 a{width: 33px;height: 33px;background: url(../images/theme_logo1.png) center 0 no-repeat;display: inline-block;margin-left: 27px;float: left;margin-top: 23px;}
.theme_box2 a{width: 33px;height: 33px;background: url(../images/theme_logo2.png) center 0 no-repeat;display: inline-block;margin-left: 51px;float: left;margin-top: 23px;}
.theme_box3 a{width: 33px;height: 33px;background: url(../images/theme_logo3.png) center 0 no-repeat;display: inline-block;margin-left: 51px;float: left;margin-top: 23px;}
.theme_box4 a{width: 33px;height: 33px;background: url(../images/theme_logo4.png) center 0 no-repeat;display: inline-block;margin-left: 51px;float: left;margin-top: 23px;}
.read_process{float: left;width: 100%;bottom: 0px;position: absolute;}
.catalog_box{position: absolute;width: 440px;height: 407px;top: 81px;right: -9px;background: url(../images/catalog_bg.png) no-repeat;display: none;}
.catalog_boxtitle{float: left;width: 100%;}
.catalog_boxtitle1{margin: 20px auto; text-align: center;display: table;}
.catalog_boxtitle1 i{float:left;width: 16px;height: 18px;display: block;}
.catalog_boxtitle1 i:first-child{background: url(../images/catalog_title1.png) no-repeat center;}
.catalog_boxtitle1 i:last-child{background: url(../images/catalog_title2.png) no-repeat center;}
.catalog_boxtitle1 span{float: left;width: 58px;height: 18px;line-height: 18px;font-family: "微软雅黑";font-size: 16px;color: #717171;text-align: center;}
.catalog_boxtlist{float: left;width: 100%;}
.catalog_boxtlist ul{float: left;width: 100%;padding: 0px 27px 0px 23px;box-sizing: border-box;height: 333px;overflow-y: auto;}
.catalog_boxtlist ul li{float: left;width: 100%;border-bottom: 1px solid #eaeaea;height: 37px;}
.catalog_boxtlist ul li a{float: left;width: 100%;height: 37px;line-height: 37px;font-family: "微软雅黑";font-size: 13px;color: #8a776a;}
.catalog_boxtlist ul li:first-child{border-top: 1px solid #eaeaea;}
/*PDF阅读*/
.pdfread_top{float: left;width: 100%;padding: 0px 43px 0px 45px;box-sizing: border-box;}
.pdfread_content{float: left;width: 1110px;margin-left: 45px;box-sizing: border-box;position: relative; z-index: 1;}
.pdfread_readcontent{width: 100%;height: 708px;}
.pdfread_process{position: relative;margin-top: 57px;}




/*遗珍数据库-图片列表*/
.image_list_box{border: none;margin-bottom: 0px;padding: 0px;}
.image_list_box ul li{margin-left: 16px;margin-top: 16px;}
/*遗珍数据库-图片详情*/
.tpxq_box13l{width: 416px;height: 416px;}
.tpxq_box13l img{width: 416px;height: 416px;}
.tp_rightbox{width: 325px;height: 416px;}
.tp_rightbox h2{line-height: 24px !important;margin-bottom: 12px !important;}
.tp_rightbox_b i p{width: 65px;line-height: 50px;}
.tp_rightbox_b i span{width: calc( 100% - 65px );line-height: 50px;}
.p_t18{padding-top: 18px !important;}
.p_t16{padding-top: 16px !important;}
/*机构库*/
.jgklist_top{float: left;width: 100%;height: 44px;background: #f2ebe6;padding: 0px 20px;box-sizing: border-box;}
.jgklist_top ul li{float: left;height: 44px;padding:8px 0px; box-sizing: border-box;}
.jgklist_top ul li a{padding: 0px 8.8px;box-sizing: border-box;font-family: "微软雅黑";font-size: 14px;color: #424242;height: 27px;line-height: 27px;border-radius: 2px;display: block;}
.jgklist_top ul li a:hover{background: #99533e;color: #ffffff !important;}
.jgklist_top ul li:first-child a{padding: 0px 10px !important;}
.jgk_total{background: #99533e;color: #ffffff !important;}
.jgklist_box1{float: left;width: 100%;padding: 0px 19px;box-sizing: border-box;}
.jgklist_box1 ul li{float: left;width: 100%;height: 141px;padding: 19px 0px;border-bottom: 1px solid #e9e0d9;box-sizing: border-box;}
.jgklist_img{ overflow:hidden; float: left;width: 102px;height: 102px;margin-right: 15px;line-height: 102px;text-align: center;border-radius: 4px;box-sizing: border-box;background-color: #f4f4f4;}
.jgklist_img img{max-width: 102px;max-height: 102px; transition: 0.3s;}
.jgklist_img:hover img{ transform: scale(1.1);}
.jgklist_content{float: left;width: calc( 100% - 117px );height: 102px;}
.jgklist_content h3{float: left;width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;line-height: 26px;height: 26px;font-family: "微软雅黑";font-size: 15px;color: #4f4126;margin-bottom: 20px;}
.jgklist_content h3:hover{color: #99533e !important;}
.jgklist_content p{float: left;width: 100%;line-height: 20px;height: 60px;overflow: hidden;text-overflow: ellipsis;font-family: "微软雅黑";font-size: 13px;color: #b19982;}
.jgklist_tj{margin-right: 15px;}
.jgklist_tj ul li:last-child{border-bottom: none;}
.jgklist_tjcontent{width: calc( 100% - 117px );}

/*机构详情*/
.jgdetail_box{float: left;width: 100%;}
.jgdetail_box_intr{float: left;width: 100%;}
.jgdetail_box_intr1{float: left;width: 100%;height: 128px;background: url(../images/jgk_detailbg.png) no-repeat center;}
.jgdetail_box_intr1 h3{float: left;margin-left: 241px;font-family: "微软雅黑";font-size: 20px;color: #726256;line-height: 71px;}
.jgdetail_box_intr3{float: left;margin-left: 241px;width: calc( 100% - 241px );margin-bottom: 10px;}
.jgdetail_box_intr3 ul li{float: left;margin-right: 28px;}
.jgdetail_box_intr3 ul li span{float: left;font-family: "微软雅黑";font-size: 13px;color: #6d6259;}
.jgdetail_box_intr3 ul li p{float: left;font-family: "微软雅黑";font-size: 13px;color: #807872;}
.jgdetail_box_intr4{float: left;margin-left: 241px;}
.jgdetail_box_intr4 span{float: left;font-family: "微软雅黑";font-size: 13px;color: #6d6259;}
.jgdetail_box_intr4 p{float: left;font-family: "微软雅黑";font-size: 13px;color: #807872;}
.jgdetail_box_intr2{float: left;width: 100%;margin-bottom: 24px;}
.jgdetail_box_intr2img{float: left;margin-left: 17px;width: 196px;height: 196px;line-height:186px;text-align: center;padding: 5px;box-sizing: border-box;border-radius: 4px;background: #ffffff;margin-top: -103px;}
.jgdetail_box_intr2img img{max-width: 186px;max-height: 186px;}
.jgdetail_box_intr2 span{float: left;font-family: "微软雅黑";font-size: 15px;color: #4f4126;margin-left: 25px;margin-top: 20px;width: 912px;}
.jgdetail_box_intr2 span img{ max-width: 100%;}
.jgkxgts_box ul li{margin-right: 19px;}
.jgkxglw_box ul li{width: 32%;}
.jgkxglw_box ul li:nth-child(2n){margin-right: 2%;}
.jgkxglw_box ul li:nth-child(3n){margin-right: 0;}
.jgdetail_box_intr1 i{float: left;margin-left: 13px;margin-top: 29px;font-family: "微软雅黑";font-size: 13px;color: #8d7d70;}

/*专家详情*/
.zjkdetail_box_intr1{height: 71px !important;}
.zjkdetail_box_intr1 h3{margin-left: 186px;}
.zjkdetail_box_intr2img{width: 142px;height: 142px;line-height: 132px;margin-top: -46px !important;}
.zjkdetail_box_intr2img img{max-width: 132px;max-height: 142px;}
.xgjg_box{float: left;width: 100%;padding: 19px 15px;background: #fffbf7;box-sizing: border-box;border-radius: 0 0 4px 4px;border: 2px solid #ebe1d9;border-top: 0;margin-bottom: 20px;}
.xgjg_box ul li{float: left;width: 362px;height: 144px;background: #f6eee8;margin-right: 40px;border-radius: 2px;overflow: hidden;}
.xgjg_box ul li:nth-child(3n){margin-right: 0px;}
.xgjg_img{float: left;width: 144px;height: 144px;margin-right: 10px;line-height: 144px;text-align: center;border-radius: 4px;box-sizing: border-box;background-color: #f4f4f4; overflow: hidden;}
.xgjg_img img{max-width: 144px;max-height: 144px; transition: 0.3s;}
.xgjg_img:hover img{ transform: scale(1.1);}
.xgjg_box1{float: left;width: 195px;}
.xgjg_box1 h3{float: left;width: 100%;height: 52px;line-height: 52px;font-family: "微软雅黑";font-size: 17px;color: #4f4126;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.xgjg_box1 h3:hover{color: #99533e !important;}
.xgjg_box1 p{float: left;width: 100%;line-height: 25px;height: 75px;font-family: "微软雅黑";font-size: 14px;color: #b19982;overflow: hidden;text-overflow: ellipsis;}
.zjkdetail_box_intr3{margin-left: 50px;width: auto;margin-top: 32px;}

/*在线投稿1*/
.zxtgdetail1_box{width: 1082px;}
.zxtgf_box{float: left;width: 100%;margin-bottom: 30px;border: 2px solid #ebe1d9;border-radius: 4px;overflow: hidden;background: #fffbf7;}
.zxtg_qdbtn{float: left;width: 100%;margin-bottom: 28px;margin-top: 15px;}
.zxtg_qdbtn a{margin: 0 auto;width: 109px;height: 30px;line-height: 30px;font-family: "微软雅黑";position: relative;font-size: 14px;color: #ffffff;background-color: #af967f;border-radius: 3px;display: block;text-align: center;}
.tgtj_tj font{float: right;font-family: "微软雅黑";font-size: 15px;color: #393939;}
.tgtj_tj font a{color:#99533e;text-decoration: underline;cursor: pointer;}




/*搜索结果*/
.search_t_box{float: left;width: 100%;background: #fffbf7;border: 2px solid #ebe1d9;border-bottom: none;box-sizing: border-box;}
.searchleft_box{float: left;width: 793px;}
.search_listbox{float:left;width: 100%;margin-bottom: 30px;border: 2px solid #ebe1d9;box-sizing: border-box;border-radius: 4px;background: #fffbf7;}
.search_listbox1{float: left;width: 100%;}
.search_listbox11{float: left;width: 100%;padding: 0px 18px;box-sizing: border-box;background: #f2ede9;}
.search_listbox11 ul li{float: left;margin-right: 35px;}
.search_listbox11 ul li:last-child{ margin-right: 0;}
.search_listbox11 ul li a{float: left;height: 47px;line-height: 47px;font-family: "黑体";font-size: 15px;color: #504027;}
.search_listbox12{float: left;width: 100%;padding: 0px 18px;box-sizing: border-box;margin-top: 17px;}
.search_listbox12 >p{float: left;font-family: "黑体";font-size: 13px;color: #af967f;height: 20px;line-height: 20px;}
.search_listbox12 >p>span{color: #f83a3a;padding: 0px 5px;}
.search_listbox12_r{float: right;}
.search_listbox12_r span{float: left;height: 20px;line-height: 20px;font-family: "黑体";font-size: 13px;color: #af967f;}
.search_listbox12_r a{float: left;height: 20px;line-height: 20px;font-family: "黑体";font-size: 13px;color: #af967f;padding: 0px 7px;}
.search_selected{background: #af967f;border-radius: 3px;color: #ffffff !important;}
.search_listbox .tgtj_lw >ul >li:last-child{border-bottom: 1px solid #e9e0d9;}
.search_listbox .tgtj_sk{margin-bottom: 0px;}
.searchimg_list .booklist_img img{width: 100%;height: 100%;}
.searchimg_list .booklist_gjc{margin-top: 40px;}
.search_wx_boxbox{margin-bottom: 0px;padding: 0px 12px;box-sizing: border-box;}
.search_wx_boxbox table{width: 100%;}
.search_wx_listbox .wx_listbox table{margin-top: 17px;}
.search_listbox_t{border-top: none;border-radius: 0px 0px 4px 4px;}



div.wrapper {
	width: 980px;
	margin: 0 auto;
	position: relative
}

.fn-clear {
	zoom: 1
}

.box_skitter {
	position: relative;
	background: 0 0
}

.box_skitter ul {
	display: none
}

.box_skitter .container_skitter {
	overflow: hidden;
	position: relative;
	width: 1920px!important;
}

.box_skitter .image {
	overflow: hidden
}
.container_skitter img{ width: 1920px; height: 995px;}

.box_skitter .image img {
	display: none
}

.box_skitter .box_clone {
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	overflow: hidden;
	display: none;
	z-index: 20
}

.box_skitter .box_clone img {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 20
}

.prev_button {display: none;
	position: absolute;
	top: 50%;
	left: -5px;
	z-index: 100;
	width: 57px;
	height: 62px;
	overflow: hidden;
	text-indent: -9999em;
	margin-top: -25px;
	/* background: url(../images/prev.png) no-repeat left top!important */
}

.next_button {display: none;
	position: absolute;
	top: 50%;
	right: -5px;
	z-index: 100;
	width: 57px;
	height: 62px;
	overflow: hidden;
	text-indent: -9999em;
	margin-top: -25px;
	/* background: url(../images/next.png) no-repeat left top!important */
}

.box_skitter .label_skitter {
	z-index: 150;
	position: absolute;
	display: none
}

.loading {
	position: absolute;
	top: 50%;
	right: 50%;
	z-index: 10000;
	margin: -16px;
	color: #fff;
	text-indent: -9999em;
	overflow: hidden;
	/* background: url(../images/ajax-loader.gif) no-repeat left top; */
	width: 31px;
	height: 31px
}


#slideshow ul {
	display: none
}
/*错误页*/
.yerror{ overflow: hidden; width: 100%; height: 743px; background: url(../images/error_back.png) no-repeat center;}
.yerror img{ display: block; margin: 0 auto; margin-top: 220px;}
.yerror p{ font-size: 15px; font-family: "微软雅黑"; color: #aea69e; width: 100%; float: left; text-align: center; margin-top: 40px;}
.yerror a{ width: 126px; height: 37px; display: table; margin: 0 auto; background: #a09285; line-height: 37px; overflow: hidden;  position: relative; left: 0; top: 50px; font-size: 15px; font-family: "微软雅黑"; color: #ffffff; text-align: center; border-radius: 3px;}
/*搜索结构*/
.hunhe_list{ width: 100%; float: left; padding:11px 17px; box-sizing: border-box;}
.shiku_list{ width: 100%; float: left; border-bottom: 1px solid #e9e0d9; padding-bottom: 15px; margin-top: 15px;}
.shiku_list1{ width: 96px; height:96px; float: left; margin-right: 10px; line-height: 96px; text-align: center; background: #f4f4f4;}
.shiku_list1 img{  max-width: 96px; max-height:96px;}
.shiku_list2{ width: calc( 100% - 106px ); float: left; height: 96px;}
.shiku_list2 h2{ font-size: 18px; font-family: "微软雅黑"; color: #4f4126; line-height: 18px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; margin-bottom: 21px;}
.shiku_list2 i{ font-family: "微软雅黑"; color: #787878; font-size: 14px; line-height: 14px; width: 100%; float: left; margin-bottom: 15px;}
.shiku_list2 i font{ float: left; width: 57px;}
.shiku_list2 p{ width: 100%; float: left; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 14px; font-family: "微软雅黑"; color: #b19982; line-height: 14px;}
.tushu_list{ width: 100%; float: left; border-bottom: 1px solid #e9e0d9; padding-bottom: 15px; margin-top: 15px;}
.tushu_list1{ width: 96px; height:96px; float: left; margin-right: 10px; line-height: 96px; text-align: center; background: #f4f4f4;}
.tushu_list1 img{  max-width: 96px; max-height:96px;}
.tushu_list2{ width: calc( 100% - 106px ); float: left; height: 96px;}
.tushu_list2 h2{ font-size: 17px; font-family: "微软雅黑"; color: #4f4126; line-height: 17px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; margin-bottom: 12px;}
.tushu_list2 i{ font-family: "微软雅黑"; color: #787878; font-size: 14px; line-height: 14px; width: 100%; float: left; margin-bottom: 13px;}
.tushu_list2 p{ width: 100%; float: left; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 14px; font-family: "微软雅黑"; color: #b19982; line-height: 14px;}
.tushu_list2 span a{ font-family: "微软雅黑"; color: #99533e; margin-right: 18px;}
.tushu_list2 i font{ float: left; width: 57px;}
.lunwen_list{ width: 100%; float: left; border-bottom: 1px solid #e9e0d9; padding-bottom: 15px; margin-top: 15px;}
.lunwen_list h2{ width: 100%; float: left; font-size: 17px; font-family: "微软雅黑"; color: #4f4126; line-height: 17px; margin-bottom: 14px;}
.lunwen_list2{ width: 100%; float: left; margin-bottom: 12px; font-size: 14px; line-height: 14px; font-family: "微软雅黑"; color: #787878;}
.lunwen_list2>i{ max-width: 33%; float: left; margin-right: 35px;}
.lunwen_list>i{ font-family: "微软雅黑"; color: #787878; font-size: 14px; line-height: 14px; width: 100%; float: left; margin-bottom: 13px;}
.lunwen_list span a{ font-family: "微软雅黑"; color: #99533e; margin-right: 18px;}
.lunwen_list p{ width: 100%; float: left; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 14px; font-family: "微软雅黑"; color: #b19982; line-height: 14px;}
.tupian_list i span a { font-family: "微软雅黑"; color: #99533e; margin-right: 18px;}
.lunzhu_list .lunwen_list2{ margin-bottom: 0;}
.wenwu_list{ width: 100%; float: left; border-bottom: 1px solid #e9e0d9; padding-bottom: 15px; margin-top: 15px;}
.wenwu_list h2{ font-size: 17px; font-family: "微软雅黑"; color: #4f4126; line-height: 17px; width: 100%; float: left; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; margin-bottom: 14px;}
.wenwu_list span{ width: 100%; float: left; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 14px; font-family: "微软雅黑"; color: #b19982; line-height: 14px;}
/*列表搜索*/
.list_search{float: right;margin-right: 16px;margin-top: 7px;}
.list_search input{float: left;width: 205px;height: 30px;line-height: 30px;background: #fff;border-radius: 3px 0px 0px 3px;border: none;outline: none;padding-left: 12px;box-sizing: border-box;font-family: "微软雅黑";font-size: 13px;color: #787878;}
.list_search a{float: left;width: 30px;height: 30px;border-radius: 0px 3px 3px 0px;background: url(../images/list_searchico.png) no-repeat center #b8a18d;}
/*专家详情*/
.expert_box .jgdetail_box_intr2img{ width: 157px; height: 157px; line-height: 140px; margin-top: -60px!important; box-shadow: 0 0 5px #decdc0;}
.expert_box .jgdetail_box_intr2img img{ max-width: 148px; max-height: 148px;}
.expert_box2{ width: 995px; float: right; height: 100px;}
.expert_box2 h2{ font-size: 20px; font-family: "微软雅黑"; line-height: 80px;}
.expert_box2 i{ float: left; padding-right: 50px; color: #6e6158; font-size: 15px; font-family: "微软雅黑"; font-weight: 900;}
.expert_box2 i p{ display: inline;  color: #807870; font-weight: 100;}
.exprt_box3{ width: 100%; float: left; margin-top: 15px;}










































@media (max-height:900px) {
	.login_box1{ margin-top: 50px;zoom: 1;-moz-transform:scale(1)}
	.login_box2{ margin-top: 0; zoom: 1;-moz-transform:scale(1);-moz-margin-top: 0px;}
	.login_zc{ font-size: 14px;}
}
@media(max-height:800px) {
	.login_box1{ margin-top: 0;zoom: 0.9;-moz-transform:scale(0.9)}
	.login_box1x{ margin-top: 0;}
	.login_box2{ margin-top: -3px; zoom: 0.83;-moz-transform:scale(0.83);-moz-margin-top: -30px;}
	.login_mt_box .login_footer{height: 113px;padding-top: 10px;}
	.login_zc{ font-size: 15px;}
	.register_type{ zoom: 0.83;-moz-transform:scale(0.83);}
}
.login01D .el-select ,.login01D .el-select *{ border: none !important; outline: none;}
.el-select-dropdown{ font-family: '微软雅黑';}
/*图片显示中间部分*/
.imgobject-fit{object-fit: cover;}
/*集合登录*/
.jihe_login{ width: 100%; float: left; height: 100vh; background: url(../images/login_back.png) no-repeat center;}
.jihe_login>img{ display: block; width: 980px; margin: 0 auto; margin-top: 6vh; margin-bottom: 6vh;}
.login_index{ width: 1200px; margin: 0 auto; height: 620px; background: rgba(255,255,255,0.45); position: relative;}
.login_index>div{ background: rgba(65,54,43,0.88);}
.login_index1{ position: absolute; left: 0; top: 0; width: 296px; height: 336px;}
.login_index1 p{ line-height: 336px; width: 100%; float: left; text-align: center; font-size: 18px; font-family: "微软雅黑"; color: #fff;}
.login_index2{ width: 295px; height: 336px; position: absolute; left: 300px; top: 0;}
.login_index2 p{ line-height: 336px; width: 100%; float: left; text-align: center; font-size: 18px; font-family: "微软雅黑"; color: #fff;}
.login_index3{ width: 299px; height: 187px; position: absolute; left: 599px; top: 0;}
.login_index3 p{ line-height: 187px; width: 100%; float: left; text-align: center; font-size: 18px; font-family: "微软雅黑"; color: #fff;}
.login_index4{ width: 298px; height: 187px; position: absolute; left: 902px; top: 0;}
.login_index4 p{ line-height: 32px; width: 100%; margin-top: 60px; float: left; text-align: center; font-size: 18px; font-family: "微软雅黑"; color: #fff;}
.login_index5{ width: 601px; height: 146px; position: absolute; left: 599px; top: 190px;}
.login_index5 p{ line-height: 32px; width: 100%; margin-top: 43px; float: left; text-align: center; font-size: 18px; font-family: "微软雅黑"; color: #fff;}
.login_index6{ width: 657px; height: 281px; position: absolute; left: 0; top: 339px;}
.login_index6 p{ line-height: 32px; width: 100%; margin-top: 43px; float: left; text-align: center; font-size: 18px; font-family: "微软雅黑"; color: #fff;}
.login_index7{ width: 539px; height: 281px; position: absolute; left: 661px; top: 339px;}
.login_index7 p{ line-height: 32px; width: 100%; margin-top: 108px; float: left; text-align: center; font-size: 18px; font-family: "微软雅黑"; color: #fff;}
.login_index6 div{ font-size: 18px; font-family: "微软雅黑"; color: #ffffff; float: left; text-align: center; line-height: 138.5px; width: 326px; height: 138.5px; float: left; background: rgba(65,54,43,0.88); margin-right: 4px; margin-bottom: 4px;}
.login_index6 a:nth-child(2n) div{ margin-right: 0;}
.login_index>div:hover p{ color: #b87e33;}
.login_index6 div:hover{ color: #b87e33;}
@media (max-width:1370px) {
	.jihe_login>img{ width: 680px;}
	.login_index{ width: 844px; height: 438px;}
	.login_index1{ width: 207px; height: 237px;}
	.login_index1 p{ line-height: 237px; font-size: 13px;}
	.login_index2{ width: 207px; height: 237px; left: 211px;}
	.login_index2 p{ line-height: 237px; font-size: 13px;}
	.login_index3{ width: 209px; height: 130px; left: 422px;}
	.login_index3 p{ line-height: 130px; font-size: 13px;}
	.login_index4{ width: 209px; height: 130px; left: 635px;}
	.login_index4 p{ line-height: 23px; font-size: 13px; margin-top: 45px;}
	.login_index5{ width: 422px; height: 103px; left: 422px; top: 134px;}
	.login_index5 p{ line-height: 23px; font-size: 13px; margin-top: 28px;}
	.login_index6{ width: 462px; height: 198px; left: 0px; top: 240px;}
	.login_index6 div{ font-size: 13px; line-height: 97px; width: 229px; height: 97px;}
	.login_index7{ width: 377px; height: 198px; left: 466px; top: 240px;}
	.login_index7 p{ line-height: 23px; font-size: 13px; margin-top: 76px;}
}



/* ----------------------------------改版---------------------------------- */
/* 首页 */
.main_title i{ background: url(../images/title_bg.png) no-repeat; width: 42px; height: 29px;}
.main_news{ height: 825px; background:url(../images/index_bg1.png)  center no-repeat #f9f6f4;}
.main_news .sm_mainbox{ width: 100%;}
.main_news .sm_contentbox{ height: 298px; border-radius: 4px;}
.main_news .zy_contentbox_top{ width: 475px; margin-bottom: 0; height: 100%; margin-right: 22px;}
.main_news .zy_contentbox_bottom{ width: calc( 100% - 497px ); box-sizing: border-box; padding: 0;}
.main_news .zy_contentbox_bottom li{ padding: 13px 14px 10px; height: auto; box-sizing: border-box; margin-bottom: 5px;}
.main_news .zy_contentbox_bottom li:hover{ border: 1px solid #904a24;}
.main_news .zy_contentbox_bottom li:hover .main_newsc2 h3{ color: #904a24;}
.main_news .zy_contentbox{ background-color: unset; border: none;}
.main_news .zy_contentbox_top h3{ padding: 0 10px; box-sizing: border-box;}
.main_newsc1{ float: left; width: 70px;}
.main_newsc1 span{ float: left; font-size: 13px; color: #504027; font-family: '微软雅黑'; line-height: 18px; margin-bottom: 8px; }
.main_newsc1 font{ float: left; width: 100%; font-size: 18px; color: #504027; font-weight: bold;}
.main_newsc2{ float: left; width: calc( 100% - 70px );}
.main_newsc2 h3{ position: relative; line-height: 18px !important; height: 18px !important; margin-bottom: 9px; padding-left: 25px; box-sizing: border-box;}
.main_newsc2 h3::before{ position: absolute; left: 0; top: 0; content: ''; background: url(../images/index_titleico2.png); width: 17px; height: 17px;}
.main_newsc2 p{ float: left; width: 100%; font-size: 13px; color: #9f9c98; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.main_book{ float: left; width: 100%; height: 421px;}
.main_bookbox{ width: 682px !important; height: 323px;}
.main_bookbox .sm_contentbox{ height: 100%; padding: 0; background: #fffbf7; border: 1px solid #ddd3cb; box-sizing: border-box; }
.main_bookbox .zy_contentbox_top{ width: 321px; height: 321px; margin-right: 26px; border-right: 1px solid #ddd3cb; margin-bottom: 0;}
.main_bookbox .zy_contentbox_top1l{ width: 100%; height: 100%; line-height: 321px; background: #ebe1d9;}
.main_bookbox .zy_contentbox_top1l img{ max-width: 100%; max-height: 100%; vertical-align: middle;}
.main_bookbox .zy_contentbox_bottom{ width: calc( 100% - 348px ); box-sizing: border-box; padding: 20px 22px 0 0;}
.main_bookbox .zy_contentbox_bottom h3{ float: left; width: 100%; font-size: 20px; color: #504027; font-family: "微软雅黑"; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 28px;}
.main_bookbox .zy_contentbox_top1r{ width: 100%; margin-top: 0;}
.main_bookbox .zy_contentbox_top1r span{ margin-bottom: 21px;}
.main_bookbox .zy_contentbox_bottom p{ float: left; width: 100%; font-size: 15px; color: #504027; font-family: "微软雅黑"; margin-top: 15px; max-height: 58px; overflow: hidden; display: -webkit-box; -webkit-line-clamp: 2; line-clamp: 2; text-overflow: ellipsis; -webkit-box-orient: vertical;}
.main_qkbox{ width: 493px !important; height: 323px; margin-right: 0;}
.main_qkboxc{ height: 100% !important; background: #fffbf7; padding: 0; border: 1px solid #ddd3cb;}
.main_qkboxc .zy_contentbox_top{ width: 100%; height: 227px; background: #ebe1d9; padding: 0 35px 20px; box-sizing: border-box;}
.main_qkboxc .zy_contentbox_top h3{ height: 63px; font-weight: 400; line-height: 63px; background: none; margin-top: 0; text-align: left; font-size: 20px; color: #504027;}
.main_qkboxc .zy_contentbox_top1r{ width: calc( 100% - 157px );}
.main_qkboxc .zy_contentbox_bottom{ padding: 0 24px; width: 100%;}
.main_qkboxc .zy_contentbox_bottom p{ float: left; width: 100%; margin-top: 12px; font-size: 15px; color: #504027; font-family: "微软雅黑"; line-height: 35px; }
.main_export{ float: left; width: 100%; min-height: 1005px; background: url(../images/index_bg2.png) no-repeat center #e8e0da;}
.main_exportbox{ width: 100%;}
.main_exportboxc{ width: 100%; height: 216px; padding: 0; background: none;}
.main_exportboxc .zy_contentbox_top{ width: 100%; height: 171px; padding: 23px 20px 0; border-radius: 4px; box-sizing: border-box;}
.main_exportboxc .mzys_contentbox_top1{ width: 850px;}
.main_exportboxc .mzys_contentbox_top1l{ position: relative; width: 128px; height: 128px; margin-right: 26px; border: 5px solid #ded2c9; border-radius: 50%; box-sizing: border-box;}
.main_exportboxc .mzys_contentbox_top1l img{ width: 100%; height: 100%; border: 1px solid #624030;}
.main_exportboxc .mzys_contentbox_top1l i{ position: absolute; right: -12px; top: 2px; background: url(../images/index_ico1.png) no-repeat; width: 49px; height: 19px;}
.main_exportboxc .mzys_contentbox_top1r{ float: left; width: calc( 100% - 154px ); position: relative; padding-right: 54px; box-sizing: border-box;}
.main_exportboxc .mzys_contentbox_top1r:after{ position: absolute; right: 0; top: 17px; width: 1px; height: 87px; content: ''; background: #ddd3cb;}
.main_exportboxc .mzys_contentbox_top1r font{ float: left; position: relative; padding-left: 30px; width: 100%; height: 37px; font-size: 20px; color: #504027; font-weight: 600; line-height: 37px;}
.main_exportboxc .mzys_contentbox_top1r font::before{ position: absolute; left: 0; top:7px; content: ''; width: 23px; height: 22px; background: url(../images/index_titleico1.png) no-repeat;}
.main_exportboxc .mzys_contentbox_top1r p{ float: left; width: 100%; font-size: 13px; color: #504027; line-height: 28px;}
.export_contentbox_top2{ float: right; width: 310px; padding: 0 20px 0 40px; box-sizing: border-box;}
.export_contentbox_top2 li{ float: left; width: 100%; margin-bottom: 3px; }
.export_contentbox_top2 li i{float: left; width: 7px; height: 7px; background-color: #a86147; border-radius: 50%; margin-top: 15px; margin-right: 13px;}
.export_contentbox_top2 li h3{ float: left; width: calc( 100% - 20px ); text-align: left; height: 38px; line-height: 38px; font-family: "微软雅黑"; margin-top: 0; background: none; opacity: 1; font-size: 15px; color: #504027; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.export_contentbox_top2 li h3:hover{ color: #99533e;}
.export_contentbox2 li{ border: none; cursor: pointer; background: #f7f2ed; font-size: 15px; color: #504027; font-family: '黑体'; margin-right: 16px;}
.export_contentbox2 li:nth-child(2n){ margin-right: 16px;}
.export_contentbox2 li:last-child{ margin-right: 0;}
.export_contentbox2 li:hover{ background: #fffbf7; border: 1px solid #7a6654; box-sizing: border-box; color: #504027}
.export_contentbox2 li:hover span{ color: #504027}
.main_media{ float: left; width: 100%;}
.main_video{ float: left; width: 850px;}
.main_videoc{ float: left; width: 100%;}
.main_videoc_l{ float: left; border-radius: 4px; margin-right: 22px; overflow: hidden; background: #ebe1d9; width: 458px; height: 342px; position: relative;}
.main_videoc_l img{ width: 100%; height: 100%; transition: 2s;}
.video_playbtn{ position: absolute; left: calc( 50% - 29.5px ); top: calc( 50% - 29.5px ); width: 59px; height: 59px; background: url(../images/play.png) no-repeat; cursor: pointer;}
.main_videoc_l:hover img{ transform: scale(1.1);}
.main_videoc_r{ float: left; width: calc( 100% - 480px );}
.main_videoc_r ul li{ float: left; cursor: pointer; width: 172px; height: 166px; background: #fffbf7; border: 1px solid #ddd3cb; box-sizing: border-box; margin-right: 24px; margin-bottom: 13px; border-radius: 4px; overflow: hidden; }
.main_videoc_r ul li:nth-child(2n){ margin-right: 0;}
.main_videoc_rc{ float: left; width: 100%; height: 128px; position: relative;}
.main_videoc_rc img { width: 100%; height: 100%; transition: 2s;}
.main_videoc_r h3{ float: left; width: 100%; padding: 0 8px; box-sizing: border-box; font-size: 13px; line-height: 37px; color: #504027; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; text-align: center;}
.main_videoc_rc .video_playbtn{ width: 40px; height: 40px; left: calc( 50% - 20px ); top: calc( 50% - 20px ); background-size: 100% 100%;}
.main_videoc_r ul li:hover .main_videoc_rc img{ transform: scale(1.1);}
.main_videoc_r ul li:hover h3{color: #99533e;}
.main_audio{ float: right; width: 308px;}
.main_audioc{ float: left; width: 100%;}
.main_audioc ul li{ float: left; width: 100%; margin-bottom: 27px; position: relative; padding-left: 35px; box-sizing: border-box;}
.main_audioc ul li:last-child{ margin-bottom: 0;}
.main_audioc ul li::before{position:absolute; left: 0; top: 0; content: ''; width: 19px; height: 19px; background: url(../images/index_ico2.png) no-repeat;}
.main_audioc ul li h3{ float: left; cursor: pointer; width: 100%; font-weight: 400; font-size: 15px; color: #504027; line-height: 19px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.main_audioc ul li h3:hover{ color: #99533e;}
.main_yjbox{ float: left; width: 100%;}
.main_yjboxc{ width: 588px; }
.main_yjboxc:last-child{ margin-right: 0;}
.main_yjboxc1{ background: #fff; border: 1px solid #ddd3cb; border-radius: 4px; box-sizing: border-box; height: 191px; padding: 0;}
.main_yjboxc1 .zy_contentbox_top{ height: 191px;}
.main_yjboxc1 .mzys_contentbox_top1l{ width: 244px; height: 189px; margin-right: 0;}
.main_yjboxc1 .mzys_contentbox_top1l img{ width: 100%; height: 100%; border: none; border-radius: 0;}
.main_yjboxc1r{ float: left; width: calc( 100% - 244px ); padding: 0 29px 0 17px; box-sizing: border-box;}
.main_yjboxc1r h3{ float: left; width: 100%; font-size: 18px; font-family: '黑体'; height: 63px; line-height: 63px; text-align: left; color: #504027; margin-top: 0; opacity: 1; background: none;}
.main_yjboxc1r p{ float: left; width: 100%; font-size: 13px; color: #504027; font-family: '黑体'; line-height: 35px;}
.main_yjboxc1rc{ float: left; width: 100%; margin-top: 22px;}
.main_yjboxc1rc li{ float: left; width: 85px; margin-right: 20px;}
.main_yjboxc1rc li span{ float: left; font-size: 13px; color: #ad4a19; font-family: '黑体'; text-decoration: underline; width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.main_yjboxc1rc li:last-child{ margin-right: 0;}
.main_title a{ margin-left: 18px; margin-top: 0 !important; width: 65px; height: 32px; line-height: 30px; font-size: 13px; text-align: center; font-family: '微软雅黑'; color: #fff; background: url(../images/more_bg.png) no-repeat;}
.main_title a:hover{ color: #fff; background: url(../images/more_bg2.png) no-repeat;}
.main_symlbox{ height: auto; min-height: 907px; background: url(../images/index_bg1.png) no-repeat center #f2ede9;}
.main_symlboxl{ float: left; width: 666px; height: 336px; box-sizing: border-box;;}
.main_symlboxl_l{ float: left; cursor: pointer; position: relative; width: 337px; background: #fffbf7; height: 100%; box-sizing: border-box; padding: 7px;}
.main_symlboxl_lc1{ float: left; width: 100%; height: 100%; overflow: hidden;}
.main_symlboxl_lc{ position: absolute; left: -74px; top: 0; background: url(../images/index_bg3.png) no-repeat; width: 412px; height: 335px;}
.main_symlboxl_l img{ float: left; width: 100%; height: 100%; transition: 2s; z-index: 100; overflow: hidden;}
.main_symlboxl_l:hover img{ transform: scale(1.1);}
.main_symlboxl_r{ float: left; width: calc( 100% - 340px ); margin-left: 3px; height: 100%; border-radius: 4px; border: 1px solid #ddd3cb; background: #fffbf7; padding: 0 27px 0 23px; box-sizing: border-box;}
.main_symlboxl_r h3{ float: left; width: 100%; font-size: 18px; line-height: 90px; color: #504027; font-family: '黑体'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.main_symlboxl_r span{ float: left; width: 100%; font-size: 15px; color: #ad4a19; font-family: '黑体';}
.main_symlboxl_r p{ float: left; margin-top: 22px; width: 100%; font-size: 15px; color: #504027; font-family: '微软雅黑'; line-height: 30px;}
.main_symlboxr{ float: right; width: 504px;}
.main_symlboxr ul li{ width: 154px; height: 154px; margin-right: 20px; margin-bottom: 24px;}
.main_symlboxr ul li:nth-child(3n){ margin-right: 0;}
.ww_detail_right2{ background: #f2ede9;}
.ww_detail_right2 .ww_detail_rightb{ height: 100%; background: #fffbf7; padding-top: 25px;}
.ww_detail_right2 .ww_detail_rightb a{background: #f2ede9; margin-bottom: 20px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.ww_detail_right2 .ww_detail_rightb a:hover{background: #99533e; color: #fff; border-color:#99533e;}
.ww_detail_rightred2{ padding-top: 20px; box-sizing: border-box; background: #fffbf7;}
.ww_detail_rightred2 ul li{ height: 52px;}
.ww_detail_rightred2 ul li h3{ margin: 0; line-height: 52px; width: calc( 100% - 60px ); margin-right: 8px;}
.ww_detail_rightred2 ul li p{ width: 52px; line-height: 52px;}
.yz_mainbox{ width: 600px;}
.yz_mainbox .yz_contentbox134{ height: 222px;}
.yz_mainbox .yz_contentbox2 li{ margin-right: 34px;}
.yz_mainbox .yz_contentbox2 li:nth-child(2n){ margin-right: 34px;}
.yz_mainbox .yz_contentbox2 li:nth-child(3n){ margin-right: 0;}
.rwmain_mainbox{ width: 341px; margin-right: 18px;}
.rwmain_mainbox .rw_contentbox213 { width: 100%; height: auto; border: none; background: none; padding: 0; min-height: 40px;}
.rw_contentbox213l{ width: 93px; height: 93px; float: left; margin-right: 20px; transition: 2s; border: 3px solid #93b39b; border-radius: 50%; box-sizing: border-box; overflow: hidden;}
.rw_contentbox213l img{ width: 100%; height: 100%;}
.rw_contentbox213l:hover{ transform: scale(1.1);}
.rw_contentbox213r{ width: calc( 100% - 113px ); float: left;}
.rw_contentbox213r h2{ float: left; width: 100%; height: 93px; font-size: 20px; color: #504027; font-family: '黑体'; line-height: 93px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.rw_contentbox213r span{ float: right; font-size: 15px; color: #ad4a19; font-family: '黑体';}
.yzrw_contentbox213{ border-bottom: 1px solid #af967f !important; border-radius: 0 !important; padding-bottom: 30px !important;}
.yzrw_contentbox213 span{ font-family: '黑体'; line-height: 26px;}
.rwmain_mainbox .sm_contentbox{ padding: 19px 16px;}
.rwmain_mainbox .yz_contentbox2 li{ width: 147px; margin-bottom: 22px; background: #f2ede9;}
.rwmain_mainbox .yz_contentbox2 li:hover{ background: #99533e; color: #fff; border-color: #99533e;}
.rwmain_mainbox .yz_contentbox2 li:hover span{ color: #fff;}
.qt_mainbox{ margin-right: 0; width: 220px;}
.qt_mainbox .yz_contentbox2 li{ margin-bottom: 22px; background: #f2ede9;}
.qt_mainbox .yz_contentbox2 li:hover{ background: #99533e; color: #fff; border-color: #99533e;}
.qt_mainbox .yz_contentbox2 li:hover span{ color: #fff;}
.yjbook_main{ height: auto; background:url(../images/index_bg1.png) no-repeat center #f9f6f4}
.yjbook_mainl{ float: left; width: 293px; margin-right: 12px; height: 354px; border-radius: 3px; background: #eaddd4; padding: 10px; box-sizing: border-box;}
.yjbook_mainl_cover{ float: left; width: 100%; height: 277px; background: #f4f4f4; text-align: center; line-height: 277px; overflow: hidden;}
.yjbook_mainl_cover img{ max-width: 100%; max-height: 100%; vertical-align: middle; transition: 2s;}
.yjbook_mainl_cover img:hover{ transform: scale(1.1);}
.yjbook_mainl_con{ float: left; width: 100%;}
.yjbook_mainl_con h3{ float: left; width: 100%; font-size: 15px; color: #4f4126; font-family: '微软雅黑'; line-height: 40px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.yjbook_mainl_con h3:hover{color: #99533e !important;}
.yjbook_mainl_con font{ float: left; font-size: 14px; color: #777777; font-family: '微软雅黑'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.yjbook_mainr{ float: left; width: calc( 100% - 305px );}
.yjbook_mainr .tgtj_ts{ background: none; padding: 0; margin-bottom: 14px;}
.yjbook_mainr .tgtj_ts >ul{ padding: 0;}
.yjbook_mainr .tgtj_ts >ul >li{ margin-top: 0; width: 212px; height: 257px; padding: 10px; margin-right: 15px; margin-bottom: 0;}
.yjbook_mainr .tgtj_ts >ul >li:nth-child(4n){ margin-right: 0;}
.yjbook_mainr .tgtj_ts_img{ width: 100%; height: 193px; line-height: 193px;}
.yjbook_mainr .tgtj_ts_content h3{ text-align: left;}
.yjbook_mainr .tgtj_ts_content font{ text-align: left;}
.yjbook_mainr1{ float: left; width: 100%; height: 83px; border-radius: 4px; background: #eaddd4; padding: 8px; box-sizing: border-box;}
.yjbook_mainr1 ul{ float: left; width: 100%; height: 100%; background: #f4f4f4; padding: 0 20px; box-sizing: border-box;}
.yjbook_mainr1 ul li{ float: left; width: 270px; margin-right: 10px;}
.yjbook_mainr1 ul li:nth-child(3n){ margin-right: 0;}
.yjbook_mainr1 ul li i{ float: left; width: 6px; height: 6px; border-radius: 50%; background: #7a6654; margin-top: 14px; margin-right: 6px;}
.yjbook_mainr1 ul li h3{ float: left; width: calc( 100% - 12px ); font-size: 14px; color: #4f4126; font-weight: 600; font-family: '微软雅黑'; line-height: 34px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.yjbook_mainr1 ul li h3:hover{ color: #99533e !important;}
.lwmain_contentbox{ background: #eaddd4; border: none;}
.lwmain_contentbox_l{ float: left; width: 479px; height: 100%; padding: 10px 0 0 24px; box-sizing: border-box;}
.lwmain_contentbox_l h3{ float: left; width: 100%; margin-bottom: 20px; height: 50px; line-height: 50px; font-size: 16px; color: #524335; font-weight: bold; font-family: '微软雅黑'; border-bottom: 1px solid #fff; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.lwmain_contentbox_l p{ float: left; width: 100%; font-size: 13px; color: #524335; font-family: '微软雅黑'; line-height: 32px;}
.lwmain_contentbox .lw_boxf_list{ width: calc( 100% - 479px );}
.lwmain_contentbox .lw_boxf_list ul{ padding-top: 40px;}
.lwmain_contentbox .lw_boxf_list ul li{ margin-right: 24px; margin-bottom: 27px;}
.lwmain_contentbox .lw_boxf_list ul li:nth-child(2n){ margin-right: 0;}
.lwmain_contentbox .wxww_boxf_list ul li font{ color: #524335;}
.lwmain_contentbox .wxww_boxf_list ul li h3{ color: #524335;}
.lwmain_contentbox .wxww_boxf_list ul li:hover h3{ color: #99533e;}
.lwmain_contentbox .wxww_boxf_list ul li:hover font{ color: #99533e;}
.mainqk_contentbox1_list .qk_image{ background: #6b5d58; padding: 0;}
.mainqk_contentbox1_list .qk_image .qk_imagebg{ width: 100%; height: 100%; line-height: 209px; background: #6b5d58;}
.yzsjkindex_mainbox3{ min-height: 432px; background: #eae5e1; padding-bottom: 0;}
.yzsjkindex_mainbox3l{ float: left; width: 336px; height: 336px; border-radius: 3px; overflow: hidden; margin-top: 18px; margin-right: 26px;}
.yzsjkindex_mainbox3l img{ float: left; width: 100%; height: 100%; transition: 2s;}
.yzsjkindex_mainbox3l img:hover{ transform: scale(1.1);}
.yzsjkindex_mainbox3l h3{ float: left; width: 100%; height: 28px; line-height: 28px; margin-top: -28px; background: #252321; opacity: 0.8; font-size: 15px; color: #f2ede9; font-family: '黑体'; text-align: center; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.yzsjkindex_mainbox3 .gwxsjk_contentbox{ width: 838px;}
.yzsjkindex_mainbox3 .yzsjk_boxr_list{ margin-top: 18px; height: auto;}
.yzsjkindex_mainbox3 .syml_list ul li{ width: 153px; height: 153px; margin-right: 17px; margin-bottom: 26px;}
.yzsjkindex_mainbox3 .syml_list ul li:nth-child(5n){ margin-right: 0;}
.gwxsjkindex_mainbox{ min-height: 432px; background:#f2ede9; padding-bottom: 26px;}
.gwxsjkindex_mainbox .gwxsjk_boxr_list{ height: 260px;}
.gwxsjkindex_mainbox .gwxsjk_boxr_list ul li{ margin-bottom: 24px;}
.ky_mainbox{ margin-right: 0; width: 100%;}
.ky_mainbox .kyfzpt_contentbox{ padding: 27px 28px 26px; border: 1px solid #907863; border-radius: 4px; background: #fffbf7; height: 162px;}
.ky_mainbox .zxtg_btn{ width: 326px; height: 106px; padding: 3px 41px 5px 0; border-right: 1px solid #ddd3cb;}
.ky_mainbox .kyfzpt_contentboxli{ width: calc( 100% - 368px ); padding: 6px 24px 0 41px; box-sizing: border-box;}
.ky_mainbox .kyfzpt_contentboxli li{ margin-right: 33px;}
.ky_mainbox .kyfzpt_contentboxli li:last-child{ margin-right: 0;}
.ky_mainbox .kyfzpt_contentboxli li span{ font-family: '黑体';}
.kycg_mainbox{ height: 435px; background: url(../images/main_bg8.jpg) no-repeat center;}
.kycg_mainbox .yqlj_mainbox{ margin-top: 26px;}
.main_artical{ float: left; width: 100%; background: url(../images/index_bg2.png) center no-repeat #f2ede9;}
.main_artical1{ float: left; width: 100%; min-width: 1200px;}
.main_artical2{ float: left; width: 100%; min-width: 1200px;}
.main_artical1_l{ float: left; width: 870px;}
.main_artical1_r{ float: right; width: 305px;}
.main_artical1_lc{ float: left; width: 100%; background: #f8eee5; border: 1px solid #ddd3cb; border-top: 4px solid #af967f; margin-bottom: 22px; padding: 0 19px; box-sizing: border-box; border-radius: 4px; overflow: hidden;}
.main_artical1_lct{ float: left; width: 100%; padding: 19px 0 14px; border-bottom: 1px solid #ddd3cb; margin-bottom: 24px;}
.main_artical1_lct h3{ float: left; position: relative; padding-left: 30px; box-sizing: border-box; cursor: pointer; width: 100%; font-size: 16px; color: #524335; font-weight: bold; font-family: '微软雅黑'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 22px;}
.main_artical1_lct h3::before{ position: absolute; left: 0; top: 0; content: ''; width: 23px; height: 22px; background: url(../images/index_titleico1.png) no-repeat;}
.main_artical1_lct h3:hover{ color: #99533e;}
.main_artical1_lct p{ float: left; width: 100%; font-size: 13px; color: #524335; font-family: '微软雅黑'; line-height: 32px;}
.main_artical1_lcb ul li{ width: 50%; margin-bottom: 22px;}
.main_artical1_rc{ float: left; width: 100%; border: 1px solid #ddd3cb; margin-bottom: 22px; padding: 26px 28px 0 28px; background: #f8eee5; border-radius: 4px; box-sizing: border-box;}
.main_artical1_rc ul li{ float: left; width: 100%; margin-bottom: 27px;}
.main_artical1_lcb ul li:last-child{margin-bottom: 22px;}
.main_artical1_rc ul li h3{ float: left; position: relative; padding-left: 18px; width: 100%; font-size: 15px; box-sizing: border-box; cursor: pointer; color: #524335; font-weight: 400; font-family: '微软雅黑'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.main_artical1_rc ul li h3::before{ position: absolute; left: 0; top: 7px; content: ''; width: 7px; height: 7px; background: #7a6654; border-radius: 50%;}
.main_artical1_rc ul li h3:hover{ color: #99533e;}
.main_artical2con{ float: left; width: 100%; border: 1px solid #ddd3cb; box-sizing: border-box; border-radius: 4px; background: #fffbf7;}
.main_artical2_l{ float: left; width: 760px; padding-right: 36px; box-sizing: border-box;}
.main_artical2_l1{ float: left; width: 253px; height: 253px; cursor: pointer; overflow: hidden;}
.main_artical2_l1 img{ float: left; width: 100%; height: 100%;  transition: 2s;}
.main_artical2_l1 img:hover{ transform: scale(1.1);}
.main_artical2_lc{ float: left; width: calc( 100% - 279px ); margin-left: 26px;}
.main_artical2_lc h3{ float: left; width: 100%; cursor: pointer; margin-top: 34px; margin-bottom: 20px; font-size: 16px; color: #524335; font-weight: bold; font-family: '微软雅黑';}
.main_artical2_lc h3:hover{ color: #99533e;}
.main_artical2con2 .main_artical2_lc h3{ position: relative; padding-left: 30px; box-sizing: border-box;}
.main_artical2con2 .main_artical2_lc h3::before{ position: absolute; left: 0; top: 0; content: ''; width: 23px; height: 22px; background: url(../images/index_titleico1.png) no-repeat;}
.main_artical2_lc p{ float: left; width: 100%; font-size: 13px; color: #524335; line-height: 32px;}
.main_artical2_r{ float: left; width: calc( 100% - 760px ); padding: 17px 30px 24px 0; box-sizing: border-box;}
.main_artical2_r ul li{ float: left; width: 100%; border-bottom: 1px solid #e3d8cd; line-height: 52px;}
.main_artical2_r ul li h3{ float: left; cursor: pointer; max-width: calc( 100% - 60px ); font-weight: 600; font-size: 16px; color: #504027; font-family: '黑体'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.main_artical2_r ul li span{ float: right; width: 45px; font-size: 15px; color: #7a6654; font-family: '黑体'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.main_artical2_r ul li h3:hover{ color: #99533e;}
.main_artical2con2{ margin-bottom: 38px;}
.main_artical2con2 .main_artical2_lc{ width: calc( 100% - 26px );}
.mainyz_box h2{ position: relative; padding-left: 30px; line-height: 22px;}
.mainyz_box h2::before{ position: absolute; left: 0; top: 0; content: ''; width: 23px; height: 22px; background: url(../images/index_titleico1.png) no-repeat;}

/* 图书详情 */
.book_detail{ float: left; width: 100%;}
.detail_xgzsy{ float: left; width: 100%; margin-bottom: 20px; background: #fffbf7; border-radius: 0 0 4px 4px; padding: 0 14px; box-sizing: border-box; border: 2px solid #ebe1d9;}
.detail_xgzsy ul li{ float: left; width: 100%; height: 62px; line-height: 62px; border-bottom: 1px dashed #7d7166; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.detail_xgzsy ul li:last-child{ border-bottom: none;}
.detail_xgzsy ul li h3{ float: left; font-family: '微软雅黑'; font-size: 15px; color: #99533e; position: relative; padding-right: 8px; margin-right: 5px;}
.detail_xgzsy ul li h3::after{ position: absolute; content: ''; right: 0; top: 24px; width: 3px; height: 15px; background: #99533e;}
.detail_xgzsy ul li p{ float: left; width: 100%; font-family: '微软雅黑'; font-size: 15px; color: #585858; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.detail_xgzsy ul li p span{ font-size: 15px; color: #99533e; font-weight: 600;}
.xgtm_box ul li{ width: 32%;}
.xgtm_box ul li:nth-child(2n){ margin-right: 2%;}
.xgtm_box ul li:nth-child(3n){ margin-right: 0;}
.detail_xgts{ float: left; width: 100%; margin-bottom: 20px; background: #fffbf7; border-radius: 0 0 4px 4px; padding: 16px 0 18px; box-sizing: border-box; border: 2px solid #ebe1d9;}
.detail_xgts ul li{ float: left; width: 274px; height: 128px; border-radius: 2px; background: #eaddd4; margin-left: 17px; border: 2px solid #eaddd4; box-sizing: border-box;}
.detail_xgts1{ float: left; cursor: pointer; width: 124px; height: 124px; margin-right: 12px; text-align: center; line-height: 124px; background: #f4f4f4; overflow: hidden;}
.detail_xgts1 img{ max-width: 100%; max-height: 100%; vertical-align: middle; transition: 2s;}
.detail_xgts1 img:hover{ transform: scale(1.1);}
.detail_xgts2{ float: left; width: calc( 100% - 136px ); padding-top: 12px; box-sizing: border-box;}
.detail_xgts2 h3{ float: left; cursor: pointer; font-size: 15px; color: #726256; font-family: '微软雅黑'; width: 100%; line-height: 26px;}
.detail_xgts2 h3:hover{color: #99533e;}
.detail_xgts2 p{ float: left; width: 100%; font-size: 13px; color: #777777; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-top: 30px;}
.detail_xgtsbox{ padding: 18px 23px;}
.detail_xgtsbox ul li{ width: 169px; margin-right: 27px;}
.detail_xgtsbox .xgts_boxt{ width: 165px; height: 123px; overflow: hidden;}
.detail_xgtsbox .xgts_boxt img{ max-width: 100%; max-height: 100%;}
.detail_xgtsbox .xgts_boxb{ width: 100%;}
.detail_xgtsbox .xgts_boxb h3{ line-height: 19px;}
.detailxgyp_box{ padding: 18px 24px;}
.detailxgyp_box ul li{ width: 169px; margin-right: 26px; margin-bottom: 0;}
.detailxgyp_box li:nth-child(4n){ margin-right: 26px;}
.detailxgyp_box ul li:nth-child(6n){ margin-right: 0;}
.detailxgyp_box li h2{ float: left;}
.detailxgyp_box li a img{ width: 100%; height: 126px; border: 2px solid #eaddd4; box-sizing: border-box; border-radius: 2px; overflow: hidden;}
.book_detail .detail_title{ float: left; box-sizing: border-box; width: 100%; height: 50px; padding: 0 12px 0 20px; line-height: 50px; background-color: #EADDD4; border-radius: 3px;}
.book_detail .detail_title h3{ float: left; position: relative; font-size: 18px; font-family: '微软雅黑'; max-width: calc( 100% - 80px ); color: #4f4126; font-weight: bold; padding-left: 45px; box-sizing: border-box;}
.book_detail .detail_title h3::before{ position: absolute; left: 0; top: 13px; width: 34px; height: 23px; content: ''; background: url(../images/title_bg2.png) no-repeat;}
.book_detailbox{ background: url(../images/detail_bg.png) no-repeat right top 82px #fffbf7; box-sizing: border-box; padding: 0 0 20px; border: 1px solid #eaddd4;}
.book_detailboxc{ position: relative; float: left; width: calc( 100% - 60px ); padding-bottom: 25px; border-bottom: 1px dashed #7d7166; margin: 20px 26px 0 34px;}
.book_detailboxc .qk_leftbox{ position: relative; border-radius: 4px; width: 294px; height: 295px; background: #fef5eb;}
.book_detailboxc .qk_leftbox::before { content: ''; z-index: 10; position: absolute; left: -19px; bottom: 0; width: 90px; height: 19px; background: url(../images/book_bg_ico1.png) no-repeat;}
.book_detailboxc .qk_leftbox::after { content: ''; position: absolute; right: -20px; top: -7px; width: 61px; height: 34px; background: url(../images/book_bg_ico2.png) no-repeat;}
.book_detailboxc .qk_leftboxc{ width: 100%; height: 98%; line-height: 295px; margin: 0; background: none;}
.book_detailboxc .qk_leftboxc img{ max-width: 100%; max-height: 100%;}
.book_detailboxc .qk_rightbox{ float: left; min-height: 295px; height: auto; padding: 0; background: none; width: calc( 100% - 294px); padding-left: 54px; padding-right: 0;}
.book_detailboxc .qk_rightbox_b i >p{ line-height: 52px; position: relative; width: auto; max-width: 110px; margin-right: 10px; padding-left: 29px; box-sizing: border-box; font-size: 16px;}
.book_detailboxc .qk_rightbox_b i >p::before{ position: absolute; left: 0; top: 17.5px; content: ''; width: 19px; height: 17px; background: url(../images/title_ico2.png) no-repeat;}
.book_detailboxc .qk_rightbox_b i span{ line-height: 52px; width: calc( 100% - 120px ); font-size: 16px;}
.book_detailboxc .qk_rightbox_b i span img{ max-width: 100%;}
.book_detailboxc .qk_rightbox_b i .origin_price { margin-left: 10px; color: #DB3A3A; font-weight: normal; text-decoration: line-through;}
.book_detailboxc .opreation_box { position: absolute; bottom: 25px; right: 0;}
.book_detailboxc .opreation_box>a { float: left; box-sizing: border-box; margin-right: 16px; padding-left: 25px; width: 95px; height: 32px; line-height: 32px; text-align: center; font-size: 15px; color: #fff; background-color: #AD816D !important; box-shadow: 0 3px 7px rgba(0,0,0,0.23);}
.book_detailboxc .opreation_box>a:last-child {margin-right: 0;}
.book_detailboxc .opreation_box .detail_collection {background: url(../images/collection_ico3.png) no-repeat 19px;}
.book_detailboxc .opreation_box .detail_collected {background-position: 15px;}
.book_detailboxc .opreation_box .read {background: url(../images/book_readico2.png) no-repeat 19px 7px;}
.book_detailboxc .opreation_box .buy {background: url(../images/book_cart.png) no-repeat 19px 8px;}
.book_detailboxc .lin_height20p_t14{ padding-top: 16px;}
.book_detailintro { float: left; width: 100%; padding: 0 34px; box-sizing: border-box;}
.book_introTitle { display: inline-block; margin: 20px 0 13px; padding: 3px; text-align: center; font-size: 15px; font-weight: bold; color: #4f4126; background-color: #E4D8CC;}
.book_introTitle .border_box {padding: 4px 9px; border: 1px solid #d2c2aa;}
.book_introTitle .border_box span { position: relative; top: -2px; display: inline-block; margin: 0 5px; width: 5px; height: 5px; background-color: #D94444; border-radius: 50%;}
.book_introcon{ float: left; width: 100%; color: #7a6654; line-height: 26px;}
.sk_detailboxsy .qk_rightbox_b i >p::before{ position: absolute; left: 0; top: 17.5px; content: ''; width: 19px; height: 17px; background: url(../images/title_ico2.png) no-repeat;}
.sk_detailboxsy .qk_rightbox_b i >p{ position: relative; width: auto; padding-left: 29px; box-sizing: border-box; line-height: 52px;}
.sk_detailboxsy .qk_rightbox_b i span{ width: calc( 100% - 130px ); padding: 9px 0; margin-left: 5px;}

/* 出版社详情 */
.publishing_detail{ background: url(../images/detail_bg.png) no-repeat right top 82px #fffbf7; box-sizing: border-box; padding: 0 0 20px; border: 1px solid #EADDD4;}
.publishing_detail .detail_title{ float: left; box-sizing: border-box; width: 100%; height: 50px; padding: 0 12px 0 20px; line-height: 50px; background-color: #EADDD4; border-radius: 3px;}
.publishing_detail .detail_title h3{ float: left; position: relative; font-size: 18px; font-family: '微软雅黑'; max-width: calc( 100% - 80px ); color: #4f4126; font-weight: bold; padding-left: 45px; box-sizing: border-box;}
.publishing_detail .detail_title h3::before{ position: absolute; left: 0; top: 13px; width: 34px; height: 23px; content: ''; background: url(../images/title_bg2.png) no-repeat;}
.pub_detail_cont{ position: relative; float: left; width: calc( 100% - 60px ); padding-bottom: 27px; border-bottom: 1px dashed #7d7166; margin: 20px 26px 0 34px;}
.pub_detail_cont .qk_leftbox{ position: relative; border-radius: 4px; width: 158px; height: 168px; background: #fef5eb; border: 1px solid #EBE1D9;}
.pub_detail_cont .qk_leftboxc{ width: 100%; height: 100%; line-height: 168px; margin: 0; background: none;}
.pub_detail_cont .qk_leftboxc img{ max-width: 100%; max-height: 100%; width: 100%; height: 100%;}
.pub_detail_cont .qk_rightbox{ float: left; height: auto; padding: 0; background: none; width: calc( 100% - 165px); padding-left: 40px; padding-right: 0;}
.pub_detail_cont .qk_rightbox_b i >p{ line-height: 52px; position: relative; width: auto; max-width: 110px; margin-right: 10px; padding-left: 29px; box-sizing: border-box; font-size: 16px;}
.pub_detail_cont .qk_rightbox_b i p::before{ position: absolute; left: 0; top: 17.5px; content: ''; width: 19px; height: 17px; background: url(../images/title_ico2.png) no-repeat;}
.pub_detail_cont .qk_rightbox_b i span{ line-height: 52px; width: calc( 100% - 120px ); font-size: 16px;}
.pub_detail_cont .qk_rightbox_b i span img{ max-width: 100%;}
.pub_detailintro { float: left; width: 100%; padding: 0 34px; box-sizing: border-box;}
.pub_introTitle { display: inline-block; margin: 20px 0 13px; padding: 3px; text-align: center; font-size: 15px; font-weight: bold; color: #4f4126; background-color: #E4D8CC;}
.pub_introTitle .border_box {padding: 4px 9px; border: 1px solid #d2c2aa;}
.pub_introTitle .border_box span { position: relative; top: -2px; display: inline-block; margin: 0 5px; width: 5px; height: 5px; background-color: #D94444; border-radius: 50%;}
.pub_introcon{ float: left; width: 100%; font-size: 14px; font-family: "微软雅黑"; color: #4B4B4A; line-height: 26px; text-align: justify;}
.pub_introcon img {max-width: 100%; margin: 5px 0;}

/* 人物详情 */
.person_detail{ background: url(../images/detail_bg.png) no-repeat right top 82px #fffbf7; box-sizing: border-box; padding: 0; border: 1px solid #EADDD4;}
.person_detail .detail_title{ position: relative; float: left; box-sizing: border-box; width: 100%; height: 50px; padding: 0 12px 0 20px; line-height: 50px; background-color: #EADDD4; border-radius: 3px;}
.person_detail .detail_title h3{ float: left; position: relative; font-size: 18px; font-family: '微软雅黑'; max-width: calc( 100% - 80px ); color: #4f4126; font-weight: bold; padding-left: 45px; box-sizing: border-box;}
.person_detail .detail_title h3::before{ position: absolute; left: 0; top: 13px; width: 34px; height: 23px; content: ''; background: url(../images/title_bg2.png) no-repeat;}
.person_detail_cont{ position: relative; float: left; width: calc( 100% - 60px ); padding-bottom: 27px; margin: 20px 26px 0 34px;}
.person_detail_cont .left { float: left; width: 212px; margin-right: 28px;}
.person_detail_cont .qk_leftbox{ position: relative; float: none; border-radius: 4px; width: 212px; height: 230px; background: #fef5eb; border: 1px solid #EBE1D9;}
.person_detail_cont .qk_leftboxc{ width: 100%; height: 100%; line-height: 230px; margin: 0; background: none;}
.person_detail_cont .qk_leftboxc img{ max-width: 100%; max-height: 100%; width: 100%; height: 100%;}
.person_detail_cont .person_info {margin-top: 15px;}
.person_detail_cont .person_info i{display: block;}
.person_detail_cont .qk_rightbox{ float: left; height: 168px; padding: 0; background: none; width: calc( 100% - 294px - 200px); padding-left: 40px; padding-right: 0;}
.person_detail_cont .person_info i >p{ float: left; line-height: 52px; position: relative; width: auto; max-width: 110px; margin-right: 10px; padding-left: 29px; box-sizing: border-box; font-size: 16px; color: #4f4126;}
.person_detail_cont .person_info i p::before{ position: absolute; left: 0; top: 17.5px; content: ''; width: 19px; height: 17px; background: url(../images/title_ico2.png) no-repeat;}
.person_detail_cont .person_info i span{ line-height: 52px; width: calc( 100% - 120px ); font-size: 16px; color: #686868;}
.person_detail_cont .person_info i.person_address::after{content: ""; display: block; clear: both;}
.person_detail_cont .person_info i.person_address span {float: left; width: 136px; line-height: 25px; margin-top: 13px; margin-bottom: 7px;}
.person_detail_cont .person_info i span img{ max-width: 100%;}
.person_detail_cont .right {float: left; width: calc(100% - 240px); padding-right: 15px; box-sizing: border-box;}
.person_introTitle { display: inline-block; margin-bottom: 10px; padding: 3px; text-align: center; font-size: 15px; font-weight: bold; color: #4f4126; background-color: #E4D8CC;}
.person_introTitle .border_box {padding: 4px 9px; border: 1px solid #d2c2aa;}
.person_introTitle .border_box span { position: relative; top: -2px; display: inline-block; margin: 0 5px; width: 5px; height: 5px; background-color: #D94444; border-radius: 50%;}
.person_introcon{ float: left; width: 100%; font-size: 14px; font-family: "微软雅黑"; color: #4B4B4A; line-height: 26px; text-align: justify;}
.person_introcon img {max-width: 100%; margin: 5px 0;}

/* 出版详情 */
.publish_detailbox{ background: url(../images/detail_bg3.png) no-repeat right 226px top 67px #fffbf7;}
.publish_boxl{ float: left; width: 870px;}
.publish_boxr{ float: right; width: 248px; height: 268px; border: 1px solid #ebe1d9; border-radius: 3px; box-sizing: border-box;}
.publish_boxr img{ width: 100%; height: 100%;}
.publish_detailbox .book_detailboxc{ padding-bottom: 0; border-bottom: none;}
.publish_con{ float: left; width: 100%; padding: 20px 18px; background: #fffbf7; border: 1px solid #ebe1d9; box-sizing: border-box; margin-bottom: 20px;}
.detail_xgrwbox{ float: left; width: 100%; padding: 16px 24px; box-sizing: border-box; background: #fffbf7; border: 2px solid #ebe1d9; margin-bottom: 20px; border-radius: 0 0 4px 4px;}
.detail_xgrwbox ul li{ float: left; width: 146px; margin-right: 52px; border: 1px solid #eaddd4; background: #eaddd4;}
.detail_xgrwbox ul li:last-child{ margin-right: 0;}
.detail_xgrwboxc1{ float: left; width: 100%; height: 124px; overflow: hidden;}
.detail_xgrwboxc1 img{ width: 100%; height: 100%; transition: 2s;}
.detail_xgrwboxc1 img:hover{ transform: scale(1.1);}
.detail_xgrwbox ul li h3{ float: left; width: 100%; line-height: 20px; font-size: 13px; color: #726256; font-family: '微软雅黑'; text-align: center; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.detail_xgrwbox ul li h3:hover{ color: #99533e;}

/* 石窟详情 */
.detail_skl{ float: right; width: 830px;}
.detail_skr{ float: left; width: 284px; height: 308px; position: relative; overflow: hidden;}
.detail_skr >div{ float: left; width: 100%; height: 100%;}
.detail_changeBtn{ position: absolute; z-index: 10; right: 13px; top: 18px; width: 53px; height: 53px; background: url(../images/detail_ico1.png) no-repeat; background-size: 100% 100%;}
.sk_detailbox{ background: url(../images/detail_bg3.png) no-repeat right -232px top 70px #fffbf7; position: relative; padding-bottom: 30px;}
.sk_detailbox .detail_title {position: relative;}
.sk_detailbox .opreation_box { position: absolute; bottom: 9px; right: 26px;}
.sk_detailbox .opreation_box>a { float: left; box-sizing: border-box; margin-right: 16px; padding-left: 25px; width: 95px; height: 32px; line-height: 32px; text-align: center; font-size: 15px; color: #fff; background-color: #AD816D !important; box-shadow: 0 3px 7px rgba(0,0,0,0.23);}
.sk_detailbox .opreation_box>a:last-child {margin-right: 0;}
.sk_detailbox .opreation_box .detail_collection {background: url(../images/collection_ico3.png) no-repeat 19px;}
.sk_detailbox .opreation_box .detail_collected {background-position: 15px;}
.sk_detailboxc{ padding-bottom: 0; border-bottom: none;}
.sk_detailboxc .qk_rightbox_b3 i >p{ line-height: 46px;}
.sk_detailboxc .qk_rightbox_b3 i >p::before{ top: 14.5px;}
.sk_detailboxc .qk_rightbox_b3 i span{ line-height: 46px;}
.sk_detailboxc .lin_height20p_t14{ padding-top: 13px;}
.sk_detailboxsy{ float: left; width: 100%; background: #fffbf7; padding: 20px 20px 10px; margin-bottom: 20px; border: 2px solid #ebe1d9; border-radius: 2px; box-sizing: border-box;}
.sk_detailboxsy_t{ float: left; width: 100%;}
.sk_detailboxsy_t li{ float: left; width: 126px; height: 36px; margin-bottom: 10px; margin-right: 18px; background: url(../images/sy_ico1.png) no-repeat; text-align: center; line-height: 36px;}
.sk_detailboxsy_t li a{ line-height: 37px; font-size: 15px; color: #504027; font-family: '宋体';}
.sk_syCur{ background: url(../images/sy_ico2.png) no-repeat !important;}
.sk_syCur a{ color: #99533e !important;}
.sk_detailboxsy_t li:hover{ background: url(../images/sy_ico2.png) no-repeat !important;}
.sk_detailboxsy_t li:hover a{ color: #99533e !important;}
.sk_sycon{ float: left; width: 100%;}
.sk_sycon1{ float: left; width: 100%; padding: 10px 0; color: #7a6654; line-height: 26px;}
.sk_sycon1 img{ width: 60px; height: 60px;}
.sk_sycon2{ float: left; width: 100%;}
.sk_sycon2 h3{ float: left; position: relative; margin-bottom: 10px; margin-right: 10px; cursor: pointer; padding-left: 15px; line-height: 18px; box-sizing: border-box; font-size: 13px; color: #99533e; font-family: '微软雅黑';}
.sk_sycon2 h3::before{ position: absolute; content: ''; left: 0; width: 12px; height: 14px; top: 2px; background: url(../images/book_ico1.png) no-repeat;}
.mainDetail_box{ background: url(../images/detail_bg6.png) repeat-y center top #f2ede9; position: relative;}

/* 石窟列表 */
.tmss_list .rmtj_listimg{ margin-right: 11px; }
.tmss_list .rmtj_listcontent{ width: calc( 100% - 112px );}
.tmss_list .rmtj_listcontent h3{ color: #99533e;}
.tmss_list .rmtj_listcontent span{ margin-top: 5px;}
.sklist_box{ width: 805px;}
.sklist_boxc{ float: left; width: 100%; padding: 0 17px 0 26px; box-sizing: border-box;}
.sklist_box1{ float: left; width: 100%; line-height: 51px; border-bottom: 1px solid #eae2da;}
.sklist_box1_l{ float: left;}
.sklist_box1_l li{ float: left; margin-right: 35px;}
.sklist_box1_l li a{ float: left; font-size: 15px; color: #2e2e2e; font-family: '微软雅黑';}
.sklist_cur a{ color: #99533e !important; font-weight: bold;}
.sklist_box1_l li:hover a{ color: #99533e !important; font-weight: bold;}
.sklist_box1_r{float: right;}
.sklist_box1_r span{ float: left; font-size: 13px; color: #161616; font-family: '微软雅黑'; margin-right: 7px;}
.sklist_box1_r1{ float: left; width: 205px; height: 34px; border: 1px solid #c5b3a4; border-radius: 3px; background: #ffffff; margin-top: 8px; overflow: hidden; box-sizing: border-box;}
.sklist_box1_r1 input{ float: left; border: none; line-height: 32px; width: 177px;padding: 0 10px; box-sizing: border-box;}
.list_searchbtn{ float: left; background: url(../images/search_ico4.png) no-repeat; width: 14px; height: 14px; margin-top: 9px;}
.sklist_box2{ float: left; width: 100%;}
.sklist_box2 ul li{ float: left; width: 100%; border-bottom: 1px solid #eae2da; padding: 17px 0;}
.sklist_box2_l{ float: left; width: 145px; height: 145px; border-radius: 3px; box-sizing: border-box; overflow: hidden; margin-right: 11px;}
.sklist_box2_l img{ width: 100%; height: 100%; transition: 2s;}
.sklist_box2_l img:hover{ transform: scale(1.1);}
.sklist_box2_r{ float: left; width: calc( 100% - 156px ); padding-top: 13px;}
.sklist_box2_r h3{ float: left; width: 100%; font-size: 15px; color: #99533e; font-weight: 600;}
.sklist_box2_r1{ float: left; width: 100%; margin-top: 22px;}
.sklist_box2_r1 span{ float: left; max-width: 48%; margin-right: 4%; font-size: 13px; color: #787878; font-family: '微软雅黑';}
.sklist_box2_r1 span:last-child{ margin-right: 0;}
.sklist_box2_r2{ float: left; width: 100%; font-size: 13px; color: #2e2e2e; line-height: 25px; font-family: '微软雅黑'; margin-top: 15px;}
.sklist_box2_rc1{ width: 100%; padding-top: 0;}
.sklist_box2_l2{ width: 169px; height: 126px;}
.sklist_box2_rc2{ width: calc( 100% - 180px );}
.sklist_box2_l3{ float: left; width: 126px; height: 126px; margin-right: 11px; border-radius: 3px; background: #eaddd4; overflow: hidden; line-height: 126px;}
.sklist_box2_l3 img{ max-width: 100%; max-height: 100%; vertical-align: middle; transition: 2s;}
.sklist_box2_l3 img:hover{ transform: scale(1.1); }
.sklist_box2_rc3{ width: calc( 100% - 137px );}
/* 图片详情 */
.img_detail .detail_title h3{ width: calc( 100% - 110px); }
.img_detail .book_detailbox{ background: #fffbf7;}
.img_detailCover{ float: left; margin-left: -40px; width: calc( 100% + 80px ); background: url(../images/detail_bg5.png) no-repeat center top 36px; text-align: center; padding-bottom: 16px; margin-bottom: 20px;}
.img_detailCover img{ width: 660px; height: 434px; margin: 0 auto; }
.img_detailboxc{ padding: 0; background: none; height: auto; margin-bottom: 16px;}
.w_25{ width: 25% !important;}
.review_btn{background: url(../images/review_ico.png) no-repeat 19px; position: relative;}
.imgDownload_btn{ background: url(../images/download_ico1.png) no-repeat 19px;}
.qk_rightbox_b{ float: left; width: 100%; position: relative;}
.img_detail .opreation_box{ bottom: 13px;}

/* 条目详情 */
.tm_detailboxc{ padding-bottom: 0; border-bottom: none;}
.tm_detailbox{ background: url(../images/detail_bg.png) no-repeat center top 242px #fffbf7;}
.tm_detailboxc_l{ float: left; width: 612px; min-height: 301px; padding-right: 27px; box-sizing: border-box; position: relative;}
.tm_detailboxc_l .qk_rightbox_b i{ width: 100%}
.tm_detailboxc_l .qk_rightbox_b i:last-child{ margin-bottom: 20px;}
.tm_detailboxc_l .opreation_box{ bottom: -5px;}
.tm_detailboxc_lc{ float: left; width: 100%; padding-bottom: 25px; border-bottom: 1px dashed #7d7166; color: #7a6654; line-height: 26px;}
.tm_detailboxc_r{ float: right; width: 516px; margin-right: 2px; height: 301px; border-radius: 3px; background: #fef5eb; padding: 0 32px; box-sizing: border-box;}
.tm_detailboxc_r .listtitle_box{ background: none; margin-top: 4px !important;}
.tm_detailboxc_r .listtitle_box i{ margin-left: 0;}
.tm_detailboxc_r .listtitle_box h3{ float: left; font-size: 16px; color: #4f4126; font-weight: 600; line-height: 43px; font-family: '微软雅黑';}
.tm_detailboxc_r1{ float: left; width: 100%;}
.tm_detailboxc_r1l{ float: left; width: 144px; height: 213px; line-height: 213px; text-align: center; margin-right: 36px;}
.tm_detailboxc_r1 img{ max-width: 100%; max-height: 100%; transition: 2s;}
.tm_detailboxc_r1 img:hover{ transform: scale(1.1);}
.tm_detailboxc_r1r{ float: left; width: calc( 100% - 180px );}
.tm_detailboxc_r1r h3{ float: left; width: 100%; font-size: 16px; font-family: '微软雅黑'; color: #4f4126; font-weight: 600; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; margin-bottom: 6px;}
.tm_detailboxc_r1r h3:hover{ color: #99533E;}
.tm_detailboxc_r1r1{ float: left; width: 100%; font-size: 13px; font-family: '微软雅黑'; margin-top: 20px;}
.tm_detailboxc_r1r1 span{ float: left; color: #4f4126; max-width: 58px;}
.tm_detailboxc_r1r1 p{ color: #676767; max-width: calc( 100% - 58px); overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.keyword_btn{ float: left; width: 16px; height: 16px; background: url(../images/more.png) no-repeat; margin-top: 17px; cursor: pointer; }
.detail_keyword1{ height: 20px; overflow: hidden;}
.detail_keyword2{ height: auto;}

/* 古文献列表 */
.anLittitle_box{ background-color: #f2ede9;}
.anLittitle_box .list_search{ border: 1px solid #b8a18d; border-radius: 3px;}
.anLiteraturebox{ background: none; margin-bottom: 0; margin-top: 29px; padding-bottom: 0;}
.anLiteraturebox ul li{ width: 194px; height: auto; margin-top: 0; margin-right: 31px; background: none; margin-bottom: 8px; padding: 0;} 
.anLiteraturebox_c{ width: 100%; padding: 4px; height: 194px; box-sizing: border-box; background: #eaddd4; border-radius: 3px;}
.anLiteraturebox ul li:nth-child(5n){ margin-right: 0;}
.anLiteraturebox ul li img{ width: 100%; height: 100%;}
.anLiteraturebox ul li p{ color: #4f4126; height: 45px; line-height: 45px;}

/* 条目库 */
.entry_box{ float: left; width: 100%; margin-top: 130px;}
.entry_box1{ float: left; width: 100%; background: url(../images/main_bg3.png) no-repeat center bottom #f2ede9; min-height: 304px;}
.entry_box2{ float: left; width: 100%; background: url(../images/main_bg4.png) no-repeat center top #ffffff; min-height: 400px;}
.entry_box1_list{ float: left; width: 100%; border: 1px solid #ddd3cb; border-radius: 4px; box-sizing: border-box; padding: 15px 0; background: #fffbf7;}
.entry_box1_list ul li{ float: left; line-height: 38px; width: 355px; margin-left: 30px;}
.entry_box1_list ul li h3{ float: left; position: relative; cursor: pointer; padding-left: 15px; box-sizing: border-box; width: 100%; font-size: 15px; color: #504027; font-family: '微软雅黑'; font-weight: 400; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.entry_box1_list ul li h3::before{ position: absolute; content: ''; left: 0; top: 16px; width: 6px; height: 6px; border-radius: 50%; background: #7a6654;}
.entry_box1_list ul li h3:hover{ color: #99533E;}
.entry_box2c{ float: left; width: 100%; background: #f8eee5; border-radius: 0 0 4px 4px; border-top: 5px solid #af967f; height: 290px; padding: 0 25px; box-sizing: border-box;}
.entry_box2c_l{ float: left; width: 456px;}
.entry_box2c_l h3{ float: left; cursor: pointer; width: 100%; line-height: 54px; border-bottom: 1px solid #ddd3cb; font-size: 16px; color: #524335; font-family: '微软雅黑';}
.entry_box2c_l p{ float: left; width: 100%; font-size: 13px; color: #524335; margin-top: 15px; line-height: 30px;}
.entry_box2c_r{ float: left; margin-left: 55px; width: calc( 100% - 511px ); margin-top: 32px;}
.entry_box2c_r ul li{ float: left; width: 314px; margin-right: 10px;}
.entry_box2c_r ul li:nth-child(2n){ margin-right: 0;}
.entry_box2c_r ul li h3{ position: relative; cursor: pointer; float: left; width: 100%; margin-bottom: 24px; padding-left: 21px; box-sizing: border-box; font-size: 15px; color: #524335; font-weight: 400; font-family: '微软雅黑'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.entry_box2c_r ul li h3:hover{ color: #99533E;}
.entry_box2c_r ul li h3::before{ position: absolute; content: ''; left: 0; top: 5px; width: 7px; height: 7px; border-radius: 50%; background: #7a6654;}
.entry_box3{ float: left; width: 100%; background: #f2ede9; min-height: 308px;}
.entry_box3_list{ float: left; width: 100%; background: #fffbf7; border-radius: 4px; border: 1px solid #ddd3cb; padding: 25px 30px 20px; box-sizing: border-box;}
.entry_box3_list ul li{ float: left; width: 355px; margin-right: 35px;}
.entry_box3_list ul li:last-child{ margin-right: 0;}
.entry_box3_list ul li h3{ float: left; width: 100%; cursor: pointer; margin-bottom: 12px; font-size: 16px; color: #524335; font-family: '微软雅黑'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.entry_box3_list ul li p{ float: left; width: 100%; font-size: 13px; color: #524335; font-family: '微软雅黑'; line-height: 26px;}
.entry_box3_list ul li h3:hover{ color: #99533E;}
.entry_box4{ float: left; width: 100%; min-height: 489px; background: url(../images/main_bg9.png) no-repeat center #f0e3d7;}
.entry_box4_con{ float: left; width: 100%; margin-top: 32px; background: #fffbf7; min-height: 422px; margin-bottom: 32px;}
.entry_box4_class{ position: relative; overflow: hidden; float: left; width: 100%; border: 1px solid #dfd7cf; border-top: none; padding: 0 30px; height: 52px; box-sizing: border-box; background-color: #F8EFE7;}
.entry_box4_class .slide_left{position: absolute; left: 0px; top: 0px; width: 30px; height: 100%; background: url(../images/slide_left_ico.png) no-repeat 13px center; background-color: #F8EFE7;}
.entry_box4_class .slide_right{position: absolute; right: 0px; top: 0px; width: 30px; height: 100%; background: url(../images/slide_right_ico.png) no-repeat 0 center; background-color: #F8EFE7;}
.entry_box4_class ul{ position: absolute; display: block; white-space: nowrap; width: auto;}
.entry_box4_class li{ display: inline-block; width: 114px; height: 51px; line-height: 51px; text-align: center;}
.entry_box4_class li span{ float: left; width: 100%; font-size: 17px; color: #857b71;}
.entry_box4_class li:hover span{ color: #fff;}
.entry_box4_class li:hover{ background: url(../images/licurback2.png) no-repeat center;}
.entry_box4_class ::-webkit-scrollbar-track{background-color: #eee;}
.entry_box4_class ::-webkit-scrollbar{ width:6px; height:6px; background-color:#7a6654;}
.entry_box4_class ::-webkit-scrollbar-thumb{ background-color:#7a6654;}
.entry_box4_class scrollbar-track{background-color: #eee;}
.entry_box4_class scrollbar{ width:6px; height:6px; background-color:#7a6654;}
.entry_box4_class scrollbar-thumb{ background-color:#7a6654;}
.entry_box4_list{ float: left; width: 100%; padding: 0 28px; box-sizing: border-box;}
.entry_box4_listt{ float: left; width: 100%; padding-bottom: 26px; border-bottom: 1px solid #ddd3cb;}
.entry_box4_listt h3{ float: left; cursor: pointer; width: 100%; font-size: 20px; color: #504027; font-family: '黑体'; margin-top: 30px; margin-bottom: 15px;}
.entry_box4_listt h3:hover{ color: #99533E;}
.entry_box4_listt p{ float: left; width: 100%; font-size: 13px; color: #504027; font-family: '黑体'; line-height: 26px;}
.entry_box4_listb{ float: left; width: 100%; margin-top: 24px;}
.entry_box4_listb ul li{ float: left; width: 488px; position: relative; padding-left: 30px; margin-bottom: 22px; margin-right: 100px;}
.entry_box4_listb ul li:nth-child(2n){ margin-right: 0;}
.entry_box4_listb ul li::before{ position: absolute; left: 7px; top: 6px; content: ''; width: 7px; height: 7px; border-radius: 50%; background: #7a6654;}
.entry_box4_listb ul li h3{ float: left; cursor: pointer; width: calc( 100% - 100px ); font-size: 15px; color: #524335; font-family: '微软雅黑'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.entry_box4_listb ul li span{ float: right; width: 80px; text-align: right; font-size: 15px; color: #524335; font-family: '微软雅黑'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.entry_box4_listb ul li h3:hover{ color: #99533E;}
.class_cur{background: url(../images/licurback.png) no-repeat center;}
.class_cur span{ color: #fff !important;}
.entry_box .class_cur{background: url(../images/licurback2.png) no-repeat center;}
.yzsjk_titlebox ul li:hover{ background: url(../images/licurback.png) no-repeat center;}


/* 列表 */
.list_box{ float: left; width: 100%;}
.list_box_l{ float: left; width: 804px; margin-bottom: 32px; border-radius: 4px; border: 1px solid #ebe1d9; padding: 10px 22px; box-sizing: border-box; background: #fffbf7;}
.list_title{ float: left; margin: 7px 0; text-align: center;}
.list_title em{ float: left; width: 27px; height: 19px; background: url(../images/title_ico1.png) no-repeat;}
.list_title img{ display: inline-block; margin: 0 8px; vertical-align: top;}
.list_title span{ display: inline-block; margin: 0 8px; font-family: 'FZQKJT'; font-size: 18px; color: #504027; font-weight: 400; vertical-align: top;}
.list_class{ float: left; width: 100%; padding-bottom: 10px; border-bottom: 1px solid #eae2da;}
.list_class_l{ float: left; width: 550px;}
.list_class_l ul li{ float: left; font-size: 15px; color: #2e2e2e; font-family: '微软雅黑'; line-height: 34px; margin-right: 40px; cursor: pointer;}
.list_class_l ul li:last-child{ margin-right: 0;}
.list_class_cur{ font-weight: bold; color: #99533e !important;}
.list_class_l ul li:hover{font-weight: bold; color: #99533e !important;}
.list_class_r{ float: right; width: 205px; position: relative;}
.list_class_r input{ float: left; width: 100%; height: 34px; border: 1px solid #c5b3a4; border-radius: 3px; padding: 0 30px 0 10px; outline: none; line-height: 32px; box-sizing: border-box;}
.list_class_r img{ position: absolute; right: 10px; top: 10px; width: 14px; height: 14px; cursor: pointer;}
.list_box_con{ float: left; width: 100%;}
.list_box_con >ul li{ float: left; width: 100%; border-bottom: 1px solid #eae2da; padding: 16px 0;}
.element_box{ float: left; width: 100%;}
.element_box_l{ float: left; width: 145px; height: 145px; overflow: hidden; border-radius: 3px; cursor: pointer; margin-right: 10px;}
.element_box_l img{ width: 100%; height: 100%; transition: 2s;}
.element_box_l img:hover{ transform: scale(1.1);}
.element_box_r{ float: left; width: calc( 100% - 155px );}
.element_box_r h3{ float: left; cursor: pointer; width: 100%; cursor: pointer; margin: 13px 0 20px; font-size: 15px; color: #99533e; font-family: '微软雅黑'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.element_box_r1{ float: left; width: 100%; margin-bottom: 15px;}
.element_box_r1 span{ float: left; font-size: 13px; color: #787878; font-family: '微软雅黑'; margin-right: 25px; }
.element_box_r p{ float: left; width: 100%; font-size: 13px; color: #2e2e2e; font-family: '微软雅黑'; line-height: 28px;}
.entrylist_box{ float: left; width: 100%; }
.entrylist_box h3{ float: left; width: 100%; margin-bottom: 10px; font-size: 15px; color: #99533e; font-family: '微软雅黑'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; cursor: pointer;}
.entrylist_box p{ float: left; width: 100%; font-size: 13px; color: #2e2e2e; font-family: '微软雅黑'; line-height: 28px;}
/* .entrylist_box span{ float: left; width: 100%; font-size: 13px; color: #726256; font-family: '微软雅黑'; margin-top: 8px;} */
.ImgList_box{ float: left; width: 100%;}
.ImgList_box_l{ float: left; width: 170px; height: 126px; border-radius: 3px; cursor: pointer; overflow: hidden; margin-right: 12px;}
.ImgList_box_l img{ width: 100%; height: 100%; transition: 2s;}
.ImgList_box_l img:hover{ transform: scale(1.1);}
.ImgList_box_r{ float: left; width: calc( 100% - 182px );}
.ImgList_box_r h3{ float: left; width: 100%; font-size: 15px; color: #99533e; font-family: '微软雅黑'; margin: 16px 0 35px; cursor: pointer; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.ImgList_box_r1{ float: left; width: 100%; margin-bottom: 15px;}
.ImgList_box_r1 span{ float: left; font-size: 13px; color: #787878; font-family:'微软雅黑'; margin-right: 24px; max-width: 50%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.ImgList_box_r p{ float: left; width: 100%; font-size: 13px; color: #787878; font-family: '微软雅黑'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
.book_box{ float: left; width: 100%;}
.book_box_l{ float: left; width: 126px; height: 126px; margin-right: 12px; line-height: 126px; text-align: center; border-radius: 3px; background: #eaddd4; overflow: hidden;}
.book_box_l img{ max-width: 100%; max-height: 100%; vertical-align: middle; transition: 2s;}
.book_box_l img:hover{ transform: scale(1.1);}
.book_box_r{ width: calc( 100% - 138px );}
.audio_box{ float: left; width: 100%;}
.audio_box_l{ float: left; width: 126px; height: 101px; cursor: pointer; margin-right: 15px; line-height: 101px; border-radius: 2px; text-align: center;}
.audio_box_l img{ max-width: 100%; max-height: 100%;}
.audio_box_r{ float: left; width: calc( 100% - 141px );}
.audio_box_r h3{ float: left; width: 100%; margin: 10px 0; font-size: 15px; color: #99533e; font-family: '微软雅黑'; cursor: pointer; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.audio_box_r span{ float: left; width: 100%; font-size: 13px; color: #726256; font-family: '微软雅黑'; margin-bottom: 10px;}
.audio_box_r p{ float: left; width: 100%; margin-top: 10px; font-size: 13px; color: #2e2e2e; font-family: '微软雅黑'; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.video_box{ float: left; width: 100%;}
.video_box_l{ float: left; cursor: pointer; width: 169px; height: 126px; border-radius: 3px; overflow: hidden; margin-right: 15px;}
.video_box_l img{ width: 100%; height: 100%; transition: 2s;}
.video_box_l img:hover{ transform: scale(1.1);}
.video_box_r{ width: calc( 100% - 184px );}
.video_box_r h3{ margin-bottom: 15px;}
.list_box_r .news_aboutl{ width: 101px; height: 101px;}
.list_box_r .news_aboutl img{ width: 100%; height: 100%;}
.list_box_r .news_aboutr{ width: calc( 100% - 112px );}
.list_box_r .news_aboutr p{ margin-top: 20px;}
.imagelist_box_con >ul li{ width: 50%; height: 152px; border-bottom: none; padding: 16px 16px 10px 0; box-sizing: border-box;}
.imagelist_box_con .ImgList_box_r h3{ margin-top: 0; margin-bottom: 26px;}
.imagelist_box_con .ImgList_box_r1 span{ max-width: 100%; margin-bottom: 15px; margin-right: 0;}
.imagelist_box_con .ImgList_box_r1 span:last-child{ margin-bottom: 0;}
.book_box_r h3{ margin-bottom: 10px; margin-top: 5px;}
.book_box_r .ImgList_box_r1 span{ width: 100%; margin-bottom: 15px;}
.book_box_r .ImgList_box_r1 span:last-child{ margin-bottom: 0;}

/* pdf阅读*/
.pdfread_readcontent img{max-width: 100%; max-height: 100%;}
.pdfread_readcontent .page{width:461px;height:600px;background-color:white;background-repeat:no-repeat;background-size:100% 100%;box-shadow: 0 0 10px #999}
.pdfread_readcontent a{ outline: none !important;}

/* 相关 */
.detailxgyp_box li a:first-child{ height: 126px; width: 100%;}
.chartbox{ float: left; width: 100%; height: 100%;}
.resource_bookRelated{ padding-bottom: 0 !important;}
.resource_bookRelated1{ float: left; width: 503px; padding-right: 15px; box-sizing: border-box;}
.resource_bookRelated2{ float: left; width: 676px;}
.resource_bookRelated1 li{width: 220px !important; margin-top: 10px; margin-bottom: 18px !important; height: 20px !important; background: unset !important; border: none !important;}
.resource_bookRelated1 li h3{ float: left; position: relative; font-size: 15px; color: #726256; cursor: pointer; width: 100%; line-height: 20px; padding-left: 16px; box-sizing: border-box; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.resource_bookRelated1 li h3::before{ position: absolute; content: ''; left: 0; top: 4px; width: 7px; height: 10px; border-left: 7px solid #6b5e4c; border-top: 5px solid transparent; box-sizing: border-box; border-right: none; border-bottom: 5px solid transparent;}
.resource_bookRelated2 li{ width: 330px !important; margin-left: 0 !important; margin-right: 15px;}
.resource_bookRelated2 li:last-child{ margin-right: 0;}
.resource_audioRelated li{ width: 220px !important; margin-right: 12px !important;}
.resource_audioRelated li:last-child{ margin-right: 0 !important;}
.resource_audioRelated .audio_cover{ border: none !important; width: 111px !important; height: 91px !important; line-height: 91px !important;}
.audio_content{ float: left; margin-left: 13px ; width: calc( 100% - 124px );}
.audio_content h2{ text-align: left !important; height: 65px; overflow: unset !important; text-overflow: unset !important; white-space: unset !important;}
.audio_content span{ float: left; width: 100%; font-size: 13px; color: #726256;}
.video_content{ float: left; width: 100%;}
.video_content h2{ text-align: left !important; width: 70% !important;}
.video_content span{ float: right; max-width: 30%; font-size: 13px; color: #726256; line-height: 30px; }
.resource_imageRelated1{ width: 684px !important;}
.resource_imageRelated1 li{ width: 328px !important; cursor: pointer; background: #eaddd4; border-width: 3px !important;}
.resource_imageRelated1 li{ margin-right: 14px !important;}
.image_cover{ float: left; width: 166px; height: 123px; text-align: center; overflow: hidden; line-height: 123px; background: #f4f4f4; }
.image_cover img{ max-width: 100%; max-height: 100%; vertical-align: middle; transition: 2s;}
.resource_imageRelated1 li:hover .image_cover img{ transform: scale(1.1);}
.image_content{ float: left; width: calc( 100% - 166px ); padding: 0 5px 0 16px; box-sizing: border-box;}
.image_content h3{ float: left; width: 100%; font-size: 15px; color: #726256; line-height: 38px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.image_content span{ float: left; width: 100%; margin-bottom: 10px; font-size: 13px; color: #2e2e2e; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.resource_imageRelated2 li{ width: 150px !important; margin-right: 8px !important;}
.resource_imageRelated2 li:last-child{ margin-right: 0 !important;}
.resource_imageRelated2 .xgts_boxt{ width: 146px !important; height: 108px !important; line-height: 108px !important;}
.resource_imageRelated2 .xgts_boxb h3{ line-height: 17px !important;}
.export_related{ position: relative; margin-bottom: 20px !important; padding-left: 15px; box-sizing: border-box; list-style: none !important;}
.export_related::before{position: absolute; content: ''; left: 0; top: 8px; width: 7px; height: 10px; border-left: 7px solid #6b5e4c; border-top: 5px solid transparent; box-sizing: border-box; border-right: none; border-bottom: 5px solid transparent;}
.export_related::marker{ display: none;}
.export_related span{ float: left; height: 17px; margin-top: 5px; width: 100%; font-size: 13px; color: #585858; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}

/* 音频弹窗 */
.index_audio audio{ width: 100% !important; margin: 0 !important; background: rgb(241,243,244);}
.index_audioc{ float: left; width: 100%; height: 149px; padding: 25px 40px 0 40px; box-sizing: border-box; background: url(../images/detail_bg.png) 60px 60px no-repeat;}
.index_audioc_l{ float: left; width: 128px; height: 101px;text-align: center; line-height: 101px;}
.index_audioc_l img{ max-width: 100%; max-height: 100%; vertical-align: middle;}
.index_audioc_r{ float: left; width: calc( 100% - 138px ); margin-left: 10px; font-family: '微软雅黑';}
.index_audioc_r h3{ float: left; width: 100%; font-size: 15px; margin-top: 20px;  color: #323232; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}
.index_audioc_r span{ float: left; width: 100%; font-size: 15px; color: #323232; margin-top: 28px;}

/* xml阅读 */
.xml_content{float: left; width: 100%; height: 800px; margin-top: 15px; box-shadow: 0 0 10px #999; background: #fff; overflow-x: hidden; overflow-y: auto;padding: 20px 30px 30px; box-sizing: border-box;}
.xml_content para{ display: block; width: 100%; text-indent: 2em;}
/* pdf阅读 */
.themebg1{ background: url(../images/theme1.png) !important;}
.themebg2{ background: url(../images/theme2.png) !important;}
.themebg3{ background: url(../images/theme3.png) !important;}
.themebg4{ background: url(../images/theme4.png) !important;}
.manu_box{ padding-left: 15px; padding-right: 15px; box-sizing: border-box;}
.play_layerc1 video{ float: left; width: 100%; height: 500px; outline: none;}
.play_layerc1 iframe{ width: 100%; height: 500px;}
/* 底部 */
.bottom_tjbox{ float: left;}
.bottom_tjbox ul li{ float: left; margin-top: 90px; margin-right: 88px;}
.bottom_tjbox ul li:last-child{ margin-right: 0;}
.bottom_tjbox ul li img{ float: left; width: 36px; height: 40px; margin-right: 18px; margin-top: 15px;}
.bottom_tjboxc{ float: left; font-size: 23px; color: #ffffff; font-family: 'FZQKJT';}
.bottom_tjboxc h3{ float: left; width: 100%; font-weight: 400; margin-bottom: 18px;}

/* 毛玻璃 */
.layer_con{ position: absolute; left: 0; bottom: 0; width: 100%;background-image: -webkit-linear-gradient( 90deg,#fff,hsla(0,0%,100%,.99),hsla(0,0%,100%,.95),hsla(0,0%,100%,0));}
.layer_loginbtn{ position: absolute; width: 100%; bottom: 20px; color: #000; font-size: 15px; line-height: 30px; text-align: center; }
.layer_loginbtn span{ color: #AD816D; cursor: pointer; margin-left: 5px; text-decoration: underline;}
.layer_vipbtn{ color: #AD816D; text-decoration: underline;}
.header_searchType{ z-index: 1000001 !important;}