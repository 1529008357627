/*公用样式*/
body, ul, ol, li, p, h1, h2, h3, h4, h5, h6, form, fieldset, table, td, img, div, a, span { border: 0 none; margin: 0; padding: 0; }
.clear { clear: both; }
ul, li { list-style-type: none; }
body a { text-decoration: none;}
i { font-style: normal; }
em { font-style: normal; }
a{ color:#000;}
body { font-size:13px;font-family:"宋体"; min-width:1200px; word-break: break-word; word-wrap: break-word; box-sizing: border-box;}
table{ border-spacing:0;}
input,select{ outline: none;}
img{ vertical-align: middle;}
@font-face { font-family: 'FZQKJT'; src: url('./fzqkjt.TTF');}
/**************常用class******************/
.red_title{ color:red; font-style: normal; font-family: "微软雅黑"; font-size: 13px; width: 10px; font-weight: 100; display: inline-block; text-align: left;}
/*浮动*/
.fl_l { float: left!important; }
.fl_r { float: right!important; }
/*边距*/
.m_0{ margin: 0!important;}
.m_l0{ margin-left: 0!important;}
.m_r0{ margin-right: 0!important;}
.m_t0{ margin-top: 0!important;}
.m_b0{ margin-bottom: 0!important;}
.m_t5 { margin-top: 5px!important; }
.m_t10 { margin-top: 10px!important; }
.m_t13{margin-top: 13px !important;}
.m_t15 { margin-top: 15px!important; }
.m_t20 { margin-top: 20px!important; }
.m_t30 { margin-top: 30px!important; }
.m_t40 { margin-top: 40px!important; }
.m_t50 { margin-top: 50px!important; }
.m_b5 { margin-bottom: 5px!important; }
.m_b10 { margin-bottom: 10px!important; }
.m_b15 { margin-bottom: 15px!important; }
.m_b20 { margin-bottom: 20px!important; }
.m_b22 { margin-bottom: 22px!important; }
.m_b25 { margin-bottom: 25px!important; }
.m_b30 { margin-bottom: 30px!important; }
.m_b35 { margin-bottom: 35px!important; }
.m_b40 { margin-bottom: 40px!important; }
.m_b50 { margin-bottom: 50px!important; }
.m_l5 { margin-left: 5px!important; }
.m_l10 { margin-left: 10px!important; }
.m_l15 { margin-left: 15px!important; }
.m_l20 { margin-left: 20px!important; }
.m_r5 { margin-right: 5px!important; }
.m_r10 { margin-right: 10px!important; }
.m_r15 { margin-right: 15px!important; }
.m_r20 { margin-right: 20px!important; }
.p_0{ padding: 0!important;}
.p_l0{ padding-left: 0!important;}
.p_r0{ padding-right: 0!important;}
.p_t0{ padding-top: 0!important;}
.p_b0{ padding-bottom: 0!important;}
.p_t5 { padding-top: 5px!important; }
.p_t10 { padding-top: 10px!important; }
.p_t15 { padding-top: 15px!important; }
.p_t20 { padding-top: 20px!important; }
.p_b5 { padding-bottom: 5px!important; }
.p_b10 { padding-bottom: 10px!important; }
.p_b15 { padding-bottom: 15px!important; }
.p_b20 { padding-bottom: 20px!important; }
.p_l5 { padding-left: 5px!important; }
.p_l10 { padding-left: 10px!important; }
.p_l15 { padding-left: 15px!important; }
.p_l20 { padding-left: 20px!important; }
.p_r5 { padding-right: 5px!important; }
.p_r10 { padding-right: 10px!important; }
.p_r15 { padding-right: 15px!important; }
.p_r20 { padding-right: 20px!important; }
.m_t-10{margin-top: -10px !important;}
.m_t-20{margin-top: -20px !important;}
/*高度*/
.h10 { height: 10px!important; }
.h20 { height: 20px!important; }
.h30 { height: 30px!important; }
.h40 { height: 40px!important; }
.h50 { height: 50px!important; }
.h100 { height: 100px!important; }
.h200 { height: 200px!important; }
.min_h_auto { min-height: auto!important; }
.h_auto{ height: auto!important;}
/*宽度*/
.w100{ width: 100%!important;}
/*头部*/
.index_header{ background: url(../images/head_bg.png) top center no-repeat #46362d !important;}
.header{ width: 100%; float: left; height: 130px; min-width: 1200px; background: #302723; position: fixed; top: 0; left: 0; z-index: 10; transition:0.3s; z-index: 100000;}
.header_c{ width: 1200px; margin: 0 auto; height: 130px;}
.head_l{ width: 310px; height: 130px; float: left; line-height: 130px; text-align: center;}
.head_l11{ width: 309px; float: left;}
.head_l22{ width: 243px; float: left; height: 15px; margin-top: 11px;}
.head_l22 img{ width: 243px; float: left; height: 15px;}
.head_l11 img{ width: 309px; height: 75px; float: left; margin-top: 30px; transition: 0.3s;}
.head_r{ float: right; width: 820px; height: 130px;}
.head_rt{ width: 240px; height: 30px; float: left; margin-top:32px; }
.head_rt2{ width: 540px; height: 30px; float: right; margin-top: 32px; transition: 0.3s;}
.head_rt21{ float: right; width: 115px; position: relative; left: 0; top: 0;}
.head_rt21>a{ float: left; font-size: 15px; font-family: "黑体"; color: #d4beaa; padding-left: 10px; border-left: 1px solid #d4beaa; margin-left: 10px; height: 15px; line-height: 15px;}
.head_rt21>a:first-child{ border-left: 0;}
.head_rt21a{ width: 90%; float: right; margin-top: 12px;}
.head_rt21a>a{ float: left; font-size: 15px; font-family: "黑体"; color: #d4beaa; padding-left: 10px; border-left: 1px solid #d4beaa; margin-left: 9px; height: 15px; line-height: 15px;}
.head_rt21a>a:first-child{ border-left: 0;}
.head_rt22{ width: 362px; height: 38px; float: right; margin-right: 15px; transition: 0.3s;}
.head_rt22>a{ width: 78px; height: 38px; line-height: 38px; text-align: center; border: 1px solid #9e8773; float: left; border-radius: 4px; box-sizing: border-box; font-size: 15px; font-family: "黑体"; color: #af967f;}
.head_rt22l{ float: left; width: 275px; height: 38px; background: url(../images/header_back.png) no-repeat center; margin-right: 7px;}
.head_rt22l select{ width: 60px; height: 25px; border-right: 1px solid #d0ccc8; float: left; margin-left: 10px; margin-top: 7px; border: 0; background: none; outline: none; font-size: 15px; font-family: "黑体"; color: #af967f;}
.head_rt22l >input{ width: 152px; height: 25px; float: left; margin-top: 7px; box-sizing: border-box; border: none; background: none; outline: none; border-left: 1px solid #d0ccc8; padding-left: 5px;}
.head_rt22l a{ float: left; width: 52px; height: 38px; text-align: center; line-height: 38px; font-size: 15px; font-family: "黑体"; color: #ffffff;}
.nav{ float: right; margin-top: 18px; height: 50px; transition: 0.3s; overflow: hidden;}
.nav ul li{ float: left; margin-right: 49px; transition: 0.3s;}
.nav ul li:last-child{ margin-right: 0;}
.nav ul li a{ box-sizing: border-box; float: left; font-size: 17px; font-family: "黑体"; color: #af967f; line-height: 50px; height: 50px; transition: 0.3s;}
.nav_cur a{ border-bottom: 3px solid #d9c7b8; color: #e1cfbf!important;}
.nav ul li:hover a{ color: #e1cfbf!important; border-bottom: 3px solid #d9c7b8;}
.head_rt21b{ width: 90%; float: right;}
.head_rt21b1{ float: right; width: 38px; height: 60px; position: relative;}
.head_rt21b1>a{ width: 100%; margin-left: 0; padding-left: 0; width: 38px; height: 38px; float: left;}
.head_rt21b1 img{ width: 100%; border-radius: 50%;}
.head_rt21b2{ float: right; width: 30px; height: 30px; position: relative; margin-top: 2px; margin-right: 6px;}
.head_rt21b2 img{ position: absolute; left: 0; bottom: 0;}
.head_rt21b2 p{ width: 20px; height: 13px; line-height: 11px; text-align: center; background: #f1403c; font-size: 12px; font-family: "微软雅黑"; color: #fff; border-radius: 5px; border: 1.5px solid #2e2420; position: absolute; right: 1px; top: 1px; z-index: 10;}
.head_rt21b1_fix_x{ display: none; position: relative; width: 80px; right:42px; top: 35px;}
.head_rt21b1_fix{ margin-top: -17px; width: 80px; border: 1px solid #eedccc; border-radius: 3px; float: right; background: #fff;}
.head_rt21b1:hover .head_rt21b1_fix_x{ display: block;}
.head_rt21b1_fix:before{ content: ''; position: absolute; right: 12px; top: 15px; border-left:5.5px solid transparent; border-right:5.5px solid transparent; border-bottom:8px solid #fff;}
.head_rt21b1_fixc{ width: 60px; margin: 0 auto;}
.head_rt21b1_fixc ul li{ width: 100%; float: left; height: 30px; border-bottom: 1px solid #d2d2d2;}
.head_rt21b1_fixc ul li:last-child{ border-bottom: 0;}
.head_rt21b1_fixc ul li a{ font-size: 13px; font-family: "微软雅黑"; color: #787878; text-align: center; width: 100%; float: left; line-height: 30px;}
.head_rt22l .layui-input-block{ margin-left: 1px; width: 70px;}
.head_rt22l .layui-form{ float: left; width: 70px;}
.head_rt22l .layui-form-select .layui-input{ width: 70px; padding-left: 8px; padding-right: 0; line-height: 20px; color: #af967f;}
.head_rt22l .layui-form-select dl dd, .head_rt22l .layui-form-select dl dt{line-height: 30px;}
.head_rt22l .layui-form-select dl dd.layui-this{ background-color: #af967f!important;}
.head_rt22l .layui-form-select .layui-edge{ margin-top: 17px; right: 5px;border-top-color: #af967f;}
.head_rt22l .layui-form-selected .layui-edge{ margin-top: 11px!important; right: 5px;}
.address{ width: 100%; float: left; height: 53px;}
.address p{ font-size: 13px; font-family: "微软雅黑"; color: #747474;line-height: 53px;}
.address p span{ color: #99533e;}
.main_box{ width: 100px;  float: left;}
.main_boxc{ width: 1200px; margin: 0 auto;}
.cont_box{ width:100%; float: left; margin-bottom: 30px;}
.cont_left_box{ width: 240px; float: left; border-radius: 4px;  overflow: hidden;}
.cont_left_lic{ width: 100%; float: left; display: none;}
.cont_left_lic ul li a{ background: #fbf7f4; border-top: 1px solid #e9e0d9; width: 100%; float: left; font-size: 13px; font-family: "微软雅黑"; color: #736357; padding-left: 53px; height: 38px; box-sizing: border-box; line-height: 38px;}
.cont_left_lic ul li a:hover{ color: #99533e;}
.cont_left_lic ul li.cur a{ color: #99533e;}
.cont_left_box_c{ width: 100%; float: left; background: #fffbf7; border: 2px solid #ebe1d9; box-sizing: border-box; border-radius: 0 0 4px 4px;}
.cont_left_li{ width: 100%; float: left; border-bottom: 1px solid #e9e0d9; box-sizing: border-box;}
.cont_left_li.cur{ position: relative;}
.cont_left_li.cur:before{ width:0; height:0; border-left:5.5px solid transparent; border-right:5.5px solid transparent; border-top:8px solid #736357; position: absolute; left: 210px; top: 19px;}
.cont_left_li:nth-child(1).cur{ position: relative;}
.cont_left_li:nth-child(1).cur h2{ color: #99533e;}
/* .cont_left_li:nth-child(1).cur:before{content: ''; width：0; height:0; border-top:5.5px solid transparent; border-bottom:5.5px solid transparent; border-right:8px solid #736357; position: absolute; left: 203px; top: 19px;} */
.cont_left_li:nth-child(2).cur{ position: relative;}
.cont_left_li:nth-child(2).cur h2{ color: #99533e;}
/* .cont_left_li:nth-child(2).cur:before{content: ''; width：0; height:0; border-top:5.5px solid transparent; border-bottom:5.5px solid transparent; border-right:8px solid #736357; position: absolute; left: 203px; top: 19px;} */
.cont_left_li:nth-child(3).cur{ position: relative;}
.cont_left_li.cur h2{ color: #99533e;}
/* .cont_left_li:nth-child(3).cur:before{content: ''; width：0; height:0; border-top:5.5px solid transparent; border-bottom:5.5px solid transparent; border-right:8px solid #736357; position: absolute; left: 203px; top: 19px;} */
.cont_left_li:nth-child(4).cur{ position: relative;}
.cont_left_li:nth-child(4).cur h2{ color: #99533e;}
.cont_left_li:nth-child(5).cur:before{content: '';}
.cont_left_li:last-child{ border-bottom: 0;}
.cont_left_li img{ float: left; margin-left: 18px; margin-top: 17px;}
.cont_left_li h2{ font-size: 15px; font-weight: 100; font-family: "微软雅黑"; color: #736357; line-height: 48px; float: left; margin-left: 9px;}
.cont_left_li h2:hover{color: #99533e !important;}
.cont_left_li a{ float: left; width: 100%;}
.head_rt21b3{ float: left; margin-top: 10px; margin-left: -20px; cursor: pointer; display: none; opacity: 0; transition: 0.3s;}
/*---------------------------------------------------*/
.fixed_header{ height: 76px;}
.fixed_header .header_c{ height: 76px;}
.fixed_header .head_l{ height: 76px;}
.fixed_header .head_r{ height: 76px;}
.fixed_header .head_l22{ display: none;}
.fixed_header .head_l11{ width: 179px;}
.fixed_header .head_l11 img{ width: 179px; height: 39px; margin-top: 18px;}
.fixed_header .nav{ height: 76px; margin-top: -48px; margin-left: -57px; width: 740px; float: left;}
.fixed_header .nav ul li{ margin-right: 37px;}
.fixed_header .nav ul li a{ line-height: 76px; height: 76px;}
.fixed_header .head_rt2{ margin-top: 18px;}
.fixed_header .head_rt22{ margin-top: -75px; opacity: 0;}
.fixed_header .head_rt21b3{ display: block; opacity: 1;}
.fixed_header .nav ul li:last-child{ margin-right: 0;}
.sec_header .head_rt22{ display: none;}
.sec_header .head_rt21b3{ display: none;}
.header_zw{ width: 100%; float: left; height: 130px; display: none;}
.fixed_header .head_rt{display: none;}
.sec_header .head_rt{display: none;}

/*分页*/
.pagefoot span, .pagefoot .active, .pagefoot a, .pagefoot input, .pagefoot select { min-width: 28px !important; height: 28px !important; line-height: 28px !important; color: #a8a8a8 !important; border-radius:3px !important; box-sizing:border-box;}
.pagefoot .active { background: #99533e !important; color: #ffffff !important; border-radius:3px !important; border:none !important;}
.pagefoot a { background: #f9f9f9 !important; border: 1px solid #ededed !important; border-radius:3px !important;}
.pagefoot a:hover { color: #ffffff !important; background: #99533e !important;}
.pagefoot input { background: #f9f9f9 !important; border: 1px solid #ededed !important; border-radius:3px !important;}
.pagefoot select { border: 1px solid #ededed !important; width:auto !important; background:#f9f9f9;}
/*暂无数据*/
.no_data{ width: 100%; float: left; height: 250px; line-height: 250px; text-align: center; margin-top: 100px;}
li{list-style: none;}
/*详情页头部*/
.header2 { width: 100%; float: left; height: 76px; min-width: 1200px; background: #302723;}
.header2_l{ width: 180px; height: 76px; float: left;}
.header2_l img{ float: left; width: 100%; margin-top: 16px;}
.header2c{ width: 1200px; margin: 0 auto; height: 76px; position: relative; z-index: 10;}
.header2_r{ width: 915px; float: right; height: 76px;}
.header2_nav>ul li{ float: left; margin-right: 35px;}
.header2_nav>ul>li>a { box-sizing: border-box; float: left; font-size: 17px; font-family: "黑体"; color: #af967f; line-height: 76px; height: 76px; transition: 0.3s;}
.header2_nav{ float: left;}
.header2_nav ul li:hover a { color: #e1cfbf!important; border-bottom: 3px solid #d9c7b8;}
.header2_my{ width: 150px; height: 76px; float: right;}
.header2_my .head_rt21b{ margin-top: 19px;}
.header2_searchbox{ width: 409px; height: 70px; background: #ffffff; position: absolute; right: 95px; top: 50px; border-radius: 3px; display: none;}
.header2_searchbox:before { content: ''; position: absolute; right: 18px; top: -8px; border-left: 5.5px solid transparent; border-right: 5.5px solid transparent; border-bottom: 8px solid #fff;}
.header2_searchbox1{ width: 351px; height: 36px; float: left; margin-left: 30px; margin-top: 17px;}
.header2_searchbox1 .layui-input-block{ margin-left: 1px; width: 70px;}
.header2_searchbox1 .layui-form{ float: left; width: 70px;}
.header2_searchbox1 .layui-form-select .layui-input{ height: 36px; width: 70px; padding-left: 8px; padding-right: 0; line-height: 20px; background: #af967f; border: none; font-size: 14px; color: #ffffff;}
.header2_searchbox1 .layui-form-select dl dd, .head_rt22l .layui-form-select dl dt{line-height: 30px;}
.header2_searchbox1 .layui-form-select dl dd.layui-this{ background-color: #af967f!important;}
.header2_searchbox1 .layui-form-select .layui-edge{ margin-top: -1px; right: 5px; border-top-color: #fff;}
.header2_searchbox1 .layui-form-selected .layui-edge{ margin-top: -8px!important; right: 5px;}
.header2_searchbox1 input.cse{ width: 242px; height: 36px; box-sizing: border-box; border: 0; border-top: 1px solid #af967f; border-bottom: 1px solid #af967f; float: left; padding-left: 8px;}
/*列表页左侧分类*/
.navigation{ width: 100%; float: left; margin-bottom: 20px; background: #fffbf7; border: 2px solid #ebe1d9; box-sizing: border-box; border-radius: 4px;}
.navigation_t{ width: 100%; float: left; height: 48px; background: url(../images/nav_left_back.png) no-repeat center;}
.navigation_t img{ display: table; margin: 0 auto; margin-top: 13px;}
.navigation_c{ width: 100%; float: left; min-height: 100px; padding:5px 10px 10px 10px; box-sizing: border-box;}
.navigation_box{ float: left; font-size: 15px; font-family: "微软雅黑"; color: #736357;line-height: 32px; position: relative;}
.navigation_c .navigation_title{ position: relative; z-index: 1; font-size: 15px; font-family: "微软雅黑"; font-weight: 400; color: #736357; padding-left: 12px; padding-right: 12px; border: 1px solid #fffbf7; border-bottom: 0; border-radius: 4px;}
.navigation_c .navigation_boxabos{ display: none; position: absolute; z-index: 2; left: -120px; border-radius: 4px; top: 32px; width: 365px; background: #fcfaf8; border: 1px solid #ebe1d9; padding:10px; box-sizing: border-box;}
.navigation_c .navigation_box:hover .navigation_title{ border-color: #ebe1d9; background: #fcfaf8; z-index: 3; color: #99533e;}
.navigation_c .navigation_boxabos a{ font-size: 14px; font-family: "微软雅黑"; color: #8c7c73; float: left; margin-right: 10px; margin-left: 10px; line-height: 35px;}
.navigation_c .navigation_boxabos2{ top: 150px; left: 5px;}


.left_box .navigation_title{ background: url(../images/zpxx_ico.png) no-repeat left; height: 22px; width: 100%; float: left; line-height: 22px; font-family: "微软雅黑"; color: #736357; font-size: 18px; margin-bottom: 14px; margin-top: 13px;font-weight: 600; padding-left: 30px; font-weight: 400;}

/* 图片裁剪 */
.Popup{ position: fixed; left: 0; top: 0; width: 100%; height: 100%; opacity: .5; background: #000;z-index: 1000;}
.Popup_c{ width: 400px; height: 360px; position:fixed; left: 50%; margin-left: -200px; top: 50%; margin-top: -180px; background: #fff; z-index: 1001; border-radius: 4px;}
.Popup_c_top{ padding: 15px 15px 10px; position: relative;}
.Popup_c_top h3{ padding-left: 0; margin-bottom: 0; font-size: 18px; line-height: 1; color: #303133; font-weight: 100;}
.Pop_btn{ position: absolute; top: 15px; right: 15px; padding: 0; border: none; outline: 0; background: 0 0; font-size: 16px; cursor: pointer; color: #303133;}
.Pop_btn:hover i{ color: #409EFF;}
.CutImg_box{ width: 600px; height: 440px; position: fixed; left: 50%; top: 50%; margin-left: -300px; margin-top: -220px; background: #fff; z-index: 1000;border-radius: 4px;}
.cropper-content{ width: 100%; padding:0 15px; float: left; display: block!important; box-sizing: border-box;}
.cropper-content .cropper{ width: 570px!important; height: 300px!important;}
.Publishccrl_cz{ position: relative;}
.Publishccrl_cz input[type='file']{ position: absolute; width: 285px; height: 134px; left: -75px; top: 0; cursor: pointer; opacity: 0;}
.show-preview{ display: none!important;}
.cropper2 .cropper-view-box{ border-radius: 50%!important;}

/* 插件样式 */
.el-message-box{ border: none !important; width: 15% !important; border-radius: 0 !important;}
.el-message-box__title{ color: #fff !important;}
.el-message-box__header{  padding-top: 10px !important; background: #736357 !important;}
.el-message-box__wrapper .el-button--primary{ background-color: #736357 !important; border-color: #736357 !important;}
.el-message-box__headerbtn{ top: 11px !important;}
.el-message-box__headerbtn .el-message-box__close{ color: #fff !important;}
.el-dialog__header{ background: #736357 !important; padding-top: 10px !important;}
.el-dialog__title{ color: #fff !important;}
.el-dialog__headerbtn{ top: 15px !important;}
.el-dialog__headerbtn .el-dialog__close{ color: #fff !important;}
.el-dialog__footer .el-button{ background: #736357 !important; color: #fff !important; border-color:#736357 !important; padding: 8px 14px !important;}
